import React from 'react'
import Link from '@components/common/NoPrefetchLink'

export function TermsAndConditions({ className }: { className?: string }) {
  return (
    <Link href="/privacy-policy?tab=terms">
      <a className={`px-4  border-core-purple-40 ${className}`}>T &amp; C's</a>
    </Link>
  )
}
