import { IFooterBanner } from '@components/common/Footer/FooterBanner'
import { FC } from 'react'

import Medium from './Medium'
import Small from './Small'

export interface IFooterPage {
  Component?: FC<{ className?: string }>
  slug: string
  text: string
  pages?: Array<IFooterPage>
}

export const Pages: FC<{
  pages: IFooterPage[]
  footerBannerContent?: IFooterBanner
}> = ({ pages, footerBannerContent }) => {
  return (
    <>
      <Medium pages={pages} footerBannerContent={footerBannerContent} />
      <Small pages={pages} />
    </>
  )
}
