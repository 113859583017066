export function CartItemSkeleton() {
  return (
    <div className="w-full px-8 mt-16 sm:px-16">
      <div className="flex justify-between mb-8">
        <div className="w-8/12 h-4 rounded-full bg-black-20" />
        <div className="w-2/12 h-4 rounded-full bg-black-20" />
      </div>

      <div className="flex justify-between w-full pb-8 border-b-2 border-black-20">
        <div className="w-40 h-40 mr-8 rounded-full flex-2 bg-black-20" />
        <ul className="flex-1 w-full p-0 list-none">
          {Array.from(Array(4).keys()).map((item) => (
            <li
              className="w-full h-4 mb-2 rounded-full bg-black-20"
              key={item}
            />
          ))}

          <li className="flex justify-between w-full mt-8">
            <div className="w-8/12 h-12 rounded-full bg-black-20" />
            <div className="w-2/12 h-12 rounded-full bg-black-20" />
          </li>
        </ul>
      </div>
    </div>
  )
}
