import usePrice from '@framework/product/use-price'
import { IRadioTileOption } from '@noissue-ui-kit/oldBranding/radioTile'
import { NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_CURRENCY } from '@utils/client-side-config'

export const calculateGridOverrideClass = (
  variantOptions: IRadioTileOption[]
) => {
  const minWidthOfAllOptions = variantOptions?.reduce((prev, curr) => {
    if (
      typeof curr.displayLabel === 'string' &&
      curr.displayLabel.length > prev
    ) {
      return curr.displayLabel.length
    }
    return prev
  }, 0)

  let gridOverrideClass
  if (minWidthOfAllOptions > 20) {
    gridOverrideClass = 'grid-cols-1 md:grid-cols-2'
  } else if (minWidthOfAllOptions > 10) {
    gridOverrideClass = 'grid-cols-2 md:grid-cols-2'
  } else {
    gridOverrideClass = 'grid-cols-2 md:grid-cols-4'
  }

  return gridOverrideClass
}

export function calculateUnitPrice({
  priceOfProduct,
  qtyOfProduct,
  locale,
}): string {
  const currencyCode = NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_CURRENCY || 'NZD'

  const qtyNumeric =
    typeof qtyOfProduct === 'number'
      ? qtyOfProduct
      : Number(qtyOfProduct.match(/^\d+/)[0])

  const { price } = usePrice({
    amount: priceOfProduct / qtyNumeric,
    currencyCode,
    hideCurrencyCode: true,
    locale,
  })

  return `${price} / Unit`
}
