import { useCallback } from 'react'

const useSubscribe = () =>
  useCallback(
    (email: string, source: string) =>
      fetch('/api/bigcommerce/customers', {
        method: 'POST',
        body: JSON.stringify({ email, source }),
        headers: { 'Content-Type': 'application/json' },
      }),
    []
  )

export default useSubscribe
