import { useAddItem, useCart, useRemoveItem } from '@framework/cart'
import { ensureNoZeroPriceItemsInCart } from './utils'
import { useEffect, useState } from 'react'

export const useEnsureNoZeroPriceItemsInCart = () => {
  const { data } = useCart()
  const removeItem = useRemoveItem()
  const addItem = useAddItem()
  const [isLoading, setIsLoading] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  const [hasChecked, setHasChecked] = useState(false)

  useEffect(() => {
    if (!data?.lineItems) {
      setIsEmpty(true)
      return
    }

    let hasZeroPriceItem = false
    for (const cartItem of data.lineItems) {
      if (cartItem.variant.price === 0 || cartItem.variant.listPrice === 0) {
        hasZeroPriceItem = true
        break
      }
    }

    if (hasZeroPriceItem) {
      setIsLoading(true)
      if (!hasChecked) {
        setHasChecked(true)

        ensureNoZeroPriceItemsInCart(data.lineItems, removeItem, addItem)
          .then(() => {
            setIsLoading(false)
            setIsEmpty(false)
          })
          .catch(() => {
            setIsLoading(false)
            setIsEmpty(true)
          })
      }
    } else {
      setIsLoading(false)
      setIsEmpty(false)
    }
  }, [data?.lineItems, hasChecked])

  return {
    isLoading,
    isEmpty,
  }
}
