export const getProductVariantsSales = async ({
  productSlug,
}: {
  productSlug
}) => {
  const response = await fetch(
    `/api/bigcommerce/catalog/getProductVariantsSales?productSlug=${productSlug}`
  )
  const { data } = await response.json()
  return data
}
