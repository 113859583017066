import { useCommerce } from '@commerce'
import { formatPrice } from '@commerce/product/use-price'
import classNames from 'classnames'

export function StoreCreditCard({
  storeCredit,
  className,
  compactView = false,
}: {
  storeCredit: number
  className?: string
  compactView?: boolean
}) {
  const { locale } = useCommerce()

  return (
    <div
      className={classNames(
        `flex flex-col w-auto border-2 border-black-20 rounded-xl bg-black-5 px-7`,
        {
          'py-5': compactView,
          'py-9': !compactView,
        },
        className
      )}
    >
      <p
        className={classNames(
          `text-boulder text-xl flex font-semibold justify-between items-center`,
          { 'mb-3': !compactView }
        )}
      >
        <span className={compactView && 'text-lg'}>
          {compactView ? 'Credit' : 'Store Credit'}
        </span>
        <span className={compactView && 'font-normal'}>
          {formatPrice({
            amount: storeCredit,
            currencyCode: 'USD',
            locale,
          })}
        </span>
      </p>

      {!compactView && (
        <p className="text-boulder text-lg">
          Use at checkout to save on your next purchase
        </p>
      )}
    </div>
  )
}
