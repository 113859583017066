import { trackNavigationClick } from '@utils/gtm'
import { useRouter } from 'next/router'
import { TSecondaryMenuItem } from '../Navbar.types'
import { MobileTiles } from './MobileTiles'
import { Button } from '@noissue-ui-kit/button'
import { useSelectedPrimaryMenu } from '../NavbarDataProvider'
import { useTrackingBreadcrumbs } from '@utils/hooks/useTrackingBreadcrumbs'

export const MobileListDropdown = ({
  secondaryMenuItem,
  handleMenuToggle,
}: {
  secondaryMenuItem: TSecondaryMenuItem
  handleMenuToggle: () => void
}) => {
  const router = useRouter()
  const { selectedPrimaryMenu } = useSelectedPrimaryMenu()
  const { saveBreadcrumbs } = useTrackingBreadcrumbs()
  const { content } = secondaryMenuItem

  return (
    <div className="flex flex-col w-full">
      <div
        className={`flex flex-col w-full ${
          secondaryMenuItem?.startWithTiles ? 'order-2' : 'order-1'
        }`}
      >
        {secondaryMenuItem?.selectionMenus?.map((selectionMenu, idx) => (
          <div
            key={idx}
            className={'flex flex-col items-start gap-[12px] mb-12'}
          >
            <a
              className={`font-semibold text-[12px] leading-[15px] text-black-60 ${
                selectionMenu?.selectionMenuLink
                  ? 'cursor-pointer hover:text-pitaya'
                  : ''
              }`}
              href={selectionMenu?.selectionMenuLink}
              onClick={() => {
                if (selectionMenu?.selectionMenuLink) {
                  trackNavigationClick({
                    clickLocation: 'Selection Title',
                    clickItem: selectionMenu?.selectionMenuTitle,
                  })
                  router.push(selectionMenu?.selectionMenuLink).catch(() => {})
                }
              }}
            >
              {selectionMenu?.selectionMenuTitle}
            </a>

            <div key={idx} className="flex flex-col items-start p-0 gap-[8px]">
              {selectionMenu?.selectionMenuItems?.map((selectionItem) => (
                <div className="font-normal text-[16px] leading-[22px] text-boulder cursor-pointer hover:text-pitaya">
                  <span
                    onClick={() => {
                      if (selectionItem?.link) {
                        trackNavigationClick({
                          clickLocation: 'Selection Item',
                          clickItem: selectionItem?.label,
                        })
                        router.push(selectionItem?.link).catch(() => {})
                      }
                      handleMenuToggle()
                    }}
                  >
                    {selectionItem?.label}
                  </span>
                </div>
              ))}
            </div>
          </div>
        ))}

        {content?.ctaLabel && content?.ctaLink && (
          <Button
            size={'s'}
            corners={'rounded'}
            href={content?.ctaLink}
            onClick={() => {
              if (content?.ctaLink) {
                trackNavigationClick({
                  clickLocation: 'Dropdown CTA',
                  clickItem: content?.ctaLabel,
                })

                const breadcrumbs = [
                  {
                    name: selectedPrimaryMenu.primaryMenuTitle,
                    url: selectedPrimaryMenu.primaryMenuLink,
                  },
                  {
                    name: content?.ctaLabel,
                    url: content?.ctaLink,
                  },
                ]

                saveBreadcrumbs(breadcrumbs)
                router.push(content?.ctaLink).catch(() => {})
              }
            }}
            Component="a"
          >
            {content?.ctaLabel}
          </Button>
        )}
      </div>

      <MobileTiles
        tiles={secondaryMenuItem?.tiles}
        className={
          secondaryMenuItem?.startWithTiles ? 'order-1 mb-6' : 'order-2 mt-6'
        }
      />
    </div>
  )
}
