import isEmpty from 'lodash/isEmpty'
import { useRouter } from 'next/router'
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import { TPrimaryMenuItem, TSocialMediaIcon } from './Navbar.types'

type TNavbarDataContext = {
  primaryNavbarItems: TPrimaryMenuItem[]
  socialMediaIcons: TSocialMediaIcon[]
  selectedPrimaryMenuIndex: number
  setSelectedPrimaryMenuIndex: (number) => void
  selectedSecondaryMenuIndex: number
  setSelectedSecondaryMenuIndex: (number) => void
  searchIsActive: boolean
  setSearchIsActive: (boolean) => void
  shippingDestinationIsOpen: boolean
  setShippingDestinationIsOpen: (boolean) => void
}

const NavbarDataContext = createContext<TNavbarDataContext>(undefined)

const matchedPrimaryMenuIndex = (
  url: string,
  primaryNavbarItems: TPrimaryMenuItem[]
) => {
  if (!url) {
    return 0
  }

  const parts = url.split('/').filter(Boolean)
  if (parts.length < 1) {
    return 0
  }
  const pdpPagesStartWith = ['custom-packaging', 'marketplace']
  if (pdpPagesStartWith.includes(parts[0])) {
    return 0
  }

  const slug = parts.join('/')

  for (const [index, primary] of primaryNavbarItems.entries()) {
    if (primary?.primaryMenuLink?.includes(slug)) {
      return index
    }

    const found = primary?.secondaryMenuItems?.some((secondary) => {
      if (secondary.titleLinkUrl?.includes(slug)) {
        return true
      }
      const isSelectionMenu = secondary.selectionMenus?.find((selection) => {
        if (selection.selectionMenuLink?.includes(slug)) {
          return true
        }
        const isSelectionMenuItem = selection.selectionMenuItems?.find(
          (item) => {
            return item.link?.includes(slug)
          }
        )
        return isSelectionMenuItem
      })

      return isSelectionMenu
    })
    if (found) {
      return index
    }
  }
  return 0
}

function NavbarDataProvider({
  children,
  value,
}: {
  children: ReactNode
  value: Pick<
    TNavbarDataContext,
    | 'primaryNavbarItems'
    | 'socialMediaIcons'
    | 'searchIsActive'
    | 'setSearchIsActive'
    | 'shippingDestinationIsOpen'
    | 'setShippingDestinationIsOpen'
  >
}) {
  const [selectedPrimaryMenuIndex, setSelectedPrimaryMenuIndex] = useState<
    number | null
  >(0)
  const [selectedSecondaryMenuIndex, setSelectedSecondaryMenuIndex] = useState<
    number | null
  >(null)
  const router = useRouter()

  useEffect(() => {
    const primaryNavbarItems = value.primaryNavbarItems
    if (isEmpty(primaryNavbarItems)) {
      return
    }

    const matchedActivePrimaryIndex = matchedPrimaryMenuIndex(
      router.asPath,
      primaryNavbarItems
    )

    if (
      primaryNavbarItems[matchedActivePrimaryIndex]?.secondaryMenuItems
        ?.length > 0
    ) {
      setSelectedPrimaryMenuIndex(matchedActivePrimaryIndex)
    } else {
      setSelectedPrimaryMenuIndex(0)
    }
  }, [router.asPath])

  const providerValue = {
    selectedPrimaryMenuIndex,
    setSelectedPrimaryMenuIndex,
    selectedSecondaryMenuIndex,
    setSelectedSecondaryMenuIndex,
    ...value,
  }

  return (
    <NavbarDataContext.Provider value={providerValue}>
      {children}
    </NavbarDataContext.Provider>
  )
}

function useNavbarData() {
  const context = useContext(NavbarDataContext)
  if (context === undefined) {
    throw new Error('useNavbarData must be used within the NavbarDataContext')
  }
  return { primaryMenuItems: context.primaryNavbarItems }
}

function useSocialMediaIcons() {
  const context = useContext(NavbarDataContext)
  if (context === undefined) {
    throw new Error(
      'useSocialMediaData must be used within the NavbarDataContext'
    )
  }
  return { socialMediaIcons: context.socialMediaIcons }
}

function useSelectedPrimaryMenu() {
  const context = useContext(NavbarDataContext)
  if (context === undefined) {
    throw new Error('usePrimaryMenu must be used within the NavbarDataContext')
  }
  return {
    selectedPrimaryMenu:
      context.primaryNavbarItems?.[context.selectedPrimaryMenuIndex],
    selectedPrimaryMenuIndex: context.selectedPrimaryMenuIndex,
    setSelectedPrimaryMenuIndex: (index) => {
      context.setSelectedPrimaryMenuIndex(index)
    },
  }
}

function useSelectedSecondaryMenu() {
  const context = useContext(NavbarDataContext)
  if (context === undefined) {
    throw new Error(
      'useSelectedSecondaryMenu must be used within the NavbarDataContext'
    )
  }
  return {
    selectedSecondaryMenu:
      context.primaryNavbarItems?.[context.selectedPrimaryMenuIndex]
        ?.secondaryMenuItems?.[context.selectedSecondaryMenuIndex],
    selectedSecondaryMenuIndex: context.selectedSecondaryMenuIndex,
    setSelectedSecondaryMenuIndex: (index) => {
      context.setSelectedSecondaryMenuIndex(index)
    },
  }
}

function useSearchInput() {
  const context = useContext(NavbarDataContext)
  if (context === undefined) {
    throw new Error('useSearchInput must be used within the NavbarDataContext')
  }

  return {
    searchIsActive: context.searchIsActive,
    setSearchIsActive: context.setSearchIsActive,
  }
}

function useShippingDestinationPopUp() {
  const context = useContext(NavbarDataContext)
  if (context === undefined) {
    throw new Error(
      'useShippingDestinationPopUp must be used within the NavbarDataContext'
    )
  }

  return {
    shippingDestinationIsOpen: context.shippingDestinationIsOpen,
    setShippingDestinationIsOpen: context.setShippingDestinationIsOpen,
  }
}

export {
  NavbarDataProvider,
  useNavbarData,
  useSocialMediaIcons,
  useSelectedPrimaryMenu,
  useSelectedSecondaryMenu,
  useSearchInput,
  useShippingDestinationPopUp,
}
