import { trackNavigationClick } from '@utils/gtm'
import cn from 'classnames'
import { useNavbarData, useSelectedPrimaryMenu } from '../NavbarDataProvider'
import { useRouter } from 'next/router'
import { MobileNavHeader } from './MobileNavHeader'
import { KeenSliderWrapper } from '@components/common/KeenSliderWrapper'

export const MobilePrimaryMenu = ({
  handleMenuToggle,
}: {
  handleMenuToggle: () => void
}) => {
  const { primaryMenuItems } = useNavbarData()
  const { selectedPrimaryMenuIndex, setSelectedPrimaryMenuIndex } =
    useSelectedPrimaryMenu()
  const router = useRouter()

  return (
    <MobileNavHeader handleMenuToggle={handleMenuToggle}>
      <KeenSliderWrapper
        initialConfig={{ perView: 'auto', spacing: 24 }}
        className="h-full"
        isShowArrows={false}
        trackingIdentifier="navbar-mobile-primary-menu"
      >
        {primaryMenuItems?.map((pi, idx) => (
          <div
            key={`${pi.primaryMenuTitle} ${idx}`}
            className={`${cn(
              'keen-slider__slide',
              'flex items-center text-[16px] leading-[20px] text-black-60 border-b-[3px] border-transparent h-full !min-w-max !max-w-max ',
              selectedPrimaryMenuIndex === idx
                ? '!text-pitaya border-b-[3px] border-pitaya font-semibold'
                : '',
              pi.secondaryMenuItems?.length > 0 ? '' : 'cursor-pointer'
            )}`}
            onClick={(e) => {
              if (pi?.secondaryMenuItems?.length > 0) {
                setSelectedPrimaryMenuIndex(idx)
              } else {
                if (pi?.primaryMenuLink) {
                  e.preventDefault()
                  trackNavigationClick({
                    clickLocation: 'Primary Menu',
                    clickItem: pi?.primaryMenuTitle,
                  })
                  router.push(pi?.primaryMenuLink).catch(() => {})
                  if (typeof handleMenuToggle === 'function') {
                    handleMenuToggle()
                  }
                }
              }
            }}
          >
            {pi.primaryMenuTitle}
          </div>
        ))}
      </KeenSliderWrapper>
    </MobileNavHeader>
  )
}
