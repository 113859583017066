import React, { ReactNode, JSXElementConstructor } from 'react'
import { HiArrowLeft, HiArrowRight } from 'react-icons/hi'

const LinkIcon = {
  HiArrowLeft,
  HiArrowRight,
}

type IconName = keyof typeof LinkIcon

interface ILink {
  children: ReactNode
  colour?: 'rose' | 'purple'
  onClick?: () => void
  href?: string
  className?: string
  size: 'xs' | 's' | 'm'
  iconName?: IconName
  iconSide?: 'left' | 'right'
  Component?: string | JSXElementConstructor<any>
}

export const Link = ({
  children,
  colour = 'rose',
  size = 'm',
  onClick,
  href,
  className,
  iconName,
  iconSide = 'right',
  Component = 'a',
}: ILink) => {
  const colourClassMapper = {
    rose: 'text-pitaya hover:text-acai',
    purple: 'text-acai hover:text-pitaya',
  }

  const sizeClassMapper = {
    xs: 'text-lg',
    s: 'text-xl',
    m: 'text-2xl',
  }

  const onFocusClass =
    'focus:outline-none focus-visible:ring-2 focus-visible:ring-core-rose focus-visible:ring-offset-2'
  const transitionClass = 'transition-colors duration-200 ease-in-out'

  const DynamicHeroIcon = iconName && LinkIcon[iconName]

  return (
    <Component
      onClick={onClick}
      href={href}
      className={`font-semibold text-center cursor-pointer inline-block group
                    ${transitionClass} ${onFocusClass} 
                    ${colourClassMapper[colour]} 
                    ${sizeClassMapper[size]} 
                    ${className}`}
    >
      <span className="flex items-center justify-center">
        {DynamicHeroIcon && iconSide === 'left' && (
          <DynamicHeroIcon className="w-6 h-6 mr-2" />
        )}
        {children}
        {DynamicHeroIcon && iconSide === 'right' && (
          <DynamicHeroIcon className="w-6 h-6 ml-2" />
        )}
      </span>
    </Component>
  )
}
