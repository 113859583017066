/* CONTENTFUL API ACCESS */
import { ContentfulClientApi, Entry } from 'contentful'
import { NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_COUNTRY } from '../client-side-config'
import {
  getContentfulClients,
  ecommerceContentfulClient,
  ecommerce2ContentfulClient,
  CONTENTFUL_SPACE_IDS,
} from './clients'
import { IProductCategory } from 'types/contentful/productCategory'
import capitalize from 'lodash/capitalize'

export {
  ecommerceContentfulClient,
  ecommerce2ContentfulClient,
  CONTENTFUL_SPACE_IDS,
}

const channelToLocaleMapper = {
  AU: 'en-AU',
  NZ: 'en-NZ',
  GB: 'en-GB',
  CA: 'en-CA',
  US: 'en-US',
  EU: 'en-EU',
  ROW: 'en',
}

export function getLocale() {
  return channelToLocaleMapper[NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_COUNTRY]
}

async function loadAllContents(
  client: ContentfulClientApi,
  contentType: string,
  slug: string,
  useLocale = false
) {
  const productEntries: any = await client.getEntries({
    content_type: contentType,
    order: 'sys.createdAt',
    ...(slug ? { 'fields.urlSlug': slug } : {}),
    locale: useLocale ? getLocale() : 'en-US',
    include: 3,
  })

  if (
    !productEntries ||
    productEntries.total === 0 ||
    !productEntries.items ||
    !Array.isArray(productEntries.items)
  ) {
    console.error(`Content for ${slug} not found`)
  }
  return productEntries
}

async function loadContent(
  client: ContentfulClientApi,
  contentType: string,
  slug: string,
  useLocale = false
) {
  const productEntries = await loadAllContents(
    client,
    contentType,
    slug,
    useLocale
  )
  return productEntries.items[0]
}

export async function loadAllCategoriesIndustriesInformation(
  client = ecommerceContentfulClient
) {
  const contentType = 'categoryPage'
  const productEntries = await loadAllContents(client, contentType, '')
  return productEntries.items
}

export async function loadProductInformation({
  client = ecommerceContentfulClient,
  slug,
  useLocale = false,
}: {
  client?: ContentfulClientApi
  slug: string
  useLocale?: boolean
}) {
  const contentType = 'productCategory'
  return (await loadContent(client, contentType, slug, useLocale)) as Promise<
    Entry<IProductCategory>
  >
}

export async function loadSampleInformation({
  client = ecommerceContentfulClient,
  slug,
}: {
  client?: ContentfulClientApi
  slug: string
}) {
  const contentType = 'samplesProductPages'
  return await loadContent(client, contentType, slug)
}

export async function loadContentFromAllSpaces({
  contentType,
  slug,
  spaceId,
  useLocale = false,
  preview = false,
}: {
  contentType: string
  slug: string
  spaceId?: CONTENTFUL_SPACE_IDS.ECOMMERCE_1 | CONTENTFUL_SPACE_IDS.ECOMMERCE_2
  useLocale?: boolean
  preview?: boolean
}) {
  const clientsToBeUsed = spaceId
    ? getContentfulClients(preview)
        .filter((client) => client.spaceId === spaceId)
        .map((client) => client.client)
    : getContentfulClients(preview).map((client) => client.client)

  const clientPromises = clientsToBeUsed.map((client) => {
    return loadContent(client, contentType, slug, useLocale) as Promise<
      Entry<unknown>
    >
  })

  const results = await Promise.allSettled(clientPromises)
  return results.reduce((accumulator, result) => {
    if (result.status === 'fulfilled') {
      return { ...accumulator, ...result.value }
    } else {
      return accumulator
    }
  }, {}) as Entry<any>
}

export const slugToProductName = (slug) =>
  slug
    ?.split('-')
    ?.map((part) => capitalize(part))
    ?.join(' ')
