import { useState } from 'react'
import VisibilitySensor from 'react-visibility-sensor'

/**
 * Wrapping component for sending GTM event when component is showed
 *
 * @param children
 * @param onVisible
 * @param onHide
 * @constructor
 */
export default function EverestVisibilityDetector({
  children,
  onVisible,
  onHide,
  once,
  offset,
}: {
  children: any
  onVisible?: () => void
  onHide?: () => void
  once?: boolean
  offset?: { top: number }
}) {
  const [active, setActive] = useState(true)

  return (
    <VisibilitySensor
      partialVisibility={true}
      intervalDelay={250}
      scrollCheck={true}
      delayedCall={true}
      active={once ? active : true}
      offset={offset}
      onChange={(isVisible) => {
        if (isVisible && onVisible) {
          onVisible()
        } else if (!isVisible && onHide) {
          onHide()
        }
        if (isVisible && once) {
          setActive(false)
        }
      }}
    >
      {children}
    </VisibilitySensor>
  )
}
