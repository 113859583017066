import React from 'react'
import EverestVisibilityDetector from '@components/common/EverestVisibilityDetector'
import { trackProductClick, trackProductImpression } from '@utils/gtm'
import { ContentfulNextJsImage } from '@components/common/ContentfulImage'
import { useRouter } from 'next/router'
import { isProductCustomisable } from '@components/product/ProductPage/ProductPage.utils'
import { Button } from '@noissue-ui-kit/button'
import { TRecommendedProduct } from 'types/crossSell'
import { useUI } from '@components/ui/context'
import { LOCAL_STORAGE_KEY_CROSS_SELL_DISCOUNT_ACTIVE } from '@utils/constants'
import { appendOrReplaceItemToListToLocalStorage } from '@utils/localstorage'

export function CartCrossSell({
  heading,
  crossSellItems,
}: {
  crossSellItems: TRecommendedProduct[]
  heading: string
  showDiscountLabel: boolean
}) {
  const router = useRouter()
  const { closeSidebar } = useUI()

  return (
    <div className="py-16 pr-0 bg-black-5">
      <p className="mb-8 pl-16 text-2xl font-bold text-acai">{heading}</p>
      <div className="flex pl-16 pb-6 overflow-x-auto">
        {crossSellItems?.map((item, index) => {
          const buttonText = isProductCustomisable(item?.linkUrl)
            ? 'Continue'
            : 'Shop Now'

          return (
            <EverestVisibilityDetector
              key={`${index}-visibility-detector-minicart`}
              onVisible={() => {
                if (item?.linkUrl) {
                  trackProductImpression({
                    list: 'Minicart cross-sell',
                    item: {
                      name: item?.crossSellProductName,
                      slug: item?.linkUrl,
                    },
                    position: index,
                  })
                }
              }}
            >
              <div
                className="flex flex-col items-center justify-between flex-shrink-0 mr-8 w-[120px]"
                key={index}
              >
                <ContentfulNextJsImage
                  className={'w-full rounded-full'}
                  alt={item?.crossSellProductImage?.imageTitle}
                  src={item.crossSellProductImage?.imageUrl}
                  loading="lazy"
                  format="jpg"
                  backgroundColour="#F2ECE9"
                  quality={75}
                  height={96}
                  width={96}
                />
                <div className="w-full mt-6 mb-4 text-xl font-semibold leading-tight text-center text-acai font-mori">
                  {item?.crossSellProductName}
                </div>

                <div className="block text-core-rose text-center font-bold mt-auto mb-[5px] font-mori">
                  {item?.discountType === 'Percentage' &&
                    item?.discountAmount > 0 &&
                    `Save ${item?.discountAmount}%`}
                </div>

                <Button
                  size="xs"
                  corners="rounded"
                  colour="acai"
                  variant="outline"
                  onClick={() => {
                    trackProductClick({
                      list: 'Minicart cross-sell',
                      buttonText: buttonText,
                      item: {
                        name: item?.crossSellProductName,
                        slug: item?.linkUrl,
                      },
                      position: index,
                    })
                    closeSidebar()

                    const activeCrossSellDiscount = {
                      cta: true,
                      slug: item?.productSlug,
                    }
                    appendOrReplaceItemToListToLocalStorage(
                      LOCAL_STORAGE_KEY_CROSS_SELL_DISCOUNT_ACTIVE,
                      JSON.stringify(activeCrossSellDiscount)
                    )

                    router.push(item?.linkUrl).catch(() => {})
                  }}
                  className="w-full pt-4 font-mori"
                >
                  {buttonText}
                </Button>
              </div>
            </EverestVisibilityDetector>
          )
        })}
      </div>
    </div>
  )
}
