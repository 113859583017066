import emojiUnicode from 'emoji-unicode'

function encodeEmoji(input) {
  const c = emojiUnicode.raw(input).split(' ')
  return c.reduce((acc, codePoint) => {
    // convert code point to surrogate pair
    const C = parseInt(codePoint)
    const H = Math.floor((C - 0x10000) / 0x400) + 0xd800
    const L = ((C - 0x10000) % 0x400) + 0xdc00
    return acc.concat(`\\u${H.toString(16)}\\u${L.toString(16)}`)
  }, '')
}

export function escapeEmoji(input) {
  return input.replace(
    /(\p{Emoji_Presentation}|\p{Extended_Pictographic})/gu,
    encodeEmoji
  )
}
