import { useCallback } from 'react'
import type { MutationHook } from '@commerce/utils/types'
import { CommerceError } from '@commerce/utils/errors'
import useAddItem, { UseAddItem } from '@commerce/cart/use-add-item'
import { normalizeCart } from '../lib/normalize'
import type {
  Cart,
  BigcommerceCart,
  CartItemBody,
  AddCartItemBody,
} from '../types'
import useCart from './use-cart'
import { NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_CURRENCY } from '@utils/client-side-config'
import { trackAddToCart } from '@utils/gtm'
import {
  sentryOnZeroPriceItemAddedToCart,
  sentryOnZeroPriceItemInCart,
} from '@utils/sentryOnZeroPriceItemAddedToCart'
import * as Sentry from '@sentry/nextjs'

// eslint-disable-next-line no-use-before-define
export default useAddItem as UseAddItem<typeof handler>

export const handler: MutationHook<Cart, {}, CartItemBody> = {
  fetchOptions: {
    url: '/api/bigcommerce/cart/',
    method: 'POST',
  },
  async fetcher({ input: item, options, fetch }) {
    if (
      item.quantity &&
      (!Number.isInteger(item.quantity) || item.quantity! < 1)
    ) {
      throw new CommerceError({
        message: 'The item quantity has to be a valid integer greater than 0',
      })
    }

    const data = await fetch<BigcommerceCart, AddCartItemBody>({
      ...options,
      body: {
        item,
        currency: {
          code: NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_CURRENCY || 'NZD',
        },
      },
    })

    const cart = normalizeCart(data)

    const cartItems = cart.lineItems
    sentryOnZeroPriceItemInCart(
      cartItems,
      'ProductAddToCartError',
      'Product added to cart with zero price'
    )

    return cart
  },
  useHook:
    ({ fetch }) =>
    () => {
      const { mutate } = useCart()

      return useCallback(
        async function addItem(input) {
          const data = await fetch({ input })
          await mutate(data, false)

          trackAddToCart({
            currency: data.currency,
            lineItems: [data?.lineItems[0]],
          })
          return data
        },
        [fetch, mutate]
      )
    },
}
