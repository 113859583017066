import type { LineItem } from '@framework/types'
import pMemoize from 'p-memoize'
import { v4 } from 'uuid'
import { LOCAL_STORAGE_KEY_CROSS_SELL_DISCOUNT_ACTIVE } from './constants'
import {
  getItemFromLocalStorage,
  removeItemFromListFromLocalStorage,
} from './localstorage'

async function loadCrossSellData(data: LineItem[]) {
  const itemUrls = data?.map((item) => ({
    originalUrl: item.originalUrl,
    categorySlug: item.categorySlug,
  }))
  const encodedItems =
    data?.length === 0 ? '' : btoa(encodeURIComponent(JSON.stringify(itemUrls)))
  const returnItem = await fetch(
    `/api/bigcommerce/cross-sell/?items=${encodedItems}`
  ).then((res) => res.json())
  return returnItem
}

export const memoizeLoadCrossSellData = pMemoize(loadCrossSellData, {
  maxAge: 1000 * 60 * 5,
  cacheKey: (arguments_) => {
    const cartItems = arguments_[0] || []
    const latestItem = cartItems[0]
    const cacheKey = latestItem?.categorySlug || v4() // A random uuid to avoid cache wrong item
    return cacheKey
  },
})

export const generateCrossSellMetadataValue = (productSlug: string) => {
  const metadataValue: any = { pdpSlug: productSlug } // Always store pdpSlug

  const activeCrossSellDiscounts = getItemFromLocalStorage(
    LOCAL_STORAGE_KEY_CROSS_SELL_DISCOUNT_ACTIVE
  )

  const activeDiscount = activeCrossSellDiscounts?.find(
    (acsd) => productSlug === acsd.value.slug && acsd.value.cta
  )

  if (activeDiscount) {
    metadataValue.crossSellDiscount = {
      cta: true,
    }
  }

  removeItemFromListFromLocalStorage(
    LOCAL_STORAGE_KEY_CROSS_SELL_DISCOUNT_ACTIVE,
    productSlug
  )

  return JSON.stringify(metadataValue)
}
