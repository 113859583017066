import { useState, useEffect } from 'react'

export interface IWindowSize {
  width: number
  height: number
  screenWidth?: number
  screenHeight?: number
  bodyWidth?: number
  bodyHeight?: number
}

export function useWindowSize(externalDependencies = []): IWindowSize {
  const [windowSize, setWindowSize] = useState<IWindowSize>({
    width: null,
    height: null,
    screenWidth: null,
    screenHeight: null,
    bodyWidth: null,
    bodyHeight: null,
  })

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        setWindowSize({
          width: window?.innerWidth,
          height: window?.innerHeight,
          screenWidth: screen?.width,
          screenHeight: screen?.height,
          bodyWidth: document?.body?.clientWidth,
          bodyHeight: document?.body?.clientHeight,
        })
      }

      window.addEventListener('resize', handleResize)
      handleResize()

      return () => window.removeEventListener('resize', handleResize)
    }
  }, [...externalDependencies])

  return windowSize
}
