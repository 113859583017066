import React, { useEffect, useState } from 'react'
import { ContentfulMarkdown } from '@utils/contentful/ContentfulMarkdown'
import { trackNavigationClick } from '@utils/gtm'
import { useRouter } from 'next/router'
import { IAnnouncementBanner } from '@utils/contentful/getSharedPageData/getSharedPageData'
import Link from 'next/link'
import { useSession } from 'next-auth/client'
import { loadZendeskScript } from '@utils/utilities'

export const ANNOUNCEMENT_BAR_ID = 'announcementBar'

export function AnnouncementBanner({
  announcements,
  secondaryLinks,
  backgroundColor,
  textColor,
  trackingKey,
}: IAnnouncementBanner & { trackingKey: any }) {
  const [activeAnnouncementIndex, setActiveAnnouncementIndex] = useState(0)
  const [session] = useSession()
  const router = useRouter()

  useEffect(() => {
    const timeoutID = setTimeout(() => {
      if (activeAnnouncementIndex < announcements?.length - 1) {
        setActiveAnnouncementIndex(activeAnnouncementIndex + 1)
      } else {
        setActiveAnnouncementIndex(0)
      }
    }, 5000)

    return () => clearTimeout(timeoutID)
  }, [activeAnnouncementIndex])

  const isNextAnnouncement = (index) => {
    if (index === announcements?.length - 1 && activeAnnouncementIndex === 0) {
      return false
    }

    if (activeAnnouncementIndex === announcements?.length - 1 && index === 0) {
      return true
    }

    return index - activeAnnouncementIndex > 0
  }

  return (
    <div
      id={ANNOUNCEMENT_BAR_ID}
      className="px-8 lg:px-16 flex justify-start"
      style={{
        backgroundColor: backgroundColor,
        color: textColor,
      }}
    >
      <div className="flex justify-start content-container">
        {/* Announcements */}
        <div className="mr-auto relative w-full md:w-auto h-[50px] md:h-[32px]">
          {announcements?.length > 0 &&
            announcements?.map((item, index) => {
              return (
                <div
                  key={item?.text}
                  className={`
                    w-auto cursor-pointer absolute top-0 left-0 right-0 bottom-0
                    text-center md:text-left font-mori py-[8px] px-3 lg:px-0 transition-all
                    duration-[1s] hover:opacity-80 ease-in-out flex items-center whitespace-normal md:whitespace-nowrap
                    ${
                      activeAnnouncementIndex === index
                        ? 'visible opacity-100 translate-y-0'
                        : isNextAnnouncement(index)
                        ? 'invisible opacity-0 translate-y-12 py-0'
                        : 'invisible opacity-0 -translate-y-12 py-0'
                    }`}
                  onClick={() => {
                    trackNavigationClick({
                      clickLocation: trackingKey,
                      clickItem: item?.text,
                    })
                    router.push(item?.link).catch(() => {})
                  }}
                >
                  <ContentfulMarkdown
                    overrideClass={`mb-0 font-mori text-current text-lg w-full`}
                  >
                    {item.text}
                  </ContentfulMarkdown>
                </div>
              )
            })}
        </div>

        {/* Links */}
        <div className="hidden lg:flex ml-auto items-center text-lg font-mori text-current">
          {secondaryLinks?.length > 0 &&
            secondaryLinks?.map((linkItem) => {
              return (
                <Link
                  href={linkItem.link}
                  key={linkItem?.text}
                  className="mr-16 hover:opacity-80"
                >
                  {linkItem?.text}
                </Link>
              )
            })}

          {session?.hubspotOwnerId ? (
            <a href="/admin/orders" className="hover:opacity-80">
              Contact Account Manager
            </a>
          ) : (
            <button
              onMouseOver={() => loadZendeskScript()}
              onClick={() => {
                // @ts-ignore
                window.zE && window.zE('webWidget', 'toggle')
              }}
              className="hover:opacity-80"
            >
              Contact Us
            </button>
          )}
        </div>
      </div>
    </div>
  )
}
