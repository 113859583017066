import React from 'react'
import Link from '@components/common/NoPrefetchLink'
import {
  FaFacebookSquare,
  FaInstagram,
  FaLinkedinIn,
  FaPinterestP,
  FaTiktok,
} from 'react-icons/fa'

type TIconSize = 'S' | 'L'

function getIconSize(iconSize: TIconSize) {
  switch (iconSize) {
    case 'S':
      return 'text-[2rem]'
    case 'L':
    default:
      return 'text-[3rem]'
  }
}

export function SocialLinks({ iconSize = 'L' }: { iconSize?: TIconSize }) {
  const sizeClassName = getIconSize(iconSize)
  return (
    <>
      <Link href="https://www.instagram.com/noissueco/">
        <a
          className={`${sizeClassName} m-4 hover:text-acai-100`}
          aria-label="Instagram"
        >
          <FaInstagram />
        </a>
      </Link>
      <Link href="https://www.facebook.com/noissueco/">
        <a
          className={`${sizeClassName} m-4 hover:text-acai-100`}
          aria-label="Facebook"
        >
          <FaFacebookSquare />
        </a>
      </Link>
      <Link href="https://vt.tiktok.com/ZSTn23MH/">
        <a
          className={`${sizeClassName} m-4 hover:text-acai-100`}
          aria-label="TikTok"
        >
          <FaTiktok />
        </a>
      </Link>
      <Link href="https://www.pinterest.nz/noissuepackaging/">
        <a
          className={`${sizeClassName} m-4 hover:text-acai-100`}
          aria-label="Pinterest"
        >
          <FaPinterestP />
        </a>
      </Link>
      <Link href="https://www.linkedin.com/company/noissue/">
        <a
          className={`${sizeClassName} m-4 hover:text-acai-100`}
          aria-label="LinkedIn"
        >
          <FaLinkedinIn />
        </a>
      </Link>
    </>
  )
}
