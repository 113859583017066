import { useEffect, useState } from 'react'
import { useSession, SignInOptions } from 'next-auth/client'
import { signInWithOneTap } from '@utils/authHelpers'
import { useLocationMismatchCheck } from '@components/common/Navbar/LocationMismatchPopup/useLocationMismatchCheck'
import Cookie from 'js-cookie'
import { USER_LOCATION_POPUP_INTERACTION } from '@utils/constants'

interface OneTapSigninOptions {
  parentContainerId?: string
}

const useOneTapSignin = (
  options?: OneTapSigninOptions &
    Pick<SignInOptions, 'redirect' | 'callbackUrl'>
) => {
  const { parentContainerId } = options || {}
  const [isLoading, setIsLoading] = useState(true)
  const { locationDoesMismatch } = useLocationMismatchCheck()

  // Taking advantage in recent development of useSession hook.
  // If user is unauthenticated, google one tap ui is initialized and rendered
  const [session, loading] = useSession()

  const mismatchPopupWasShown = Boolean(
    Cookie.get(USER_LOCATION_POPUP_INTERACTION)
  )

  useEffect(() => {
    if (
      loading ||
      session ||
      (locationDoesMismatch && !mismatchPopupWasShown) // hide while region selector is open unless dismissed
    ) {
      return
    }

    // @ts-ignore
    const { google } = window
    if (!google) {
      return
    }

    google.accounts.id.initialize({
      itp_support: true,
      client_id: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
      callback: async (response: any) => {
        setIsLoading(false)

        // Here we call our Provider with the token provided by google
        await signInWithOneTap({
          credential: response.credential,
          redirectRouteParameter: options.callbackUrl,
        })
      },
      prompt_parent_id: parentContainerId,
    })

    // Seems to only show if this exists
    google.accounts.id.prompt((notification) => {
      console.log(notification)
    })
  }, [loading, session])

  return { isLoading }
}

export default useOneTapSignin
