import { ContentfulNextJsImage } from '@components/common/ContentfulImage'
import { trackNavigationClick } from '@utils/gtm'
import { useState } from 'react'
import { TSocialMediaIcon } from '../../Navbar.types'
import { useSocialMediaIcons } from '../../NavbarDataProvider'

function SocialMediaIcon({ icon }: { icon: TSocialMediaIcon }) {
  const [isHovered, setIsHovered] = useState(false)
  return (
    <div
      key={icon?.socialIcon?.imageTitle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {icon?.socialIcon?.imageUrl &&
        icon?.socialIconHover?.imageUrl &&
        icon?.socialLink && (
          <a
            href={icon?.socialLink}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              if (icon?.socialLink) {
                trackNavigationClick({
                  clickLocation: 'Social Media Icon',
                  clickItem: icon?.socialIcon?.imageTitle,
                })
              }
            }}
          >
            <div className={`${!isHovered ? '' : 'hidden'}`}>
              <ContentfulNextJsImage
                width={20}
                height={20}
                src={icon?.socialIcon?.imageUrl}
                alt={icon?.socialIcon?.imageTitle}
                loading="eager"
              />
            </div>
            <div className={`${isHovered ? '' : 'hidden'}`}>
              <ContentfulNextJsImage
                width={20}
                height={20}
                src={icon?.socialIconHover?.imageUrl}
                alt={icon?.socialIconHover?.imageTitle}
                loading="eager"
              />
            </div>
          </a>
        )}
    </div>
  )
}

export function SocialMediaIcons() {
  const { socialMediaIcons } = useSocialMediaIcons()

  return socialMediaIcons?.length > 0 ? (
    <div className="flex items-center gap-[8px] h-[20px]">
      {socialMediaIcons?.map((icon, index) => {
        return <SocialMediaIcon icon={icon} key={index} />
      })}
    </div>
  ) : null
}
