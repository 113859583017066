import { FC } from 'react'

interface Props {
  className?: string
}

const TheWrap: FC<Props> = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 1417.3 558.8"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="currentColor">
      <path d="m110 261.1h56.4v-19.9h-56.4v-16.2h-17.8v52.3h17.8z" />
      <path d="m166.4 193h-29.2v-22.9h29.2v-19.9h-74.2v19.9h27.2v22.9h-27.2v20h74.2z" />
      <path d="m166.4 81.6h-17.9v29.7h-11.3v-29.7h-17.8v29.7h-9.4v-29.7h-17.8v49.7h74.2z" />
      <path d="m445.2 191.6-22.3-83.2h-29.5l-23.2 83.3-21.4-83.3h-35.8l39.8 142.1h30.1l24.4-86 23.8 86h31.1l40.8-142.1h-35.8z" />
      <path d="m605.6 191.3c7.9-3 14.5-7.6 19.8-13.6 6-6.7 9-15.4 9-25.9 0-7.4-1.6-14.1-4.9-20s-7.6-10.5-13.1-13.8c-5.3-3.2-10.9-5.6-16.7-7.2s-11.8-2.4-17.9-2.4h-45.8v142.1h32.2v-55h1.9l37.9 55h40.6l-2.4-3.2c-13.7-18.7-27.4-37.5-40.6-56zm-11.2-27.7c-4.2 2.6-9.9 3.9-17 3.9h-9v-31.3h12.8c5.8 0 10.5 1.5 14 4.5 3.4 3 5.1 6.8 5.1 11.6-.1 5.2-2 8.8-5.9 11.3z" />
      <path d="m728.9 108.4-58.1 142.1h35.8l9.3-25.6h46.3l8.9 25.6h36.9l-53.4-142.1zm-3.2 89.3 13.9-38.6 13.3 38.6z" />
      <path d="m923.1 118.2c-5.3-3.3-11-5.8-17-7.4s-12.2-2.4-18.6-2.4h-46v142.1h32.2v-52.1h13.8c6.4 0 12.7-.8 18.7-2.4s11.7-4.1 17-7.4c5.5-3.4 9.9-8.2 13.1-14.3s4.9-13.1 4.9-21.1c0-7.8-1.7-14.8-5-20.8s-7.7-10.8-13.1-14.2zm-40.2 52.3h-9.2v-34.2h13c6.3 0 11.4 1.6 15.1 4.7 3.6 3.1 5.4 7.3 5.4 12.8 0 11.2-8 16.7-24.3 16.7z" />
      <path d="m1236.5 131.3-172.5-118.7c-10.1-7.6-22.5-11.7-35.1-11.7h-33.9-19.6-909.2c-36.4 0-66 29.6-66 66v225c0 36.4 29.6 66 66 66h909.3 53.2c12.9 0 25.6-4.3 35.8-12.2l171.5-121.7 1.1-.9c14.1-11.2 22.1-28 22.00184-46.1-.10184-17.9-8.40184-34.6-22.60184-45.7zm-1028.5 176.2h-141.8c-8.4 0-15.6-7.1-15.6-15.6v-225c0-8.4 7.1-15.6 15.6-15.6h141.8zm792-81.6c-4.4 15-13.3 47.3-8.3 81.6h-4.9-728.3v-256.1h728.3v-.1h.3v.1h7.7c-2.8 20.3-2.5 46.6 7.3 75.8-14.7 39.3-10.1 74.1-2.1 98.7zm155-6.3-113.3 80.4c-3.6-24.8 4-50.3 7.4-61.9 3-10.2 3-18.6-.1-25.1-6.2-17.4-10.3-43.6 2.7-73.4 3.6-8.2 3.8-15.8.6-22.6-8.7-22.1-9.4-41.7-7.7-56.6l111.4 76.7c-5.7 11.1-9.9 24.7-9.9 41.1 0 16.2 3.7 30 8.9 41.4z" />
    </g>
  </svg>
)

export default TheWrap
