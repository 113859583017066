import { ContentfulClientApi, Entry, EntryCollection } from 'contentful'
import { getLocale } from '.'

export const fetchAllEntriesOfType = async ({
  client,
  contentType,
}: {
  client: ContentfulClientApi
  contentType: string
}) => {
  let skipEntriesOnNextRequest = 0
  let totalEntriesOfThisType = 0
  const limitToFetch = 100
  const fetchedEntries: Entry<any>[] = []

  do {
    const fetcheEntriesPerRequest = (await client.getEntries({
      content_type: contentType,
      order: 'sys.createdAt',
      limit: limitToFetch,
      skip: skipEntriesOnNextRequest,
      locale: getLocale(),
    })) as EntryCollection<any>

    fetchedEntries.push(...fetcheEntriesPerRequest?.items)

    totalEntriesOfThisType = fetcheEntriesPerRequest.total
    skipEntriesOnNextRequest += limitToFetch
  } while (fetchedEntries.length < totalEntriesOfThisType)

  return fetchedEntries
}
