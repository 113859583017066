import Image from 'next/image'

type TPaymentMehtod =
  | 'visa'
  | 'mastercard'
  | 'amex'
  | 'applePay'
  | 'googlePay'
  | 'payPal'

export function CartPaymentMethods() {
  const paymentMethodsIcons: Record<TPaymentMehtod, string> = {
    visa: '/payment_methods/visa.svg',
    mastercard: '/payment_methods/mastercard.svg',
    amex: '/payment_methods/amex.svg',
    applePay: '/payment_methods/apple_pay.svg',
    googlePay: '/payment_methods/google_pay.svg',
    payPal: '/payment_methods/pay_pal.svg',
  }

  return (
    <div className="flex flex-row mx-auto my-8">
      {Object.values(paymentMethodsIcons).map((iconUrl) => {
        return (
          <Image
            key={iconUrl}
            src={iconUrl}
            alt="Payment Method Icon"
            aria-hidden="true"
            width="40"
            height="26"
            className="mx-3"
          />
        )
      })}
    </div>
  )
}
