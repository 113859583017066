import { RefObject, useEffect, useState } from 'react'

export const useIsOutsideDOMElement = (
  ref: RefObject<any>,
  logicFn?: (element: HTMLElement) => boolean
) => {
  const [isOutside, setIsOutside] = useState(false)
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current) {
        let extraCondition = true
        if (typeof logicFn === 'function') {
          extraCondition = logicFn(ref.current)
        }

        if (!ref.current.contains(event.target) && extraCondition) {
          setIsOutside(true)
        } else {
          setIsOutside(false)
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  return isOutside
}
