// eslint-disable-next-line @typescript-eslint/no-var-requires
const pino = require('pino')

const logger = pino({
  level: process.env.LOG_LEVEL || 'debug',
  nestedKey: 'detail',
  formatters: {
    level(label) {
      return { level: label }
    },
  },
})

module.exports = logger
