import { Link } from '@noissue-ui-kit/oldBranding/link'
import { ContentfulMarkdown } from '@utils/contentful/ContentfulMarkdown'
import { trackNavigationClick } from '@utils/gtm'
import { useRouter } from 'next/router'
import { TSecondaryMenuItem } from '../../Navbar.types'
import { NavbarTile } from '../TileSection/NavbarTile'
import { computeContentDropdownClasses } from './Dropdown.utils'
import { useTrackingBreadcrumbs } from '@utils/hooks/useTrackingBreadcrumbs'
import { useSelectedPrimaryMenu } from '@components/common/Navbar/NavbarDataProvider'

export function ContentDropdown({
  selectedSecondaryMenu,
}: {
  selectedSecondaryMenu: TSecondaryMenuItem
}) {
  const router = useRouter()
  const { selectedPrimaryMenu } = useSelectedPrimaryMenu()
  const content = selectedSecondaryMenu?.content
  const { saveBreadcrumbs } = useTrackingBreadcrumbs()

  const hasFeatureTiles = selectedSecondaryMenu?.tiles?.some(
    (tile) => tile?.desktopLayout === 'feature'
  )
  return (
    <>
      <div className="col-span-3 row-span-2 pr-[32px]">
        {content?.title && (
          <h4 className="text-[32px] text-core-purple leading-[40px] font-bold mb-[8px]">
            {content?.title}
          </h4>
        )}

        {content?.description && (
          <ContentfulMarkdown overrideClass="text-core-grey-90">
            {content?.description}
          </ContentfulMarkdown>
        )}

        {content?.ctaLabel && content?.ctaLink && (
          <Link
            size="m"
            className="mb-3 font-normal mt-[24px] inline-block"
            iconName="HiArrowRight"
            iconSide="right"
            colour="rose"
            href={content?.ctaLink}
            onClick={() => {
              if (content?.ctaLink) {
                trackNavigationClick({
                  clickLocation: 'Dropdown CTA',
                  clickItem: content?.ctaLabel,
                })

                saveBreadcrumbs([
                  {
                    name: selectedPrimaryMenu.primaryMenuTitle,
                    url: selectedPrimaryMenu.primaryMenuLink,
                  },
                  {
                    name: content?.ctaLabel,
                    url: content?.ctaLink,
                  },
                ])

                router.push(content?.ctaLink).catch(() => {})
              }
            }}
          >
            {content?.ctaLabel}
          </Link>
        )}
      </div>
      <div
        className={`flex justify-end ${computeContentDropdownClasses({
          numberOfTiles: selectedSecondaryMenu?.tiles?.length,
        })}`}
      >
        {selectedSecondaryMenu?.tiles?.map((tile, index) => (
          <NavbarTile
            title={tile?.title}
            titleSize={tile?.titleSize}
            titleColor={tile?.titleColor}
            subtitle={tile?.subtitle}
            subtitleColor={tile?.subtitleColor}
            tileLink={tile?.blockLink}
            backgroundColor={tile?.backgroundColor}
            image={tile?.image}
            images={tile?.images}
            key={index}
            layout={tile?.desktopLayout}
            siblingIsFeatureTile={hasFeatureTiles}
          />
        ))}
      </div>
    </>
  )
}
