import Link from '@components/common/NoPrefetchLink'
import React from 'react'

export function PrivaryPolicy({ className }: { className?: string }) {
  return (
    <Link href="/privacy-policy?tab=privacy">
      <a className={`px-4 ${className}`}>Privacy Policy</a>
    </Link>
  )
}
