import Cookie from 'js-cookie'
import { COOKIE_DATALAYER } from '@utils/constants'
import { signOut } from 'next-auth/client'

export async function clearCustomerAndSignOut({
  redirect,
}: {
  redirect: boolean
}) {
  Cookie.remove(COOKIE_DATALAYER)
  await signOut({ redirect })
}
