import isEmpty from 'lodash/isEmpty'
import type { LineItem } from '@framework/types'
import { getCrossSellDiscountConfiguration } from '@utils/contentful/discountConfiguration/getCrossSellDiscountConfiguration'
import { memoizeLoadCrossSellData } from '@utils/crossSell'
import { TCrossSellDiscount } from 'types/contentful/crossSellDiscount'
import { TRecommendedProduct } from 'types/crossSell'

const crossSellDiscountHandler = {
  inclusion(discountItem: TCrossSellDiscount, slugsInCart: string[]) {
    const inclusionList = discountItem.inclusionExclusionList

    let isApplyDiscount = false
    for (const slugInCart of slugsInCart) {
      if (inclusionList?.includes(slugInCart)) {
        isApplyDiscount = true
        break
      }
    }

    if (isApplyDiscount) {
      return discountItem
    }

    return {} as TCrossSellDiscount
  },
  exclusion(discountItem: TCrossSellDiscount, slugsInCart: string[]) {
    const exclusionList = discountItem.inclusionExclusionList

    let isApplyDiscount = true
    for (const slugInCart of slugsInCart) {
      if (exclusionList?.includes(slugInCart)) {
        isApplyDiscount = false
        break
      }
    }

    if (isApplyDiscount) {
      return discountItem
    }

    return {} as TCrossSellDiscount
  },
  all(discountItem: TCrossSellDiscount) {
    return discountItem
  },
}

const getCrossSellDiscount = (
  discountItem: TCrossSellDiscount,
  slugsInCart: string[]
) => {
  return crossSellDiscountHandler[discountItem?.applyDiscountTo]?.(
    discountItem,
    slugsInCart
  )
}

export const getValidCrossSellDiscounts = (
  productSlug: string,
  slugsInCart: string[],
  crossSellDiscountConfiguration: TCrossSellDiscount[]
) => {
  const discountCrossSellItem = crossSellDiscountConfiguration.find(
    (config) => config.slug === productSlug
  )

  if (discountCrossSellItem) {
    const validDiscount = getCrossSellDiscount(
      discountCrossSellItem,
      slugsInCart
    )

    if (!isEmpty(validDiscount)) {
      return validDiscount
    }
  }
  return {} as TCrossSellDiscount
}

export const getCrossSellItemsWithDiscounts = async (
  cartLineItems: LineItem[]
) => {
  const crossSellItems = (await memoizeLoadCrossSellData(
    cartLineItems
  )) as TRecommendedProduct[]

  // TODO: For performance, we may need to memoize cross sell discount configuration as well,
  // but should get direct from contentful once there is anything change in contentful
  const crossSellDiscountConfiguration =
    await getCrossSellDiscountConfiguration()

  const hasCrossSellDiscountItems = crossSellItems.map((crossSellItem) => {
    const discountCrossSellItem = crossSellDiscountConfiguration.find(
      (config) => config.slug === crossSellItem.productSlug
    )

    if (discountCrossSellItem) {
      const { discountType, discountAmount } =
        getCrossSellDiscount(
          discountCrossSellItem,
          cartLineItems.map((cartItem) => cartItem.categorySlug)
        ) || {}
      if (discountType && discountAmount) {
        return {
          ...crossSellItem,
          discountType,
          discountAmount,
        }
      }
    }

    return crossSellItem
  })

  return hasCrossSellDiscountItems
}
