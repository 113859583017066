import { useDevice } from '@utils/responsiveness/useDevice'

export function SearchLoadingStateSkeleton() {
  const loadingItemsWidth = [70, 50, 60]
  const { isMobile } = useDevice()

  return (
    <ul className="flex flex-col w-full p-0 pt-4 list-none sm:pt-0 animate-pulse">
      {loadingItemsWidth.map((item) => (
        <li className="flex items-center justify-start w-full mb-5" key={item}>
          <div className="flex rounded-full shrink-0 w-14 h-14 sm:w-11 sm:h-11 bg-black-20 mr-7 sm:mr-5" />
          <div
            className={`h-6 sm:h-4 rounded-lg w-full bg-black-20 sm:max-w-full`}
            style={{ maxWidth: (isMobile ? 100 : item) + '%' }}
          />
        </li>
      ))}
    </ul>
  )
}
