import React from 'react'
import { FooterLinksMobile } from './FooterLinksMobile'
import { FooterLinksDesktop } from './FooterLinksDesktop'

const Bottom = () => (
  <>
    <FooterLinksMobile className="md:hidden font-mori" />
    <FooterLinksDesktop className="hidden md:flex font-mori" />
  </>
)

export default Bottom
