import { ContentfulMarkdown } from '@utils/contentful/ContentfulMarkdown'
import { TSecondaryMenuItem } from '../Navbar.types'
import { MobileTiles } from './MobileTiles'

export const MobileContentDropdown = ({
  secondaryMenuItem,
  handleMenuToggle,
}: {
  secondaryMenuItem: TSecondaryMenuItem
  handleMenuToggle: () => void
}) => {
  return (
    <>
      <div className="flex flex-col items-start gap-[12px] w-full">
        <div className="font-bold text-[26px] leading-[36px] text-boulder">
          {secondaryMenuItem?.content?.title}
        </div>
        <ContentfulMarkdown overrideClass="text-black-60 w-full">
          {secondaryMenuItem?.content?.description}
        </ContentfulMarkdown>
      </div>
      <div className="w-full" onClick={handleMenuToggle}>
        <MobileTiles tiles={secondaryMenuItem?.tiles} />
      </div>
    </>
  )
}
