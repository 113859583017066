import React from 'react'
import { Popover, Transition } from '@headlessui/react'
import { UserNavButton } from './UserNavButton'
import { UserNavItem } from './UserNavItem'
import { CustomisedUserGreeting } from './CustomisedUserGreeting'
import { clearCustomerAndSignOut } from '@utils/auth/clearCustomerAndSignOut'
import { StoreCreditCard } from '@components/ui/AdminSidebar/StoreCreditCard'
import { useCustomer } from '@framework/customer'
import { NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_COUNTRY } from '@utils/client-side-config'

async function logout(event) {
  event.preventDefault()
  await clearCustomerAndSignOut({ redirect: true })
}

export function AccountPopup() {
  const { data: customer } = useCustomer()

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={`mx-6 transition-all duration-300 rounded-full focus:ring-2 focus:ring-core-rose ${
              open ? ' ring-pitaya ring focus:ring' : 'ring-core-grey-lighter'
            }`}
          >
            <UserNavButton />
          </Popover.Button>
          <Transition
            enter="transition duration-300 ease-out"
            enterFrom="transform -translate-y-5 opacity-0"
            enterTo="transform translate-y-[4px] opacity-100"
            leave="transition duration-300 ease-out"
            leaveFrom="transform translate-y-[4px] opacity-100"
            leaveTo="transform -translate-y-5 opacity-0"
          >
            <Popover.Panel className="absolute font-mori z-10 p-10 pt-0 mt-6 w-96 bg-core-white rounded-b-3xl drop-shadow-lg -right-[45px]">
              {({ close }) => (
                <>
                  <CustomisedUserGreeting />

                  {customer?.storeCredit &&
                    NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_COUNTRY === 'US' && (
                      <StoreCreditCard
                        storeCredit={customer?.storeCredit}
                        compactView={true}
                        className="mb-10"
                      />
                    )}

                  <div className="flex flex-col text-xl divide-y text-boulder divide-core-grey-light">
                    <UserNavItem
                      itemLabel="My Orders"
                      itemIconUrl="/images/cart-box-boulder.svg"
                      itemUrl="/admin/orders/"
                      onClick={close}
                    />
                    <UserNavItem
                      itemLabel="My Projects"
                      itemIconName="HiOutlineSave"
                      itemUrl="/admin/saved-designs/"
                      onClick={close}
                    />
                    <UserNavItem
                      itemLabel="Account Details"
                      itemIconName="HiOutlineCog"
                      itemUrl="/admin/account/"
                      onClick={close}
                    />
                    <a
                      href="/logout"
                      className="text-xl font-bold text-pitaya pt-7"
                      onClick={logout}
                    >
                      Logout
                    </a>
                  </div>
                </>
              )}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}
