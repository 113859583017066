import { Button } from '@noissue-ui-kit/button'
import { TSize } from '@utils/types'
import { useRouter } from 'next/router'
import { signInWithKeycloack } from '@utils/authHelpers'

export const LoginSignup = ({
  className,
  size = 'xs',
}: {
  className?: string
  size?: TSize
}) => {
  const router = useRouter()
  const redirectRouteParameter = router.asPath

  return (
    <Button
      size={size}
      corners="rounded"
      variant="outline"
      colour="acai"
      onClick={async () => {
        await signInWithKeycloack({
          redirectRouteParameter,
        })
      }}
      className={className}
    >
      Login / Signup
    </Button>
  )
}
