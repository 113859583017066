/* eslint-disable @typescript-eslint/no-use-before-define */
import * as Sentry from '@sentry/nextjs'
import { getCategoryByName } from '@framework/lib/getCategoryByName'
import { getCategoryProducts } from '@framework/lib/getCategoryProducts'
import { saveUploadConfigurationJsonData } from '@pageFeatures/design/designUpload/hooks/utils'
import { LineItem } from '@framework/types'
import { NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_COUNTRY } from '@utils/client-side-config'
import { escapeEmoji } from '@utils/bigcommerce/escapeEmojis'
import { METADATA_TAG } from '@utils/constants'

export const ensureNoZeroPriceItemsInCart = async (
  cartItems: LineItem[],
  removeItem: any,
  addItem: any
) => {
  if (!cartItems) {
    throw new Error('Cart is empty')
  }

  for (const cartItem of cartItems) {
    if (cartItem.variant.price === 0 || cartItem.variant.listPrice === 0) {
      const { alternativeProduct } = await generateAlternativeProductAndVariant(
        cartItem
      )

      if (!alternativeProduct) {
        const errMsg =
          'Custom product added to cart with zero price and did not find alternative one'
        Sentry.withScope((scope) => {
          scope.setContext('zeroPriceCartItem', cartItem)
          scope.setTransactionName('CustomProductAddToCartError')
          Sentry.captureException(new Error(errMsg))
        })
        throw new Error(errMsg)
      }

      const CustomizationsModifier = 'Customizations'

      const originalCartItemCustomizationsOption = cartItem.options.find(
        (option) => option.name === CustomizationsModifier
      )

      let originalCartItemCustomizations =
        originalCartItemCustomizationsOption.value

      if (typeof originalCartItemCustomizationsOption.value === 'string') {
        originalCartItemCustomizations = JSON.parse(
          originalCartItemCustomizationsOption.value
        )
      }

      const originalCartItemConfigId = originalCartItemCustomizations.configId

      const originalCartItemConfiguration = await fetch(
        `/api/designer/get-config?configId=${originalCartItemConfigId}`
      ).then((res) => res.json())

      originalCartItemConfiguration.variantId = alternativeProduct.variantId

      const newConfigId = await saveUploadConfigurationJsonData(
        originalCartItemConfiguration
      )
      originalCartItemCustomizations.configId = newConfigId

      const re = new RegExp(originalCartItemConfigId, 'gi')
      originalCartItemCustomizations.previewLink =
        originalCartItemCustomizations.previewLink.replace(re, newConfigId)

      await removeItem(cartItem)

      const modifiersOptions = [
        {
          option_id: alternativeProduct.modifiersId, // Set customizations field value
          option_value: escapeEmoji(
            `${JSON.stringify(originalCartItemCustomizations)}`
          ),
        },
      ]
      const reorderTag = cartItem.options.find(
        (option) => option.name === 'Reorder'
      )
      if (alternativeProduct.reorderModifierId && reorderTag) {
        if (reorderTag.value) {
          modifiersOptions.push({
            option_id: alternativeProduct.reorderModifierId,
            option_value: reorderTag.value,
          })
        }
      }

      // Retain metadata
      const originalCartItemMetadataOption = cartItem.options.find(
        (option) => option.name?.toLowerCase() === METADATA_TAG
      )

      modifiersOptions.push({
        option_id: alternativeProduct.metadataModifierId,
        option_value: originalCartItemMetadataOption.value,
      })

      await addItemToCart(
        alternativeProduct.productId,
        alternativeProduct.variantId,
        modifiersOptions,
        addItem
      )
    }
  }

  return true
}

async function generateAlternativeProductAndVariant(zeroPriceCartItem) {
  const categoryNameParts = zeroPriceCartItem.categorySlug.split('-')
  const categoryName = categoryNameParts
    .map((part) => part[0].toUpperCase() + part.substring(1))
    .join(' ')

  // Get current product categories by zero price product's category name
  const currentCategoryProducts = await getCurrentCategoryProducts(categoryName)

  // Generated zero price product variants (exclude modifiers: Customizations for now)
  const zeroPriceProductOptionsMap = generateProductOptionsMap(
    zeroPriceCartItem.options
  )

  let alternativeProduct = null
  for (const currentProduct of currentCategoryProducts) {
    if (alternativeProduct) {
      continue
    }

    const variantOptionsMap = generateVariantOptionsMap(
      currentProduct.optionsValues,
      zeroPriceProductOptionsMap
    )
    if (zeroPriceProductOptionsMap.size === variantOptionsMap.size) {
      alternativeProduct = currentProduct
    }
  }

  return {
    alternativeProduct,
  }
}

function generateProductOptionsMap(options) {
  const productOptionsMap = new Map()
  const excludeOptions = ['Customizations', 'Reorder']
  options.forEach((option) => {
    if (option.name && !excludeOptions.includes(option.name)) {
      productOptionsMap.set(option.name, option.value)
    }
  })
  return productOptionsMap
}

/**
 *
 * Add item to cart, then return new cart
 * @param productId
 * @param variantId
 * @param modifiersOptions
 *
 * [
 *    {
 *      option_id: number
 *      option_value: any value,
 *    },
 *  ]
 * @param addItem is a hook ( useAddItem() ) to add item into cart
 */
export async function addItemToCart(
  productId,
  variantId,
  modifiersOptions,
  addItem
) {
  const itemPayload = {
    productId: productId,
    variantId: variantId,
    optionSelections: modifiersOptions,
  }
  const cart = await addItem(itemPayload).then((newCart) => newCart)
  return cart
}

export function generateVariantOptionsMap(optionsValues, productOptionsMap) {
  const variantOptionsMap = new Map()

  optionsValues.forEach((option) => {
    if (
      productOptionsMap.has(option.option_display_name) &&
      productOptionsMap.get(option.option_display_name) === option.label
    ) {
      variantOptionsMap.set(option.option_display_name, {
        product_option_id: option.option_id,
        value: option.id + '',
      })
    }
  })
  return variantOptionsMap
}

export async function getCurrentCategoryProducts(categoryName: string) {
  const { data: productCategoriesData } = await getCategoryByName(categoryName)

  const countryCode = NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_COUNTRY
  // Filter the right one category by order's country(iso2 type, such as nz, us)
  const currentProductCategory = productCategoriesData.find((category) =>
    category.custom_url.url.startsWith(`/${countryCode.toLowerCase()}`)
  )

  const currentCategoryProducts = await getCategoryProducts(
    currentProductCategory.id
  )
  return currentCategoryProducts
}
