import { CgArrowLeft } from 'react-icons/cg'
import cn from 'classnames'
import isEmpty from 'lodash/isEmpty'
import { MobileNavHeader } from './MobileNavHeader'
import { MobileListDropdown } from './MobileListDropdown'
import { MobileContentDropdown } from './MobileContentDropdown'
import { useSelectedSecondaryMenu } from '../NavbarDataProvider'

export const MobileSelectionMenu = ({
  handleMenuToggle,
}: {
  handleMenuToggle: () => void
}) => {
  const { selectedSecondaryMenu, setSelectedSecondaryMenuIndex } =
    useSelectedSecondaryMenu()

  return (
    <div
      className={`${cn(
        'absolute top-0 left-0 bg-core-white w-full h-full flex flex-col items-start translate-x-full transition-all duration-300 no-scrollbar z-10 px-[24px] font-mori',
        isEmpty(selectedSecondaryMenu) ? '' : '!translate-x-0'
      )}`}
    >
      <MobileNavHeader handleMenuToggle={handleMenuToggle}>
        <div
          onClick={() => setSelectedSecondaryMenuIndex(-1)}
          className="flex gap-[8px] items-center h-[65px] font-normal text-[16px] leading-[22px] text-boulder cursor-pointer"
        >
          <CgArrowLeft className="inline-block" />
          <span>{selectedSecondaryMenu?.title}</span>
        </div>
      </MobileNavHeader>

      <div className="flex flex-col items-start pt-[32px] pb-[120px] gap-[32px] w-full">
        {selectedSecondaryMenu?.type === 'content-dropdown' && (
          <MobileContentDropdown
            secondaryMenuItem={selectedSecondaryMenu}
            handleMenuToggle={handleMenuToggle}
          />
        )}

        {selectedSecondaryMenu?.type === 'list-dropdown' && (
          <MobileListDropdown
            secondaryMenuItem={selectedSecondaryMenu}
            handleMenuToggle={handleMenuToggle}
          />
        )}
      </div>
    </div>
  )
}
