import { IShippingLocation } from './types'

const shippingLocations: IShippingLocation[] = [
  {
    key: 'US',
    label: 'United States',
    icon: '/flags/US.svg',
    currencyCode: 'USD',
  },
  {
    key: 'AU',
    label: 'Australia',
    icon: '/flags/AU.svg',
    currencyCode: 'AUD',
  },
  {
    key: 'GB',
    label: 'United Kingdom',
    icon: '/flags/GB.svg',
    currencyCode: 'GBP',
  },
  {
    key: 'EU',
    selectorOverride: 'DE',
    label: 'Europe',
    icon: '/flags/EU.svg',
    currencyCode: 'EUR',
  },
  {
    key: 'CA',
    label: 'Canada',
    icon: '/flags/CA.svg',
    currencyCode: 'CAD',
  },
  {
    key: 'NZ',
    label: 'New Zealand',
    icon: '/flags/NZ.svg',
    currencyCode: 'NZD',
  },
  {
    key: 'ROW',
    label: 'Rest of world',
    selectorOverride: 'IN',
    icon: '/flags/ROW.svg',
    currencyCode: 'USD',
  },
]

export { shippingLocations }
