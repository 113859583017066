import React from 'react'
import { SocialLinks } from './SocialLinks'
import { PrivaryPolicy } from './PrivacyPolicy'
import { TermsAndConditions } from './TermsAndConditions'
import { Copyright } from './Copyright'
import { Logo } from '@components/ui'

export function FooterLinksMobile({ className }: { className?: string }) {
  return (
    <div
      className={`flex flex-col mx-8 mb-20 text-xl text-black-40 ${className}`}
    >
      <Logo isNewLogo={false} />
      <div className="flex flex-row flex-wrap text-xl">
        <SocialLinks iconSize="S" />
      </div>
      <div className="flex flex-row">
        <Copyright className="border-r border-black-10 text-black-80" />
        <TermsAndConditions className="border-r border-black-10 text-black-80 hover:text-acai-100" />
        <PrivaryPolicy className="text-black-80 hover:text-acai-100" />
      </div>
    </div>
  )
}
