import { NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_COUNTRY } from '@utils/client-side-config'
import { shippingLocations } from './config'
import { IShippingLocation, TRegionCode } from './types'
import { COOKIE_BC_CART, EUROPEAN_COUNTRIES } from '@utils/constants'
import Cookie from 'js-cookie'

export function appendSiteRegion(path: string, countryCode: string) {
  // temp URL object to safely handle query param concatenation
  const newUrl = new URL(path, 'https://no.op')
  newUrl.searchParams.set('siteRegion', countryCode)

  return `${newUrl.pathname}${newUrl.pathname.endsWith('/') ? '' : '/'}${
    newUrl.search
  }`
}

export function codeToShippingLocation(countryCode): IShippingLocation {
  return (
    shippingLocations.find(
      (location) =>
        location.selectorOverride === countryCode ||
        location.key ===
          (countryCode || NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_COUNTRY)
    ) || null
  )
}

export function changeRegion(currentPath: string, location: IShippingLocation) {
  Cookie.remove(COOKIE_BC_CART)
  window.location.href = appendSiteRegion(
    currentPath,
    location?.selectorOverride || location?.key
  )
}

export function countryCodeToShippingLocation(userCountry) {
  const shippingLocationCodes: Record<string, TRegionCode> = {
    AU: 'AU',
    NZ: 'NZ',
    GB: 'GB',
    CA: 'CA',
    US: 'US',
    EU: 'EU',
    default: 'ROW',
  }

  if (shippingLocationCodes[userCountry]) {
    return shippingLocationCodes[userCountry]
  }

  if (EUROPEAN_COUNTRIES.includes(userCountry)) {
    return shippingLocationCodes.EU
  }

  return shippingLocationCodes.default
}
