export function ArtworkStatusDot({
  artworkUploaded,
}: {
  artworkUploaded: boolean
}) {
  return (
    <span
      className={`h-4 w-4 mr-2 rounded-full ${
        artworkUploaded ? 'bg-starfruit' : 'bg-pitaya'
      }`}
    />
  )
}
