import { ContentfulNextJsImage } from '@components/common/ContentfulImage'
import { TProductTag, TTagType } from '@pageFeatures/category/category.types'

export function ProductTag({
  label,
  labelImage,
  type = 'generic',
  className,
}: TProductTag & { className?: string }) {
  const mapTagTypeToClass: Record<TTagType, string> = {
    generic: 'text-black bg-pebble',
    sale: 'text-pitaya bg-pitaya-5',
    status: 'text-acai bg-white',
  }

  return (
    <div
      className={`font-mori py-[6px] px-[12px] text-base leading-snug font-semibold rounded-[24px] whitespace-nowrap ${mapTagTypeToClass[type]} ${className}`}
      key={label}
    >
      {labelImage?.imageUrl ? (
        <ContentfulNextJsImage
          src={labelImage?.imageUrl}
          alt={labelImage?.imageTitle}
          format="avif"
          width={50}
          height={12}
          objectFit="contain"
          className="pb-1"
        />
      ) : (
        <span>{label}</span>
      )}
    </div>
  )
}
