import { isProductCustomisable } from '@components/product/ProductPage/ProductPage.utils'
import { ProductVariant } from '@pageFeatures/design/designUpload/types'
import { IQuantitySelectorOption } from '@components/common/QuantitySelector'
import uniqWith from 'lodash/unionWith'
import isEqual from 'lodash/isEqual'

export const generatePathToPdp = (slug) => {
  return isProductCustomisable(slug)
    ? `/custom-packaging/${slug}`
    : `marketplace/${slug}`
}

export const findLineItemQuantityAlternatives = ({
  productVariants,
  lineItemOptions,
}: {
  productVariants: ProductVariant[]
  lineItemOptions: any[]
}) => {
  const productOptionAlternatives = productVariants?.filter((variant) => {
    const productVariantOptions = variant?.optionsValues?.filter(
      (option) =>
        option?.option_display_name?.toLocaleLowerCase() !== 'quantity'
    )

    return productVariantOptions.every((option) => {
      const cartItemOption = lineItemOptions?.find(
        (itemOption) => itemOption?.name === option.option_display_name
      )
      return option.label === cartItemOption?.value
    })
  })

  return productOptionAlternatives
}

export const generateQunatityOptionsFromProductVariants = ({
  productVariants,
}: {
  productVariants: ProductVariant[]
}): IQuantitySelectorOption[] => {
  if (!productVariants?.length) {
    return []
  }

  return uniqWith(
    productVariants?.map((variant) => {
      return {
        displayLabel: variant.quantity,
        key: variant.quantity,
        disabled: false,
        price: {
          originalPrice: variant.price,
          salePrice: variant.salesPrice,
          currencyCode: variant.currencyCode,
        },
      }
    }),
    isEqual
  ).sort((optionA, optionB) => +optionA.key - +optionB.key)
}
