import { trackNavigationClick } from '@utils/gtm'
import Image from 'next/legacy/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC } from 'react'

interface Props {
  className?: string
  isLight?: boolean
  isNewLogo?: boolean
}

const Logo: FC<Props> = ({ className, isLight, isNewLogo = true }) => {
  const router = useRouter()
  let width = 110
  let height = 28

  let logo = '/images/logo.svg'

  if (isNewLogo) {
    logo = '/images/noissue-wordmark.png'
    width = 140
    height = 38
  }

  if (isLight) {
    logo = '/images/logo-light.svg'
  }

  return (
    <Link
      href="/"
      onClick={(e) => {
        e.preventDefault()
        trackNavigationClick({
          clickLocation: 'Logo',
          clickItem: 'noissue-logo',
        })
        router.push('/').catch(() => {})
      }}
    >
      <Image
        src={logo}
        width={width}
        height={height}
        alt="noissue company logo"
        priority={true}
        className={'object-contain w-[140px] h-[38px]' + className}
      />
    </Link>
  )
}

export default Logo
