import { FC } from 'react'
import s from './LoadingDots.module.css'

type Props = {
  color?: string
}

const LoadingDots: FC<Props> = ({ color }) => (
  <span className={s.root}>
    <span className={color || 'bg-white'} />
    <span className={color || 'bg-white'} />
    <span className={color || 'bg-white'} />
  </span>
)

export default LoadingDots
