import Cookie from 'js-cookie'
import { useRouter } from 'next/router'
import { HiX } from 'react-icons/hi'
import { USER_LOCATION_POPUP_INTERACTION } from '@utils/constants'
import { useEffect, useState } from 'react'
import { changeRegion } from '../ShippingDestination/utils'
import { IShippingLocation } from '../ShippingDestination/types'
import { LoaderIcon } from '@noissue-ui-kit/oldBranding/loaderIcon'
import { useLocationMismatchCheck } from './useLocationMismatchCheck'
import { useScrollPosition } from '@utils/responsiveness/useScrollPosition'

export function LocationMismatchPopup() {
  const [showPopUp, setShowPopUp] = useState(false)
  const [loading, setLoading] = useState(false)
  const router = useRouter()
  const [hasScrolled, setHasScrolled] = useState(false)

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const scrolled = currPos.y < prevPos.y
      if (scrolled !== hasScrolled) {
        setHasScrolled(scrolled)
      }
    },
    [hasScrolled]
  )

  const { locationDoesMismatch, correctLocation } = useLocationMismatchCheck()

  const popupWasShown = Boolean(Cookie.get(USER_LOCATION_POPUP_INTERACTION))

  const closePopup = () => {
    setShowPopUp(false)
    Cookie.set(USER_LOCATION_POPUP_INTERACTION, 'true')
  }

  const getPopupTitle = (location: IShippingLocation) => {
    let title = ''
    switch (location.key) {
      case 'NZ':
      case 'AU':
      case 'US':
      case 'CA':
      case 'EU':
        title = `Shipping to ${location.label}?`
        break

      case 'GB':
        title = `Shipping to the ${location.label}?`
        break

      case 'ROW':
      default:
        title = `Looks like you're shopping from outside the United States?`
    }

    return title
  }

  useEffect(() => {
    if (locationDoesMismatch && !popupWasShown) {
      setShowPopUp(true)
    } else {
      if (showPopUp) {
        closePopup()
      }
    }
  }, [locationDoesMismatch, popupWasShown])

  const redirectToCorrectLocation = () => {
    setLoading(true)
    Cookie.set(USER_LOCATION_POPUP_INTERACTION, 'true')
    changeRegion(router?.asPath, correctLocation)
  }

  return (
    (showPopUp || loading) && (
      <>
        <div
          className={`pb-6 px-6 pt-8 bg-white shadow-lg rounded-b-4xl w-full md:w-120 absolute right-0 md:right-[235px] font-mori z-10 duration-300 transition-all ${
            hasScrolled ? 'top-unset xl:top-[72px]' : 'top-unset xl:top-[121px]'
          }`}
        >
          <h3 className="text-black text-[16px] font-semibold leading-tight mb-2 pr-16">
            {getPopupTitle(correctLocation)}
          </h3>
          <p className="mb-5 text-lg text-black-80">
            This site doesn't ship to your country
          </p>

          <HiX
            className="absolute text-4xl text-black cursor-pointer top-8 right-6"
            onClick={closePopup}
          />

          <button
            onClick={() => redirectToCorrectLocation()}
            className="w-full"
          >
            <span className="flex items-center justify-center w-full h-20 text-xl font-bold text-white rounded-full cursor-pointer bg-pitaya font-mori hover:bg-pitaya-120">
              {!loading && (
                <span>
                  {correctLocation.key === 'ROW'
                    ? 'Switch store'
                    : `Go to noissue ${correctLocation?.key}`}
                </span>
              )}

              {loading && <LoaderIcon size="s" fillColorClass="fill-white" />}
            </span>
          </button>
        </div>
        <div
          className={`bg-black-80 opacity-50 fixed xl:absolute left-0 right-0 bottom-0 h-[100vh] duration-300 transition-all ${
            hasScrolled
              ? 'top-[190px] xl:top-[72px]'
              : 'top-[190px] lg:top-[170px] xl:top-[120px]'
          }`}
        />
      </>
    )
  )
}
