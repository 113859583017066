import { loadZendeskScript } from '@utils/utilities'
import { useSession } from 'next-auth/client'
import { UserNavItem } from './UserNavItem'
import { clearCustomerAndSignOut } from '@utils/auth/clearCustomerAndSignOut'
import { StoreCreditCard } from '@components/ui/AdminSidebar/StoreCreditCard'
import { useCustomer } from '@framework/customer'
import { NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_COUNTRY } from '@utils/client-side-config'

async function logout(event) {
  event.preventDefault()
  await clearCustomerAndSignOut({ redirect: true })
}

export const AccountPlain = ({
  announcementBannerLinks,
}: {
  announcementBannerLinks: { text: string; link: string }[]
}) => {
  const [session] = useSession()
  const { data: customer } = useCustomer()

  return (
    <div className="flex flex-col text-xl text-core-grey-90">
      {session && (
        <>
          <UserNavItem itemLabel="My Orders" itemUrl="/admin/orders/" />
          <UserNavItem
            itemLabel="My Projects"
            itemUrl="/admin/saved-designs/"
          />
          <UserNavItem itemLabel="Account Details" itemUrl="/admin/account/" />
        </>
      )}

      {session?.hubspotOwnerId ? (
        <a
          href="/admin/orders"
          className="hover:opacity-80 py-3 pt-1 leading-10"
        >
          Contact Account Manager
        </a>
      ) : (
        <button
          onMouseOver={() => loadZendeskScript()}
          onClick={() => {
            // @ts-ignore
            window.zE && window.zE('webWidget', 'toggle')
          }}
          className="text-left py-3 pt-1 leading-10"
        >
          Contact Us
        </button>
      )}

      {announcementBannerLinks?.length > 0 &&
        announcementBannerLinks?.map((link) => {
          return (
            <UserNavItem
              key={link?.link}
              itemLabel={link?.text}
              itemUrl={link?.link}
            />
          )
        })}

      {session && (
        <a
          href="/logout"
          className="py-3 hover:text-core-rose"
          onClick={logout}
        >
          Logout
        </a>
      )}

      {customer?.storeCredit &&
        NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_COUNTRY === 'US' && (
          <StoreCreditCard
            storeCredit={customer?.storeCredit}
            className={'mt-5 mr-10'}
          />
        )}
    </div>
  )
}
