import Cookie from 'js-cookie'
import React, { useEffect, useRef, useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import Image from 'next/legacy/image'
import { useRouter } from 'next/router'
import { HiOutlineChevronUp } from 'react-icons/hi'
import { shippingLocations } from './config'
import { changeRegion, codeToShippingLocation } from './utils'
import { IShippingLocation } from './types'
import { COOKIE_CONTAINER, KEY_CODES } from '@utils/constants'
import { useScrollPosition } from '@utils/responsiveness/useScrollPosition'

export function ShippingDestinationToggle({
  open,
  selectedLocation,
  onClick = () => null,
}: {
  open: boolean
  selectedLocation: IShippingLocation
  onClick?: (event) => void
}) {
  return (
    <div
      className={`flex items-center text-xl leading-none font-mori cursor-pointer group ${
        open ? 'text-pitaya' : 'text-boulder'
      }`}
      onClick={onClick}
    >
      <span className="pt-1 mr-4 text-xl leading-none text-boulder font-mori">
        Ship to
      </span>

      <Image
        src={selectedLocation.icon}
        width={30}
        height={20}
        alt={selectedLocation.label}
      />

      <HiOutlineChevronUp
        className={`text-2xl ml-3 origin-center duration-300 group-hover:text-pitaya ${
          open ? 'rotate-x-180' : ''
        }`}
      />
    </div>
  )
}

export function ShippingDestinationItem({
  itemLabel,
  itemIconUrl,
  selected = false,
  focused = false,
  onSelection = () => null,
}: {
  itemLabel: string
  itemIconUrl?: string
  selected: boolean
  focused?: boolean
  onSelection?: () => void
}) {
  const locationItemRef = useRef(null)

  useEffect(() => {
    locationItemRef?.current?.focus()
  }, [focused])

  return (
    <button
      className={`flex items-center cursor-pointer outline-none hover:text-pitaya hover:bg-forest-5 text-2xl px-6 py-3 ${
        selected || focused ? 'text-pitaya bg-forest-5' : 'text-black'
      }`}
      onClick={onSelection}
      ref={locationItemRef}
      tabIndex={0}
    >
      <Image src={itemIconUrl} width={30} height={20} alt={itemLabel} />
      <span className="ml-4">{itemLabel}</span>
    </button>
  )
}

export function ShippingDestinationPopup() {
  const [loading, setLoading] = useState(false)
  const router = useRouter()
  const siteRegion = Cookie.get(COOKIE_CONTAINER) || router?.query?.siteRegion
  const selectedLocation = codeToShippingLocation(siteRegion)
  const [locationSelected, setLocationSelected] = useState(false)
  const [hasScrolled, setHasScrolled] = useState(false)

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const scrolled = currPos.y < prevPos.y
      if (scrolled !== hasScrolled) {
        setHasScrolled(scrolled)
      }
    },
    [hasScrolled]
  )

  const [focusedLocationIndex, setFocusedLocationIndex] = useState<number>(0)

  useEffect(() => {
    setLocationSelected(Boolean(siteRegion))
    setLoading(false)
  }, [siteRegion])

  useEffect(() => {
    setFocusedLocationIndex(
      shippingLocations?.findIndex((x) => selectedLocation?.key === x?.key)
    )
  }, [selectedLocation])

  const onKeyDown = async (event) => {
    event.preventDefault()
    event.stopPropagation()

    const { keyCode } = event
    const totalItems = shippingLocations?.length

    if (keyCode === KEY_CODES.ARROW_UP) {
      setFocusedLocationIndex((prevIndex) => Math.max(prevIndex - 1, 0))
    } else if (keyCode === KEY_CODES.ARROW_DOWN) {
      setFocusedLocationIndex((prevIndex) =>
        Math.min(prevIndex + 1, totalItems - 1)
      )
    } else if (keyCode === KEY_CODES.ENTER) {
      setLoading(true)
      changeRegion(router?.asPath, shippingLocations[focusedLocationIndex])
    }
  }

  return (
    <Popover className="relative flex items-center flex-shrink-0 mr-7">
      {({ open, close }) => {
        // Work around to close the Popocer smoothly when query parametr update is finished.
        // Headless UI doesn't give us a way to handle it externally.
        useEffect(() => {
          if (open) {
            setLocationSelected(false)
          }
        }, [open])

        useEffect(() => {
          if (locationSelected) {
            close()
          }
        }, [locationSelected])

        return (
          <>
            <Popover.Button className="ml-3 mr-4 transition-all duration-300 outline-none">
              <ShippingDestinationToggle
                open={open}
                selectedLocation={selectedLocation}
              />
            </Popover.Button>

            <Transition
              show={open && !locationSelected}
              enter="transition duration-200 ease-out"
              enterFrom="transform -translate-y-5 opacity-0"
              enterTo="transform translate-y-0 opacity-100"
              leave="transition duration-200 ease-out"
              leaveFrom="transform translate-y-0 opacity-100"
              leaveTo="transform -translate-y-5 opacity-0"
            >
              <Popover.Panel
                className={`hidden lg:block absolute font-mori z-10 pb-6 mt-6 w-96 bg-white rounded-b-3xl overflow-hidden drop-shadow-lg right-4 ${
                  hasScrolled ? 'top-[21px]' : 'top-[22px]'
                }`}
              >
                {loading && (
                  <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center bg-white opacity-90 z-5">
                    <Image
                      className="w-24 h-24 opacity-100"
                      src={'/icon-uploading-new-brand.gif'}
                      alt="loading icon"
                      width={64}
                      height={64}
                    />
                  </div>
                )}

                <p className="mt-8 mb-6 font-semibold text-acai text-[16px] px-6">
                  Shipping destination:
                </p>

                <ul
                  className="flex flex-col text-xl text-boulder"
                  onKeyDown={onKeyDown}
                >
                  {shippingLocations.map((location, index) => (
                    <ShippingDestinationItem
                      key={location?.label}
                      itemLabel={location.label}
                      itemIconUrl={location.icon}
                      selected={location.key === selectedLocation?.key}
                      focused={focusedLocationIndex === index}
                      onSelection={() => {
                        setLoading(true)
                        changeRegion(router?.asPath, location)
                      }}
                    />
                  ))}
                </ul>
              </Popover.Panel>
            </Transition>
          </>
        )
      }}
    </Popover>
  )
}
