import useOneTapSignin from './useOneTapSignin'
import { useRouter } from 'next/router'
import { Fragment, useEffect, useState } from 'react'
import { Notification } from '@noissue-ui-kit/notification'

const OneTap = () => {
  const router = useRouter()
  const redirectRouteParameter = router.asPath

  const [showNotification, setShowNotification] = useState<boolean>(false)

  const { isLoading: oneTapIsLoading } = useOneTapSignin({
    redirect: false,
    parentContainerId: 'oneTap',
    callbackUrl: redirectRouteParameter,
  })

  useEffect(() => {
    if (!oneTapIsLoading) {
      setShowNotification(true)
    }
  }, [oneTapIsLoading])

  return (
    <Fragment>
      <div id="oneTap" />
      <Notification
        opened={showNotification}
        close={() => setShowNotification(false)}
        message="Login successful!"
        variant="infoInverted"
        showForMs={5000}
      />
    </Fragment>
  )
}

export default OneTap
