import React, { useEffect, useState } from 'react'
import { Check, Cross } from '@components/icons'
import { Button } from '@noissue-ui-kit/button'
import { useUI } from '@components/ui/context'
import useCart from '@framework/cart/use-cart'
import usePrice from '@framework/product/use-price'
import type { LineItem } from '@framework/types'
import cn from 'classnames'
import CartItem from '../CartItem'
import { trackCheckout, trackCheckoutStep } from '@utils/gtm'
import isEmptyObject from 'lodash/isEmpty'
import { useRouter } from 'next/router'
import useCustomer from '@framework/customer/use-customer'
import { CartIcon } from '../CartIcon'
import { useEnsureNoZeroPriceItemsInCart } from '@utils/hooks/useEnsureNoZeroPriceItemsInCart/useEnsureNoZeroPriceItemsInCart'
import { CartCrossSell } from '../CartCrossSell/CartCrossSell'
import { IconButton } from '@noissue-ui-kit/oldBranding/iconButton'
import { HiArrowRight } from 'react-icons/hi'
import { Entry } from 'contentful'
import { ICartSidebar } from 'types/contentful/cartSidebar'
import { TRecommendedProduct } from 'types/crossSell'
import { getCrossSellItemsWithDiscounts } from '@utils/discounts/crossSellDiscounts'
import { getRedirectAfterLoginUrl } from '@utils/utilities'
import { signInWithKeycloack } from '@utils/authHelpers'
import { CartItemSkeleton } from '../CartItem/CartItemSkeleton'
import { useCommerce } from '@commerce'
import { CartPaymentMethods } from './CartPaymentMethods'
import { ShareCartButton } from './ShareCartButton'
import Cookie from 'js-cookie'
import {
  addCartMetafield,
  findCartMetafieldByKey,
  getCartMetafields,
  updateCartMetafield,
} from './cartMetafields.utils'
import { ICartMetafield, ICartMetafieldPayload } from './cartMetafields.types'
import {
  CART_METAFIELD_PERMISSIONS,
  CART_METAFIELD_NAMESPACES,
  CLIENT_ID_KEY,
} from './cartMetafield.config'
import * as Sentry from '@sentry/nextjs'

const countItem = (count: number, item: LineItem) => count + item.quantity

const getGAClientId = () => {
  const client_id = Cookie.get('_ga')
  return client_id || null
}

export function CartSidebarView({
  cartSidebarData,
}: {
  cartSidebarData?: Entry<ICartSidebar>
}) {
  const router = useRouter()
  const { data: customer } = useCustomer()
  const { data: cart } = useCart()
  const [crossSellItems, setCropssSellItems] = useState<TRecommendedProduct[]>(
    []
  )

  const { isEmpty, isLoading } = useEnsureNoZeroPriceItemsInCart()
  const [itemIsLoading, setItemIsLoading] = useState(false)

  useEffect(() => {
    const items = cart?.lineItems
    if (isEmptyObject(items)) {
      // No item in cart, save time
      return
    }
    const getCrossSellData = async () => {
      const crossSellItems = await getCrossSellItemsWithDiscounts(items)
      setCropssSellItems(crossSellItems)
    }

    getCrossSellData().catch(() => {})
  }, [cart])

  const { closeSidebar, toggleSidebar } = useUI()
  const [checkoutLoading, setCheckoutLoading] = useState(false)
  const itemsCount = isEmpty ? 0 : cart?.lineItems.reduce(countItem, 0) || 0

  cart?.lineItems?.forEach((lineItem) => {
    const customizations = lineItem.options.find(
      (option) => option.name === 'Customizations'
    )
    if (customizations?.value && typeof customizations.value !== 'object') {
      customizations.value = JSON.parse(customizations.value)
    }
  })
  const { locale } = useCommerce()

  const { price: total } = usePrice(
    cart && {
      amount: Number(cart.totalPrice),
      currencyCode: cart.currency.code,
      locale,
    }
  )
  const handleClose = () => closeSidebar()
  const error = null
  const success = null

  const addCustomerTrackingMetafieldToCart = async ({
    cartId,
  }: {
    cartId: string
  }): Promise<ICartMetafield | null> => {
    try {
      if (!cartId) {
        throw new Error('Missing cartId argument.')
      }

      const gaClientId = getGAClientId()

      if (!gaClientId) {
        throw new Error(`There is no GA client_id in cookies.`)
      }

      const { metafields } = await getCartMetafields({ cartId: cartId })

      const clientIdMetafield = findCartMetafieldByKey({
        metafields,
        key: CLIENT_ID_KEY,
      })

      if (clientIdMetafield && clientIdMetafield?.value === gaClientId) {
        // We already have correct gaClientId set, no need to update metafield
        return
      }

      const metafieldPayload: ICartMetafieldPayload = {
        permission_set: CART_METAFIELD_PERMISSIONS.STOREFRONT_READ,
        namespace: CART_METAFIELD_NAMESPACES.ANALYTICS,
        key: CLIENT_ID_KEY,
        value: gaClientId,
        description: 'GA tracking ID for cross domain tracking.',
      }

      if (clientIdMetafield) {
        // ClientId metafield is present but outdated
        return await updateCartMetafield({
          metafieldPayload,
          cartId,
          metafieldId: clientIdMetafield.id,
        })
      }

      // ClientId metafield is absent, create it from scratch
      return await addCartMetafield({
        metafieldPayload,
        cartId,
      })
    } catch (err) {
      Sentry.captureException(
        new Error(
          `Couldn't set GA Client ID metafield on cart. Error: ${
            typeof err === 'string'
              ? err
              : err?.message || 'something went wrong'
          }`
        )
      )
      return null
    }
  }

  const redirectToCheckout = async () => {
    if (customer) {
      await router.push(getRedirectAfterLoginUrl('/checkout'))
    } else {
      await signInWithKeycloack({
        redirectRouteParameter: '/checkout',
      })
    }
  }

  const latestCartItem = cart?.lineItems[0] || null
  const itemsList =
    cartSidebarData.fields.upsellDiscountTextShowUsingList?.categorySlugs || []

  const showUpsellDiscountText =
    latestCartItem !== null &&
    latestCartItem?.categorySlug !== '' &&
    itemsList.indexOf(latestCartItem?.categorySlug) !== -1

  return (
    <div
      className={cn('w-full md:w-[500px] max-w-[500px] relative h-full z-0', {
        'p-0 text-2xl text-pink-400 font-regular':
          error || success || isLoading || isEmpty,
      })}
    >
      <header className="flex items-center bg-pebble h-[75px] top-0 z-1">
        <div className="flex items-center justify-between w-full">
          <div className="items-center w-auto ml-8 sm:ml-16">
            <nav>
              <div>
                <ul className="flex flex-row items-center h-full justify-items-end">
                  <li
                    className="relative flex items-center mr-0 transition duration-100 ease-in-out outline-none cursor-pointer active:outline-none focus:outline-none"
                    onClick={toggleSidebar}
                  >
                    <CartIcon cartCount={itemsCount} />
                    <span className="ml-6 text-3xl font-bold leading-tight text-acai font-mori">
                      Shopping cart
                    </span>
                  </li>
                </ul>
              </div>
            </nav>
          </div>

          <div className="flex items-center mr-8 sm:mr-16 h-14 w-14">
            <IconButton
              size="m"
              className="flex items-center justify-center w-full h-full text-acai"
              onClick={handleClose}
              aria-label="Close panel"
            >
              <HiArrowRight className="w-6 h-6 !m-0" />
            </IconButton>
          </div>
        </div>
      </header>

      {isLoading || itemIsLoading ? (
        <div className="justify-center flex-1 w-full h-full animate-pulse">
          {Array.from(Array(4)).map((item) => {
            return <CartItemSkeleton key={item} />
          })}
        </div>
      ) : isEmpty ? (
        <div className="flex flex-col flex-1 w-full p-16">
          <p className="mb-8 text-3xl text-center text-black-60 font-regular">
            {cartSidebarData.fields.cartEmptyText}
          </p>

          <Button
            corners="rounded"
            size="m"
            variant="outline"
            colour="acai"
            className="w-full border-pebble"
            loading={checkoutLoading}
            onClick={() => router.push('/category/all')}
          >
            Shop all
          </Button>
        </div>
      ) : error ? (
        <div className="flex flex-col items-center justify-center flex-1 px-4">
          <span className="flex items-center justify-center w-16 h-16 border rounded-full border-core-white">
            <Cross width={24} height={24} />
          </span>
          <h2 className="pt-6 text-xl font-light text-center">
            We couldn’t process the purchase. Please check your card information
            and try again.
          </h2>
        </div>
      ) : success ? (
        <div className="flex flex-col items-center justify-center flex-1 px-4">
          <span className="flex items-center justify-center w-16 h-16 border rounded-full border-core-white">
            <Check />
          </span>
          <h2 className="pt-6 text-xl font-light text-center">
            Thank you for your order.
          </h2>
        </div>
      ) : (
        <>
          <div>
            <div className="overflow-y-auto md:h-[calc(100vh-385px)] overflow-x-hidden bg-white">
              <ul className="px-8 overflow-x-hidden overflow-y-auto bg-white sm:px-16">
                {cart?.lineItems.map((item: any) => {
                  return (
                    <CartItem
                      key={item.id}
                      item={item}
                      currencyCode={cart?.currency?.code}
                      cartSidebarData={cartSidebarData}
                      setItemIsLoading={setItemIsLoading}
                    />
                  )
                })}
              </ul>
              {crossSellItems?.length > 0 && (
                <CartCrossSell
                  crossSellItems={crossSellItems}
                  heading={cartSidebarData?.fields?.upsellsSectionHeading}
                  showDiscountLabel={showUpsellDiscountText}
                />
              )}
            </div>

            <div className="sticky z-1 md:absolute w-full px-8 sm:px-16 text-center -left-0 -bottom-0 pb-[20px] bg-pebble pt-12 flex flex-col">
              <div className="flex mb-6 font-mori text-3xl font-bold text-acai leading-none">
                <span>{cartSidebarData.fields.cartTotalText}</span>
                <span className="ml-auto">{total}</span>
              </div>

              <Button
                corners="rounded"
                size="m"
                variant="primary"
                colour="pitaya"
                className="w-full"
                loading={checkoutLoading}
                onClick={async () => {
                  trackCheckout(cart)
                  trackCheckoutStep(cart)
                  setCheckoutLoading(true)
                  await addCustomerTrackingMetafieldToCart({
                    cartId: cart.id,
                  })
                  redirectToCheckout().catch(() => {})
                }}
              >
                {cartSidebarData?.fields?.checkoutButtonLabel}
              </Button>

              <ShareCartButton lineItems={cart?.lineItems} />

              {cartSidebarData?.fields?.cartDisclaimerText && (
                <p className="text-black-80 text-lg leading-snug my-6 font-mori">
                  {cartSidebarData?.fields?.cartDisclaimerText}
                </p>
              )}

              <CartPaymentMethods />
            </div>
          </div>

          <div
            className="absolute top-0 left-0 w-full h-full bg-core-white"
            style={{ zIndex: -1 }}
          ></div>
        </>
      )}
    </div>
  )
}
