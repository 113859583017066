import * as Sentry from '@sentry/nextjs'
import isEmpty from 'lodash/isEmpty'
export const sentryOnZeroPriceItemAddedToCart = (
  newCart,
  transactionName,
  errorMessage
) => {
  if (isEmpty(newCart) || isEmpty(newCart.orders)) {
    return
  }

  const newAddedItem = newCart.orders.slice(-1)

  if (!isEmpty(newAddedItem) && Number(newAddedItem.price || 0) <= 0.0) {
    Sentry.withScope((scope) => {
      scope.setContext('cartItems', { cart: newCart })
      scope.setContext('itemZeroPrices', { newAddedItem })
      scope.setTransactionName(
        /* 'CustomProductAddToCartError' */ transactionName
      )
      Sentry.captureException(
        new Error(
          /* 'Custom product added to cart with zero price from card V3' */ errorMessage
        )
      )
    })
  }
}

export const sentryOnZeroPriceItemInCart = (
  cartItems,
  transactionName,
  errorMessage
) => {
  if (isEmpty(cartItems)) {
    return
  }

  // const cartItems = cart.lineItems
  const itemZeroPrices = cartItems
    .map((item) => {
      const price = item?.variant?.price || item?.variant?.listPrice
      const name = item?.variant?.name
      const sku = item?.variant?.sku

      if (!price || Number(price || 0) <= 0) {
        return {
          name,
          sku,
          price,
        }
      }

      return null
    })
    .filter(Boolean)

  if (!isEmpty(itemZeroPrices)) {
    Sentry.withScope((scope) => {
      scope.setContext('cartItems', { cartItems })
      scope.setContext('itemZeroPrices', { itemZeroPrices })
      scope.setTransactionName(
        /* 'CustomProductAddToCartError' */ transactionName
      )
      Sentry.captureException(
        new Error(/* 'Product added to cart with zero price' */ errorMessage)
      )
    })
  }
}
