import { COOKIE_CONTAINER, COOKIE_USER_COUNTRY } from '@utils/constants'
import {
  codeToShippingLocation,
  countryCodeToShippingLocation,
} from '../ShippingDestination/utils'
import Cookie from 'js-cookie'
import { NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_COUNTRY } from '@utils/client-side-config'
import { useRouter } from 'next/router'

export function useLocationMismatchCheck() {
  const router = useRouter()
  const userCountry = Cookie.get(COOKIE_USER_COUNTRY)?.toUpperCase()
  const userContainer = Cookie.get(COOKIE_CONTAINER)
  const queryCountryCode = router?.query?.siteRegion

  const userRegion = codeToShippingLocation(
    countryCodeToShippingLocation(userCountry)
  )
  const currentRegion = codeToShippingLocation(
    queryCountryCode || userContainer || NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_COUNTRY
  )

  const locationDoesMismatch = userRegion?.key !== currentRegion?.key

  return {
    locationDoesMismatch,
    correctLocation: userRegion,
  }
}
