import React, { useEffect, useState } from 'react'
import { SecondaryMenu } from '../SecondaryMenu/SecondaryMenu'
import { AccountNav } from './AccountNav'
import { CartButton } from '../CartButton'
import { useNavbarData, useSelectedPrimaryMenu } from '../NavbarDataProvider'
import { useRouter } from 'next/router'
import { trackNavigationClick } from '@utils/gtm'
import { SearchButton } from '@components/common/Navbar/SearchButton'
import { Logo } from '@components/ui'
import { ShippingDestinationPopup } from '../ShippingDestination/ShippingDestinationPopup'
import { useScrollPosition } from '@utils/responsiveness/useScrollPosition'
import { useNavbarHeightPx } from '../Navbar.utils'

export function PrimaryMenu() {
  const { primaryMenuItems } = useNavbarData()
  const { setSelectedPrimaryMenuIndex } = useSelectedPrimaryMenu()
  const [showSecondaryMenuBar, setShowSecondaryMenuBar] = useState(true)
  const [activeHover, setActiveHover] = useState(0)
  const [hasScrolledDown, setHasScrolledDown] = useState(false)
  const { announcementBarHeightPx, secondaryMenuHeightPx } = useNavbarHeightPx()

  useScrollPosition(({ prevPos, currPos }) => {
    const scrolledDown = currPos.y < prevPos.y
    const navigationElementsHeight =
      announcementBarHeightPx + secondaryMenuHeightPx
    if (
      navigationElementsHeight &&
      scrolledDown !== hasScrolledDown &&
      Math.abs(currPos.y) > navigationElementsHeight
    ) {
      setHasScrolledDown(scrolledDown)
    }
  })

  const router = useRouter()
  // show secondary menu bar after a route has been loaded since the scrollbar is at the top
  router.events.on(
    'routeChangeComplete',
    () => !showSecondaryMenuBar && setShowSecondaryMenuBar(true)
  )

  useEffect(() => {
    if (hasScrolledDown) {
      setShowSecondaryMenuBar(false)
    } else {
      setShowSecondaryMenuBar(true)
    }
  }, [hasScrolledDown])

  return (
    <nav className="w-full bg-core-white">
      <div className="flex px-[40px] border-b border-core-grey-30">
        <div className="mr-[32px] flex items-center shrink-0">
          <Logo />
        </div>
        <div className="flex flex-shrink-0 overflow-scroll grow no-scrollbar">
          {primaryMenuItems?.map((primaryMenu, index) => {
            return (
              <a
                className={`my-[24px] font-mori mr-[32px] last:mr-0 whitespace-nowrap text-[16px] leadint-tight transition-colors duration-300 ${
                  primaryMenu?.primaryMenuLink
                    ? 'cursor-pointer'
                    : 'cursor-default'
                } ${
                  activeHover === index
                    ? 'text-pitaya font-semibold'
                    : 'text-boulder font-normal'
                }`}
                href={primaryMenu?.primaryMenuLink || null}
                onMouseEnter={() => {
                  setActiveHover(index)
                  if (primaryMenu.secondaryMenuItems?.length > 0) {
                    setShowSecondaryMenuBar(true)
                    setSelectedPrimaryMenuIndex(index)
                  }
                }}
                key={`${primaryMenu?.primaryMenuTitle}-${index}`}
                onClick={(e) => {
                  if (primaryMenu?.primaryMenuLink) {
                    e.preventDefault()
                    trackNavigationClick({
                      clickLocation: 'Primary Menu',
                      clickItem: primaryMenu?.primaryMenuTitle,
                    })
                    router.push(primaryMenu?.primaryMenuLink).catch(() => {})
                  }
                }}
              >
                {primaryMenu?.primaryMenuTitle}
              </a>
            )
          })}
        </div>
        <SearchButton />
        <ShippingDestinationPopup />
        <AccountNav />
        <CartButton />
      </div>

      <SecondaryMenu showSecondaryMenuBar={showSecondaryMenuBar} />
    </nav>
  )
}
