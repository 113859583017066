import { PersistedConfiguration } from '../types'

export async function saveUploadConfiguration(
  configuration: PersistedConfiguration
): Promise<string> {
  // Create presigned url for uploading
  const response = await fetch('/api/designer/config-upload/')

  if (response.status !== 200) {
    return ''
  }

  const presignedPost = await response.json()
  const { url, fields } = presignedPost
  const configFile = new File([JSON.stringify(configuration)], 'config.json')
  const formData = new FormData()

  Object.entries({ ...fields, file: configFile }).forEach(([key, value]) => {
    formData.append(key, value as any)
  })

  // Using presigned url to save it
  await fetch(url, {
    method: 'POST',
    body: formData,
  })

  const configId = fields.key.split('/').slice(-1)[0].split('.')[0]
  return configId
}

export async function saveUploadConfigurationJsonData(
  configJsonData: any
): Promise<string> {
  // Create presigned url for uploading
  const response = await fetch('/api/designer/config-upload/')

  if (response.status !== 200) {
    return ''
  }

  const presignedPost = await response.json()

  const { url, fields } = presignedPost

  const configFile = new File([JSON.stringify(configJsonData)], 'config.json')

  const formData = new FormData()

  Object.entries({ ...fields, file: configFile }).forEach(([key, value]) => {
    formData.append(key, value as any)
  })

  // Using presigned url to save it
  await fetch(url, {
    method: 'POST',
    body: formData,
  })

  const configId = fields.key.split('/').slice(-1)[0].split('.')[0]

  return configId
}

export async function loadPersistedUploadConfiguration(
  configId: string
): Promise<PersistedConfiguration | null> {
  const loadConfigResponse = await fetch(
    `/api/designer/get-config?configId=${configId}`
  )
  if (loadConfigResponse.status !== 200) {
    return null
  }

  const config = await loadConfigResponse.json()
  return config as PersistedConfiguration
}
