import { ecommerceContentfulClient as client } from '../clients'
import { Entry } from 'contentful'
import { fetchAllEntriesOfType } from '../fetch-all-entries-of-type'
import { TCrossSellDiscount } from 'types/contentful/crossSellDiscount'

const mappingApplyDiscountTo = (applyDiscountTo: string) => {
  const inclusion = 'inclusion'
  const exclusion = 'exclusion'
  const all = 'all'

  if (!applyDiscountTo) {
    return all
  }

  const lowerCaseApplyDiscountTo = applyDiscountTo.toLocaleLowerCase()

  if (lowerCaseApplyDiscountTo.includes(inclusion)) {
    return inclusion
  }

  if (lowerCaseApplyDiscountTo.includes(exclusion)) {
    return exclusion
  }
  return all
}

export const getCrossSellDiscountConfiguration = async () => {
  const crossSellDiscountEntries: Entry<any>[] = await fetchAllEntriesOfType({
    client,
    contentType: 'crossSellDiscountConfiguration',
  })
  return crossSellDiscountEntries.map((entry) => {
    return {
      ...entry.fields,
      applyDiscountTo: mappingApplyDiscountTo(entry.fields?.applyDiscountTo),
      inclusionExclusionList: entry.fields?.inclusionExclusionList?.map(
        (list) => list.fields?.productSlug
      ),
    } as TCrossSellDiscount
  })
}
