import { trackNavigationClick } from '@utils/gtm'
import { useRouter } from 'next/router'
import { HiArrowRight } from 'react-icons/hi'
import {
  useSelectedPrimaryMenu,
  useSelectedSecondaryMenu,
} from '../NavbarDataProvider'

export const MobileSecondaryMenu = ({
  handleMenuToggle,
}: {
  handleMenuToggle: () => void
}) => {
  const router = useRouter()
  const { selectedPrimaryMenu } = useSelectedPrimaryMenu()
  const secondaryMenuItems = selectedPrimaryMenu?.secondaryMenuItems
  const { setSelectedSecondaryMenuIndex } = useSelectedSecondaryMenu()

  const handleSecondaryClick = (event, idx: number) => {
    event.preventDefault()
    setSelectedSecondaryMenuIndex(idx)
  }

  return (
    <ul className="flex flex-col items-start gap-[16px]">
      {secondaryMenuItems?.length > 0 &&
        secondaryMenuItems.map((secondary, idx) => (
          <li
            key={idx}
            className="font-semibold text-[20px] leading-[24px] text-boulder cursor-pointer hover:text-pitaya"
          >
            {secondary.type === 'link' ? (
              <a
                className="flex items-center gap-[12px]"
                onClick={() => {
                  if (secondary.titleLinkUrl) {
                    trackNavigationClick({
                      clickLocation: 'Secondary Menu',
                      clickItem: secondary?.title,
                    })
                    router.push(secondary.titleLinkUrl).catch(() => {})
                  }
                  handleMenuToggle()
                }}
              >
                <span>{secondary.title}</span>
              </a>
            ) : (
              <a
                className="flex items-center gap-[12px]"
                onClick={(e) => handleSecondaryClick(e, idx)}
              >
                <span>{secondary.title}</span>
                <HiArrowRight className="w-[15px] h-[15px]" />
              </a>
            )}
          </li>
        ))}
    </ul>
  )
}
