import { calculateUnitPrice } from '@pageFeatures/design/designUpload/components/VariantOptionSelection.utils'
import { useProductVariantsWithPrice } from './hooks/useProductVariants.hook'
import { ProductVariant } from '@pageFeatures/design/designUpload/types'
import type { LineItem } from '@framework/types'
import { useCommerce } from '@commerce'
import { useEffect, useState } from 'react'
import { useProductCategory } from './hooks/useProductCategory.hook'
import {
  findLineItemQuantityAlternatives,
  generatePathToPdp,
  generateQunatityOptionsFromProductVariants,
} from './CartItemQuantitySelector.utils'
import {
  IQuantitySelectorOption,
  QuantitySelector,
} from '@components/common/QuantitySelector'

interface ICartItemQuantitySelector {
  cartItem: LineItem
  shown: boolean
  onChange: ({ options, variantId }) => void
}

export function CartItemQunatitySelector({
  cartItem,
  shown,
  onChange,
}: ICartItemQuantitySelector) {
  const { locale } = useCommerce()
  const [requestAQuoteFormPath, setRequestAQuoteFormPath] =
    useState<string>(null)
  const [selectedQuantityOption, setSelectedQuantityOption] =
    useState<IQuantitySelectorOption>(null)
  const [quantityOptions, setQuantityOptions] =
    useState<IQuantitySelectorOption[]>(null)
  const [alternativeVariantsOfItem, setAlternativeVariantsOfItem] =
    useState<ProductVariant[]>(null)

  /* Fetching data */
  const {
    productCategory,
    loading: categoryLoading,
    error: categoryError,
  } = useProductCategory({
    productSlug: cartItem?.categorySlug,
    fetch: shown,
  })

  const {
    productVariantsWithPrice,
    loading: productVariantsLoading,
    error: productVariantsError,
  } = useProductVariantsWithPrice({
    productSlug: cartItem?.categorySlug,
    fetch: shown,
  })

  /* Parsing data */
  useEffect(() => {
    if (productVariantsWithPrice?.length) {
      const alternativeVariantsOfItem = findLineItemQuantityAlternatives({
        productVariants: productVariantsWithPrice,
        lineItemOptions: cartItem?.options,
      })
      setAlternativeVariantsOfItem(alternativeVariantsOfItem)

      const quantityOptions = generateQunatityOptionsFromProductVariants({
        productVariants: alternativeVariantsOfItem,
      })
      setQuantityOptions(quantityOptions)
    }
  }, [productVariantsWithPrice, cartItem])

  useEffect(() => {
    // Setting request a quote url to override value from contentful
    // If there is no override value - generating a path to PDP
    setRequestAQuoteFormPath(
      productCategory?.requestAQuoteFormPath ||
        generatePathToPdp(cartItem?.categorySlug)
    )
  }, [productCategory, cartItem?.categorySlug])

  useEffect(() => {
    const quantityOption = cartItem?.options?.find(
      (x) => x?.name.toLowerCase() === 'quantity'
    )
    setSelectedQuantityOption({
      displayLabel: quantityOption?.value,
      key: quantityOption?.value,
    })
  }, [cartItem])

  /* Event handlers */
  const onQuantityChange = (option: IQuantitySelectorOption) => {
    const selectedProductVariant = alternativeVariantsOfItem?.find(
      (variant) => variant.quantity === String(option.key)
    )
    const quantityOptionIndex = cartItem?.options?.findIndex(
      (option) => option?.name?.toLowerCase() === 'quantity'
    )
    const copiedItemOptions = Array.from(cartItem?.options)

    const selectedVariantQuantityOption =
      selectedProductVariant?.optionsValues?.find(
        (option) =>
          option.option_display_name?.toLocaleLowerCase() === 'quantity'
      )

    copiedItemOptions.splice(quantityOptionIndex, 1, {
      name: 'Quantity',
      value: option.key,
      valueId: selectedVariantQuantityOption?.id,
      nameId: selectedVariantQuantityOption?.option_id,
    } as any)

    onChange({
      options: copiedItemOptions,
      variantId: selectedProductVariant?.variantId,
    })
  }

  return (
    <div>
      {shown &&
        !categoryLoading &&
        !productVariantsLoading &&
        !productVariantsError &&
        !categoryError &&
        quantityOptions?.length && (
          <QuantitySelector
            options={
              quantityOptions.map((option) => {
                return {
                  ...option,
                  discount: calculateUnitPrice({
                    priceOfProduct:
                      option.price.salePrice > 0
                        ? option.price.salePrice
                        : option.price.originalPrice,
                    qtyOfProduct: +option.key,
                    locale,
                  }),
                }
              }) as IQuantitySelectorOption[]
            }
            onChange={onQuantityChange}
            selected={selectedQuantityOption}
            className="mb-0"
            includeQuoteOption={Boolean(requestAQuoteFormPath)}
            requestAQuoteFormPath={requestAQuoteFormPath}
          />
        )}

      {(productVariantsLoading || categoryLoading) && (
        <div className="flex items-center justify-center w-full rounded-2xl h-96 bg-black-20 animate-pulse" />
      )}
    </div>
  )
}
