import { Button } from '@noissue-ui-kit/button'
import { Link } from '@noissue-ui-kit/oldBranding/link'
import { trackNavigationClick } from '@utils/gtm'
import { useRouter } from 'next/router'
import { TSecondaryMenuItem } from '../../Navbar.types'
import { SelectionMenu } from '../SelectionMenu.tsx/SelectionMenu'
import { NavbarTile } from '../TileSection/NavbarTile'
import { useSelectedPrimaryMenu } from '@components/common/Navbar/NavbarDataProvider'
import { useTrackingBreadcrumbs } from '@utils/hooks/useTrackingBreadcrumbs'
import { useEffect, useRef, useState } from 'react'
import { useWindowSize } from '@utils/responsiveness/useWindowSize'

const MAX_PAGE_WIDTH = 1920

export function ListDropdown({
  selectedSecondaryMenu,
}: {
  selectedSecondaryMenu: TSecondaryMenuItem
}) {
  const router = useRouter()
  const { selectedPrimaryMenu } = useSelectedPrimaryMenu()
  const { saveBreadcrumbs } = useTrackingBreadcrumbs()
  const linksWrapperRef = useRef(null)
  const { width, bodyWidth } = useWindowSize()

  const { content, tiles, tileSectionLink } = selectedSecondaryMenu

  const hasFeatureTiles = tiles?.some(
    (tile) => tile?.desktopLayout === 'feature'
  )

  const [tilesPerRow, setTilesPerRow] = useState(2)
  const [tileWidth, setTileWidth] = useState(0)
  const TILES_GRID_GAP = 15

  const calculateTileWidth = (pageWidth) => {
    if (width && linksWrapperRef?.current?.offsetWidth) {
      const MAX_TILE_WIDTH = 500
      const MIN_TILE_WIDTH = 225
      const CONTAINER_PADDINGS = 105

      const tilesContainerWidth =
        pageWidth - linksWrapperRef?.current?.offsetWidth - CONTAINER_PADDINGS
      const totalTiles = tiles?.length

      const widthToRange = (width) =>
        Math.max(Math.min(MAX_TILE_WIDTH, width), MIN_TILE_WIDTH)

      if (totalTiles % 4 === 0 || totalTiles >= 7) {
        setTilesPerRow(4)
        return widthToRange(tilesContainerWidth / 4 - TILES_GRID_GAP)
      }

      if (totalTiles % 3 === 0 || totalTiles >= 3) {
        setTilesPerRow(3)
        return widthToRange(tilesContainerWidth / 3 - TILES_GRID_GAP)
      }

      if (totalTiles % 2 === 0) {
        setTilesPerRow(2)
        return widthToRange(tilesContainerWidth / 2 - TILES_GRID_GAP)
      }
      return widthToRange(tilesContainerWidth)
    }
  }

  useEffect(() => {
    setTileWidth(calculateTileWidth(Math.min(bodyWidth, MAX_PAGE_WIDTH)))
  }, [bodyWidth])

  return selectedSecondaryMenu?.selectionMenus?.length > 0 ? (
    <>
      <div
        className={`flex flex-col flex-shrink-0 ${
          selectedSecondaryMenu?.startWithTiles
            ? 'order-2 ml-10'
            : 'order-1 mr-10'
        }`}
        ref={linksWrapperRef}
      >
        <div className="flex flex-wrap">
          {selectedSecondaryMenu?.selectionMenus?.map(
            (selectionMenu, index) => {
              const breadcrumbs = [
                {
                  name: selectedPrimaryMenu.primaryMenuTitle,
                  url: selectedPrimaryMenu.primaryMenuLink,
                },
                {
                  name: selectedSecondaryMenu.title,
                  url: selectedSecondaryMenu.titleLinkUrl,
                },
              ]

              return (
                <SelectionMenu
                  className={`last:pr-0 pr-[32px] mb-[32px] flex flex-col`}
                  selectionMenu={selectionMenu}
                  breadcrumbs={breadcrumbs}
                  key={index}
                />
              )
            }
          )}
        </div>
        <div>
          {content?.ctaLabel && content?.ctaLink && (
            <Button
              size={'s'}
              corners={'rounded'}
              href={content?.ctaLink}
              onClick={() => {
                if (content?.ctaLink) {
                  trackNavigationClick({
                    clickLocation: 'Dropdown CTA',
                    clickItem: content?.ctaLabel,
                  })

                  const breadcrumbs = [
                    {
                      name: selectedPrimaryMenu.primaryMenuTitle,
                      url: selectedPrimaryMenu.primaryMenuLink,
                    },
                    {
                      name: content?.ctaLabel,
                      url: content?.ctaLink,
                    },
                  ]

                  saveBreadcrumbs(breadcrumbs)

                  router.push(content?.ctaLink).catch(() => {})
                }
              }}
              Component="a"
            >
              {content?.ctaLabel}
            </Button>
          )}
        </div>
      </div>

      {Boolean(tileWidth) && (
        <div
          className={`flex h-full flex-initial ${
            selectedSecondaryMenu?.startWithTiles
              ? 'order-1'
              : 'order-2 ml-auto'
          }`}
          style={{
            width:
              (tileWidth * tilesPerRow + TILES_GRID_GAP * tilesPerRow || 1000) +
              'px',
          }}
        >
          <div
            className={`flex flex-nowrap w-full gap-6 wrap ${
              selectedSecondaryMenu?.startWithTiles
                ? 'justify-start'
                : 'justify-end'
            }`}
          >
            {tiles?.map((tile, index) => {
              return (
                <div
                  style={{
                    width: tileWidth + 'px',
                    minWidth: tileWidth + 'px',
                  }}
                >
                  <NavbarTile
                    title={tile?.title}
                    titleSize={tile?.titleSize}
                    titleColor={tile?.titleColor}
                    subtitle={tile?.subtitle}
                    subtitleColor={tile?.subtitleColor}
                    tileLink={tile?.blockLink}
                    backgroundColor={tile?.backgroundColor}
                    image={tile?.image}
                    images={tile?.images}
                    layout={tile?.desktopLayout}
                    siblingIsFeatureTile={hasFeatureTiles}
                    key={index}
                  />
                </div>
              )
            })}
          </div>

          {tileSectionLink?.title && tileSectionLink?.link && (
            <Link
              size="m"
              className="mb-3 font-normal mt-[32px] inline-block"
              iconName="HiArrowRight"
              iconSide="right"
              colour="purple"
              href={tileSectionLink?.link}
              onClick={() => {
                if (tileSectionLink?.link) {
                  trackNavigationClick({
                    clickLocation: 'Dropdown Tile Section Link',
                    clickItem: tileSectionLink?.title,
                  })

                  const breadcrumbs = [
                    {
                      name: selectedPrimaryMenu.primaryMenuTitle,
                      url: selectedPrimaryMenu.primaryMenuLink,
                    },
                    {
                      name: tileSectionLink?.title,
                      url: tileSectionLink?.link,
                    },
                  ]

                  saveBreadcrumbs(breadcrumbs)

                  router.push(tileSectionLink?.link).catch(() => {})
                }
              }}
            >
              {tileSectionLink?.title}
            </Link>
          )}
        </div>
      )}
    </>
  ) : null
}
