// Used to contain functions and logic used to handle backwards compatibility of legacy editors.

import { BadgeAlertMessage } from '@noissue-ui-kit/oldBranding/badgeAlertMessage'
import type { LineItem } from '@framework/types'
import { legacyDesignJourneyToNew } from '@pageFeatures/design/utils'
import _ from 'lodash'
import {
  appendOrReplaceItemToListToLocalStorage,
  getItemFromListFromLocalStorage,
} from '@utils/localstorage'
import { removeInvalidFileUrls } from '@utils/utilities'
import { TDesignJourney } from '@pageFeatures/design/types'

const LOCAL_STORAGE_V3_EDITOR_CONFIG = 'oldV3Config'
const LOCAL_STORAGE_V3_EDITOR_EXPIRY = 7 * 24 * 60 * 60 * 1000
export const LEGACY_EDITOR_URL_FIELDS = [
  'logos',
  'thumbnailLinks',
  'logoUrls',
  'filesUrls',
  'customFontUrls',
]

function tryToParseCustomizationsObject(customizationsValue) {
  if (typeof customizationsValue === 'object') {
    return customizationsValue
  } else if (typeof customizationsValue === 'string') {
    let customizationsObject = {}
    try {
      customizationsObject = JSON.parse(customizationsValue)
    } catch (err) {}
    return customizationsObject
  }
  return {}
}

export function generateEditLink(item: LineItem) {
  const options = item.options
  const customizations = tryToParseCustomizationsObject(
    options.find((option) => option.name === 'Customizations')?.value || '{}'
  )

  let editLink =
    customizations &&
    customizations.productCategorySlug &&
    customizations.configId
      ? `/upload/${customizations.productCategorySlug}/?edit=true&configId=${customizations.configId}&itemId=${item.id}`
      : undefined

  // Old V3 data won't have productCategorySlug and configId fields
  if (
    !editLink && // Empty but still has configId
    customizations &&
    customizations.version === 'V3'
  ) {
    // new editor are loading config from S3 with configId
    // we need to save to config to localStorage for loading in new editor
    removeInvalidFileUrls(customizations)
    const filesUrls = _.chain(LEGACY_EDITOR_URL_FIELDS)
      .map((field) => _.get(customizations, field, []))
      .flatten()
      .filter((url) => !_.isNil(url) && url.indexOf('/upload_attachments/') > 0)
      .value()

    appendOrReplaceItemToListToLocalStorage(
      LOCAL_STORAGE_V3_EDITOR_CONFIG,
      JSON.stringify({
        itemId: item.id,
        configuration: {
          designJourney: legacyDesignJourneyToNew[customizations.version],
          filesUrls: filesUrls,
        },
      }),
      {
        ttl: LOCAL_STORAGE_V3_EDITOR_EXPIRY,
        valueKey: 'itemId',
      }
    )
    editLink = `/upload/${item.categorySlug}/?edit=true&itemId=${item.id}`
  }

  return editLink
}

export function checkIfLegacyEditor(configurationLoaded) {
  const editorVersion = configurationLoaded?.version
  return (
    (editorVersion === 'V3' &&
      _.isNil(configurationLoaded?.brief) &&
      _.isEmpty(configurationLoaded?.brief)) ||
    (editorVersion === 'V2' &&
      _.isNil(configurationLoaded?.onlineEditor) &&
      _.isEmpty(configurationLoaded?.onlineEditor))
  )
}

export function loadOldV3EditorConfigFromLocalStorage(itemId): {
  designJourney: TDesignJourney
  filesUrls: string[]
} {
  const savedConfig = getItemFromListFromLocalStorage(
    LOCAL_STORAGE_V3_EDITOR_CONFIG,
    'itemId',
    itemId
  ) as {
    itemId: string
    configuration: {
      filesUrls: string[]
      designJourney: TDesignJourney
    }
  }
  return savedConfig?.configuration
}

export function LegacyV3EditorAlertMessage() {
  return (
    <BadgeAlertMessage
      badge={{
        url: '/help-logo-tooltip2x.png',
        alt: 'noissue help badge',
      }}
      size={'s'}
      className="mb-6"
      variant="attention"
    >
      Looks like you’re editing an old order. We've released major updates to
      our design journey! You'll need to resubmit your order this time round.
      We’ve added your original artwork.
    </BadgeAlertMessage>
  )
}
