import { FC } from 'react'
import Bottom from './Bottom'
import { Pages, IFooterPage } from './Pages/Pages'
import SignUp from './SignUp'
import findIndex from 'lodash/findIndex'
import { TheWrap } from '@components/icons'
import {
  FooterBanner,
  IFooterBanner,
} from '@components/common/Footer/FooterBanner'
import { GenericContentBlock } from 'types/contentful/genericContentBlock'
import {
  IImage,
  mapContentfulImageAsset,
} from '@utils/contentful/contentfulImage'
import { THubspotFormSpecification } from '@utils/form/hubspot/schema'
import { TConsentOptions } from '@utils/form/schema'

const hydrateFooterColumnsWithExtraInfo = (footerColumns: IFooterPage[]) => {
  const indexOfTheWrap = findIndex(footerColumns, (footerColumn) =>
    footerColumn?.text?.toLowerCase().includes('inspo')
  )

  if (footerColumns[indexOfTheWrap]?.pages[0]) {
    footerColumns[indexOfTheWrap].pages[0].Component = TheWrap
  }

  return footerColumns
}

const Footer: FC<{
  className?: string
  children?: any
  footerColumns: IFooterPage[]
  bannerContent: GenericContentBlock
  badgeImage: IImage
  footerSignupForm: {
    hubspotId: string
    consentSpec: TConsentOptions
    formSpec: THubspotFormSpecification
  }
}> = ({ className, footerColumns, bannerContent, footerSignupForm }) => {
  const footerBannerContentProps: IFooterBanner = {
    title: bannerContent?.h1,
    leftIamge: mapContentfulImageAsset(bannerContent?.primaryImages[0]),
    link: bannerContent?.ctaLink,
    linkLabel: bannerContent?.ctaLabel,
  }
  return (
    <footer id="join-us" className={className}>
      <div className="bg-white font-mori pt-28">
        <div className="relative flex flex-col md:px-16 gap-[16px] content-container">
          <div className="w-full">
            {footerColumns && footerColumns.length > 0 && (
              <Pages
                pages={hydrateFooterColumnsWithExtraInfo(footerColumns)}
                footerBannerContent={{
                  ...footerBannerContentProps,
                  className: 'xxs:hidden lg:flex',
                }}
              />
            )}
          </div>

          <FooterBanner
            className="mt-0 md:mx-0 xl:hidden"
            {...footerBannerContentProps}
          />

          <SignUp signupForm={footerSignupForm} />

          <Bottom />
        </div>
      </div>
    </footer>
  )
}

export default Footer
