import { codeToShippingLocation } from '@components/common/Navbar/ShippingDestination/utils'
import { NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_COUNTRY } from '@utils/client-side-config'
import { useState } from 'react'

export function useShareCartLink() {
  const [shareCartModal, setShareCartModal] = useState<{
    isOpen: boolean
    title: string
    subtitle: string
  }>({
    isOpen: false,
    title: '',
    subtitle: '',
  })
  const [sharedCartLoading, setSharedCartLoading] = useState<boolean>(false)

  const shareCart = async (lineItems) => {
    try {
      setSharedCartLoading(true)
      const uploadLinkResponse = await fetch(
        '/api/bigcommerce/adminCart/getSharedCartId',
        {
          method: 'POST',
        }
      )

      const { post, configId } = await uploadLinkResponse.json()
      const { url, fields } = post
      const lineItemsFile = new File(
        [JSON.stringify(lineItems)],
        'lineItems.json'
      )

      const formData = new FormData()

      Object.entries({
        ...fields,
        file: lineItemsFile,
      }).forEach(([key, value]) => {
        formData.append(key, value as any)
      })

      const uploadResult = await fetch(url, {
        method: 'POST',
        body: formData,
      })

      const siteRegion = codeToShippingLocation(
        NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_COUNTRY
      )

      await navigator.clipboard.writeText(
        window.origin +
          `?cartHash=${configId}&siteRegion=${
            siteRegion.selectorOverride || siteRegion?.key
          }`
      )

      if (uploadResult.status > 226) {
        throw Error(uploadResult.statusText)
      }

      setShareCartModal({
        isOpen: true,
        title: 'Cart link copied!',
        subtitle: 'Your cart has been copied to clipboard. Share away.',
      })
    } catch (err) {
      setShareCartModal({
        isOpen: true,
        title: 'Something went wrong',
        subtitle: `Couldn't copy cart configuration. Please try again.`,
      })
    } finally {
      setSharedCartLoading(false)
    }
  }

  const closeShareCartModal = () => {
    setShareCartModal({
      isOpen: false,
      title: '',
      subtitle: '',
    })
  }

  return {
    shareCart,
    sharedCartLoading,
    shareCartModal,
    closeShareCartModal,
  }
}
