import { useEffect, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { TSearchItem } from 'pages/api/content/search'
import { getOrders } from '@pageFeatures/admin/orders/utils/dtcOrders.utils'
import { getPlusOrders } from '@pageFeatures/admin/orders/utils/plusOrders.utils'
import { fetchHubspotContactByEmail } from '@utils/hubspot'
import { IRawDTCOrder } from 'types/orders'
import { IHubspotDeal } from 'types/hubspot'

interface ISearchResults {
  products: TSearchItem[]
  categories: TSearchItem[]
  orders?: TSearchItem[]
}

function useSearchResults({
  searchText,
  email,
}: {
  searchText: string
  email: string
}): {
  searchResults: ISearchResults
  loading: boolean
  error: string
  foundProducts: boolean
  foundCategories: boolean
  foundOrders: boolean
} {
  const intialResults = { categories: [], products: [], orders: [] }
  const [loadingContentful, setLoadingContentful] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [searchResults, setSearchResults] =
    useState<ISearchResults>(intialResults)

  // Syntactic sugar for convenience in root component
  const [foundCategories, setFoundCategories] = useState<boolean>(false)
  const [foundProducts, setFoundProducts] = useState<boolean>(false)
  const [foundOrders, setFoundOrders] = useState<boolean>(false)

  // Orders
  const [dtcOrders, setDtcOrders] = useState<IRawDTCOrder[]>(null)
  const [loadingDtc, setLoadingDtc] = useState<boolean>(false)

  const [plusOrders, setPlusOrders] = useState<IHubspotDeal[]>(null)
  const [loadingPlus, setLoadingPlus] = useState<boolean>(false)

  const [orderSearchItems, setOrderSearchItems] = useState<TSearchItem[]>([])
  const [filteredOrders, setFilteredOrders] = useState<TSearchItem[]>([])

  // Check if query contains any digints, "raq" or "#raq"
  const customerDoesSearchForOrder = (searchText) => {
    return /\d|#?raq/.test(searchText?.toLowerCase())
  }

  useEffect(() => {
    if (customerDoesSearchForOrder(searchText)) {
      setSearchResults(intialResults)
      return
    }

    setError(null)

    const fetchSearchResults = async (searchText) => {
      try {
        setLoadingContentful(true)
        const results = await (
          await fetch(
            `/api/content/search/?q=${encodeURIComponent(
              searchText
            )}&email=${encodeURIComponent(email)}`
          )
        ).json()
        setSearchResults(results)
      } catch (err) {
        setError(err.message || 'Something went wrong...')
      } finally {
        setLoadingContentful(false)
      }
    }

    if (!searchText) {
      setSearchResults(null)
      setLoadingContentful(false)
      return
    } else {
      fetchSearchResults(searchText)
    }
  }, [searchText])

  useEffect(() => {
    setFoundCategories(!isEmpty(searchResults?.categories))
    setFoundProducts(!isEmpty(searchResults?.products))
    setFoundOrders(Boolean(filteredOrders?.length))
  }, [searchResults, filteredOrders])

  const fetchAllDtcOrders = async () => {
    setLoadingDtc(true)
    const resultsPerPage = 250
    let orders = []

    const fetchOrders = async (page) => {
      const newOrders = (await getOrders(page, resultsPerPage))?.orders || []

      if (newOrders?.length === resultsPerPage) {
        orders = [...orders, ...newOrders]
        page = page + 1
        return await fetchOrders(page)
      } else {
        return [...orders, ...newOrders]
      }
    }

    setDtcOrders((await fetchOrders(1)) || [])
    setLoadingDtc(false)
  }

  const fetchAllDeals = async (email) => {
    setLoadingPlus(true)
    const resultsPerPage = 99
    let deals = []

    const fetchDeals = async (page) => {
      const hubspotContact = await fetchHubspotContactByEmail(email)
      const newDeals =
        (await getPlusOrders({
          hubspotContact: hubspotContact,
          ordersPerPage: resultsPerPage,
          page,
        })) || []

      if (deals?.length === resultsPerPage) {
        deals = [...deals, ...newDeals]
        return await fetchDeals(page + 1)
      } else {
        return [...deals, ...newDeals]
      }
    }

    setPlusOrders((await fetchDeals(1)) || [])
    setLoadingPlus(false)
  }

  // Combine deals (plus orders) and dtc orders into common OrderSearhcItems array
  useEffect(() => {
    const dtcSearchItems =
      dtcOrders?.map((order) => {
        return {
          name: '#' + String(order.id),
          slug: '/admin/orders/' + order.id,
          image: '/plus-order-icon.png',
        }
      }) || []

    const plusSearchItems =
      plusOrders?.map((order: IHubspotDeal) => {
        return {
          name: '#RAQ' + String(order.id),
          slug: '/admin/orders/plus/' + order.id,
          image: '/plus-order-icon.png',
        }
      }) || []

    setOrderSearchItems([...dtcSearchItems, ...plusSearchItems])
  }, [plusOrders, dtcOrders])

  useEffect(() => {
    if (!searchText || !customerDoesSearchForOrder(searchText)) {
      return
    }

    if (dtcOrders === null && !loadingDtc) {
      fetchAllDtcOrders()
    }

    if (email && !loadingPlus && plusOrders === null) {
      fetchAllDeals(email)
    }
  }, [email, searchText, dtcOrders, plusOrders, loadingPlus, loadingDtc])

  useEffect(() => {
    if (searchText && orderSearchItems?.length) {
      setFilteredOrders(
        orderSearchItems?.filter((order) =>
          order?.name
            ?.toLowerCase()
            ?.includes(searchText?.toLowerCase()?.trim())
        )
      )
    }
  }, [searchText, orderSearchItems])

  return {
    searchResults: {
      ...searchResults,
      orders: filteredOrders,
    },
    loading: loadingContentful || loadingDtc || loadingPlus,
    error,
    foundCategories,
    foundProducts,
    foundOrders,
  }
}

export { useSearchResults }
