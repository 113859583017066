import { useFeature } from '@growthbook/growthbook-react'
import { REAL_BREADCRUMB_ENABLED } from '@utils/featureFlags'
import { MEGAMENU_BREADCRUMBS_SESSION_KEY } from '@utils/constants'

export type TBreadcrumb = {
  name: string
  url: string
}

export const useTrackingBreadcrumbs = () => {
  const realBreadcrumbEnabled = useFeature(REAL_BREADCRUMB_ENABLED).on

  const saveBreadcrumbs = (breadcrumbs: TBreadcrumb[]) => {
    if (realBreadcrumbEnabled) {
      sessionStorage.setItem(
        MEGAMENU_BREADCRUMBS_SESSION_KEY,
        JSON.stringify(breadcrumbs)
      )
    }
  }

  return { saveBreadcrumbs }
}
