const getName = ({ item, customizations, nameSuffixes, slugNameMapping }) => {
  const name = item.name.split('|')[0].trim()
  const categorySlug = item.categorySlug

  const originalUrl = item.originalUrl

  const version =
    customizations?.version || (originalUrl.includes('marketplace') && 'stock')
  const productType =
    (originalUrl.includes('marketplace') && 'stock') || 'custom'
  const modifiedProductName = slugNameMapping[productType][categorySlug] || name
  return `${modifiedProductName} ${nameSuffixes[version] || ''}`
}

export default getName
