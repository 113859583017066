import { useShareCartLink } from './useShareCartLink.hook'
import { HiLink } from 'react-icons/hi'
import type { LineItem } from '@framework/types'
import { Modal } from '@noissue-ui-kit/modal'

interface IShareCartButton {
  lineItems: LineItem[]
}
export function ShareCartButton({ lineItems }: IShareCartButton) {
  const { sharedCartLoading, shareCartModal, shareCart, closeShareCartModal } =
    useShareCartLink()

  return (
    <>
      <button
        className="w-full flex justify-center items-center mr-4 text-boulder text-xl mt-10 group hover:text-acai"
        onClick={() => shareCart(lineItems)}
        disabled={sharedCartLoading}
      >
        <HiLink className="mr-2 text-2xl" />

        <p className="relative w-auto">
          {sharedCartLoading && <span>Generating link...</span>}
          {!sharedCartLoading && (
            <span className="group-hover:underline">Share Cart</span>
          )}
        </p>
      </button>

      <Modal
        size="small"
        opened={shareCartModal?.isOpen}
        title={shareCartModal?.title}
        showControls={{
          cancelButton: false,
          confirmButton: true,
          cancelIcon: true,
        }}
        onCancel={() => closeShareCartModal()}
        onConfirm={() => closeShareCartModal()}
        confirmLabel="Okay"
      >
        <p className="text-2xl">{shareCartModal?.subtitle}</p>
      </Modal>
    </>
  )
}
