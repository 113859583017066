import React, { useEffect } from 'react'
import { ContentfulMarkdown } from '@utils/contentful/ContentfulMarkdown'

interface INotificationProps {
  opened: boolean
  message: string
  size?: 'small' | 'medium' | 'large'
  variant?: 'info' | 'infoInverted' | 'warning' | 'succes'
  position?: 'bottomLeft' | 'bottomRight' | 'bottomCenter'
  className?: string
  close: () => void
  showForMs?: number
}

export const Notification = ({
  size = 'small',
  variant = 'info',
  position = 'bottomCenter',
  className,
  message,
  opened,
  close,
  showForMs = 3000,
}: INotificationProps) => {
  const sizeToClassMapper: Record<'small' | 'medium' | 'large', string> = {
    small: 'px-7 py-3 text-xl',
    medium: 'px-9 py-5 text-2xl',
    large: 'px-12 py-5 text-3xl',
  }

  const variantToClassMapper: Record<
    'info' | 'infoInverted' | 'warning' | 'succes',
    string
  > = {
    info: 'bg-white text-boulder',
    infoInverted: 'bg-black text-white',
    warning: 'bg-white text-error',
    succes: 'bg-black-80 text-starfruit',
  }

  const positionOpenToClass: Record<
    'bottomLeft' | 'bottomRight' | 'bottomCenter',
    string
  > = {
    bottomLeft: 'bottom-16 left-16',
    bottomRight: 'bottom-16 right-16',
    bottomCenter: 'bottom-16 left-1/2 -translate-x-1/2',
  }

  const positionCloseToClass: Record<
    'bottomLeft' | 'bottomRight' | 'bottomCenter',
    string
  > = {
    bottomLeft: 'bottom-12 left-16',
    bottomRight: 'bottom-12 right-16',
    bottomCenter: 'bottom-12 left-1/2 -translate-x-1/2',
  }

  useEffect(() => {
    if (opened) {
      setTimeout(() => {
        close()
      }, showForMs)
    }
  }, [opened])

  return (
    <div
      className={`w-full max-w-[80%] sm:w-auto flex fixed z-[99999] rounded-xl shadow-md transition-all duration-300 ease-in-out
                ${sizeToClassMapper[size]}
                ${variantToClassMapper[variant]}
                ${className}
                ${
                  opened
                    ? `opacity-1 visible ${positionOpenToClass[position]}`
                    : `opacity-0 invisible ${positionCloseToClass[position]}`
                }
            `}
    >
      <ContentfulMarkdown
        overrideClass={`w-full text-center sm:text-left mb-0 ${variantToClassMapper[variant]}`}
      >
        {message}
      </ContentfulMarkdown>
    </div>
  )
}
