import isEmpty from 'lodash/isEmpty'

const createItem = (value: string, ttl?: number) => {
  const now = new Date()
  const item = {
    value: JSON.parse(value || '{}'),
    expiry: ttl > 0 ? now.getTime() + ttl : undefined,
  }

  return { item, now }
}

export const setItemToLocalStorage = (
  key: string,
  value: string,
  ttl?: number
) => {
  const { item } = createItem(value, ttl)
  localStorage.setItem(key, JSON.stringify(item))
}

export const getItemFromLocalStorage = (key: string) => {
  const itemStr = localStorage.getItem(key)
  if (!itemStr) {
    return null
  }

  const item = JSON.parse(itemStr)

  const now = new Date()
  if (item.expiry) {
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key)
      return null
    }
  }
  return item.value
}

export const getItemFromListFromLocalStorage = (
  key: string,
  itemKey: string,
  keyValue: any
): any => {
  const now = new Date()
  const items = getItemFromLocalStorage(key) as [{ value: any; expiry: number }]
  if (!items) {
    return null
  }
  const validItems = items.filter((item) =>
    item.expiry ? now.getTime() < item.expiry : true
  )

  const item = validItems.find(
    (validItem) => keyValue === validItem.value?.[itemKey]
  )
  return item?.value
}

export const appendOrReplaceItemToListToLocalStorage = (
  key: string,
  value: string,
  {
    ttl = 0,
    valueKey = 'slug',
  }: {
    ttl: number
    valueKey?: string
  } = { ttl: 0, valueKey: 'slug' }
) => {
  const existingItems = getItemFromLocalStorage(key)
  const { item, now } = createItem(value, ttl)
  if (isEmpty(existingItems)) {
    setItemToLocalStorage(key, JSON.stringify([item]))
  } else {
    const validItems = existingItems.filter((item) =>
      item.expiry ? now.getTime() < item.expiry : true
    )
    const foundItemIdx = validItems.findIndex(
      (validItem) => item.value?.[valueKey] === validItem.value?.[valueKey]
    )
    if (foundItemIdx > -1) {
      validItems[foundItemIdx] = item
    } else {
      validItems.push(item)
    }
    setItemToLocalStorage(key, JSON.stringify(validItems))
  }
}

export const updateItemFromListFromLocalStorage = (
  key: string,
  value: string,
  {
    ttl = 0,
    valueKey = 'slug',
  }: {
    ttl: number
    valueKey?: string
  } = { ttl: 0, valueKey: 'slug' }
) => {
  const existingItems = getItemFromLocalStorage(key)
  if (isEmpty(existingItems)) {
    return
  }

  const now = new Date()
  const item = JSON.parse(value)
  const validItems = existingItems.filter((item) =>
    item.expiry ? now.getTime() < item.expiry : true
  )
  const foundItemIdx = validItems.findIndex(
    (validItem) => item.value?.[valueKey] === validItem.value?.[valueKey]
  )
  if (foundItemIdx > -1) {
    if (ttl > 0) {
      item.expiry = ttl
    }
    validItems[foundItemIdx] = item
    setItemToLocalStorage(key, JSON.stringify(validItems))
  }
}

export const removeItemFromListFromLocalStorage = (
  key: string,
  itemValue: string,
  itemValueKey = 'slug'
) => {
  const existingItems = getItemFromLocalStorage(key)
  if (isEmpty(existingItems)) {
    return
  }
  const validItems = existingItems.filter(
    (item) => item.value?.[itemValueKey] !== itemValue
  )
  setItemToLocalStorage(key, JSON.stringify(validItems))
}

export const removeItemFromLocalStorage = (key: string) => {
  localStorage.removeItem(key)
}
