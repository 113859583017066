import React from 'react'
import Link from '@components/common/NoPrefetchLink'
import {
  HiOutlineHeart,
  HiOutlineSave,
  HiOutlineStar,
  HiOutlineCog,
  HiOutlineShoppingCart,
} from 'react-icons/hi'
import Image from 'next/image'
import { useRouter } from 'next/router'

const UserNavItemIcon = {
  HiOutlineHeart,
  HiOutlineSave,
  HiOutlineStar,
  HiOutlineCog,
  HiOutlineShoppingCart,
}

type IconName = keyof typeof UserNavItemIcon

export function UserNavItem({
  itemLabel,
  itemIconName,
  itemIconUrl,
  itemUrl,
  onClick = () => {},
}: {
  itemLabel: string
  itemIconName?: IconName
  itemIconUrl?: string
  itemUrl: string
  onClick?: () => void
}) {
  const router = useRouter()
  const DynamicHeroIcon = itemIconName ? UserNavItemIcon[itemIconName] : null

  return (
    <a
      className={`flex items-center py-3 pt-1 leading-10 cursor-pointer hover:text-pitaya group ${
        router?.asPath?.includes(itemUrl) ? 'text-pitaya' : ''
      }`}
      onClick={onClick}
      href={itemUrl || '/'}
    >
      {(DynamicHeroIcon || itemIconUrl) && (
        <div className="mr-5 w-7 h-7 flex items-center justify-center">
          {DynamicHeroIcon && <DynamicHeroIcon className="w-full h-full" />}

          {!DynamicHeroIcon && itemIconUrl && (
            <Image
              alt="Navigation Icon"
              src={itemIconUrl}
              height={18}
              width={18}
              className={`flex-shrink-0 w-full h-full group-hover:filter-pitaya ${
                router?.asPath?.includes(itemUrl) ? 'filter-pitaya' : ''
              }`}
            />
          )}
        </div>
      )}
      <span className="pt-1">{itemLabel}</span>
    </a>
  )
}
