import { ICartMetafield, ICartMetafieldPayload } from './cartMetafields.types'

const getCartMetafields = async ({ cartId }: { cartId: string }) => {
  const response = await fetch(
    `/api/bigcommerce/adminCart/${cartId}/metafields`
  )
  return await response.json()
}

const findCartMetafieldByKey = ({
  metafields,
  key,
}: {
  metafields: ICartMetafield[]
  key: string
}): ICartMetafield | null => {
  if (!metafields || !metafields?.length || !key) {
    return null
  }

  return metafields?.find((x) => x.key === key) || null
}

const updateCartMetafield = async ({
  metafieldPayload,
  cartId,
  metafieldId,
}: {
  metafieldPayload: ICartMetafieldPayload
  cartId: string
  metafieldId: string
}): Promise<ICartMetafield> => {
  const response = await fetch(
    `/api/bigcommerce/adminCart/${cartId}/metafields/${metafieldId}`,
    {
      method: 'PUT',
      body: JSON.stringify(metafieldPayload),
    }
  )

  return await response.json()
}

const addCartMetafield = async ({
  metafieldPayload,
  cartId,
}: {
  metafieldPayload: ICartMetafieldPayload
  cartId: string
}): Promise<ICartMetafield> => {
  const response = await fetch(
    `/api/bigcommerce/adminCart/${cartId}/metafields`,
    {
      method: 'POST',
      body: JSON.stringify(metafieldPayload),
    }
  )

  return await response.json()
}

export {
  findCartMetafieldByKey,
  getCartMetafields,
  addCartMetafield,
  updateCartMetafield,
}
