export function computeContentDropdownClasses({
  numberOfTiles = 0,
}: {
  numberOfTiles: number
}) {
  if (numberOfTiles < 2) {
    return 'col-span-3'
  } else if (numberOfTiles === 2) {
    return 'col-span-6'
  } else {
    return 'col-span-9'
  }
}
