import { ChangeEvent, FC, useEffect, useState } from 'react'
import useSubscribe from '@framework/customer/use-subscribe'

import { Button } from '@noissue-ui-kit/button'
import { THubspotFormSpecification } from '@utils/form/hubspot/schema'
import { TConsentOptions } from '@utils/form/schema'
import Link from 'next/link'
import {
  formValuesToHubspotPayload,
  MARKETING_FORM_API,
  RESERVED_CONSENT_FIELD_NAME,
} from '@utils/hubspot'
import { Notification } from '@noissue-ui-kit/notification'
import * as Sentry from '@sentry/nextjs'

const SignUp: FC<{
  signupForm: {
    hubspotId: string
    formSpec: THubspotFormSpecification
    consentSpec: TConsentOptions
  }
}> = ({ signupForm = null }) => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [showError, setShowError] = useState<boolean>(false)
  const subscribe = useSubscribe()
  const [loading, setLoading] = useState(false)
  const [successfullySubscribed, setSuccessfullySubscribed] = useState(false)

  const changeEmail = ({ target }: ChangeEvent<HTMLInputElement>) =>
    setEmail(target.value)

  const submitHubspotSignUpForm = async (email) => {
    const hiddenFields =
      signupForm.formSpec.fieldGroups
        ?.map((x) => x.fields?.filter((x) => x.hidden))
        ?.flat()
        ?.reduce((acc, field) => {
          acc[field?.name] = field.defaultValue
          return acc
        }, {}) || {}

    const formPayload = formValuesToHubspotPayload({
      formValues: {
        email,
        // have to includes hidden fields if present since we don't render the form
        ...hiddenFields,
        // Subscribing user by default since the form main and only purpose is subscription and we are explicit about it
        [RESERVED_CONSENT_FIELD_NAME]: true,
      },
      formId: signupForm?.hubspotId,
      consentOptions: signupForm?.consentSpec,
    })

    await fetch(MARKETING_FORM_API, {
      method: 'POST',
      body: JSON.stringify(formPayload),
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  const clickSignUp = async () => {
    setSuccessfullySubscribed(false)
    setError('')

    if (!email.trim()) {
      setError('The email field is required')
      return
    }

    if (!/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/.test(email)) {
      setError('Email is not valid')
      return
    }

    try {
      setLoading(true)
      await submitHubspotSignUpForm(email)
      await subscribe(email, 'custom')
      setSuccessfullySubscribed(true)
    } catch (err) {
      setError('Something went wrong! Please try later.')

      Sentry.captureException(
        new Error(
          `Footer Signup Widget: subscription request failed for customer: ${email}. Error: ${
            typeof err === 'string'
              ? err
              : err?.message || 'something went wrong'
          }`
        )
      )
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setShowError(Boolean(error))
  }, [error])

  if (!signupForm) {
    return null
  }

  return (
    <div className="grid grid-cols-2 grid-rows-2 gap-4 lg:gap-0 px-8 md:px-0 xl:px-10 xl:py-10 xl:border-2 xl:border-black-10 xl:rounded-[90px] my-8">
      <p
        className="col-span-2 lg:col-span-1 row-span-1
        flex flex-wrap w-[-webkit-fill-available] items-center justify-start text-acai-100 text-base-16 leading-2 lg:mb-0 lg:flex-row mx-0 lg:mx-8"
      >
        <span>Join us! Tips, tricks and more.&nbsp;</span>
        <span className="font-bold">#NoSpam #JustInspo</span>
      </p>

      <p className="col-span-2 lg:col-span-1 row-span-1 col-start-1 text-black-80 text-xl mx-0 lg:mx-8 text-pretty">
        By subscribing you will receive marketing from noissue. See
        <Link
          href="/privacy-policy/?tab=privacy"
          className="text-boulder hover:text-pitaya"
        >
          &nbsp;Privacy Policy
        </Link>
      </p>

      <div className="col-span-2 lg:col-span-1 row-span-2 col-start-1 lg:col-start-2 row-start-3 lg:row-start-1 flex flex-col w-full gap-6 lg:flex-row items-start">
        <input
          type="text"
          name="email"
          id="email"
          className="flex w-full lg:w-136 ml-0 lg:ml-auto h-full max-h-[54px] px-8 py-6 text-gray-600 border-2 border-black-20 rounded-[56px] shadow-sm focus:ring-pitaya-100 focus:border-pitaya-100 lg:py-2 text-xl md:text-2xl lg:mb-0 placeholder:text-black-40"
          placeholder="hello@thisismyemail.com"
          onChange={changeEmail}
        />

        <Notification
          message={error}
          size="medium"
          variant="warning"
          opened={showError}
          close={() => setShowError(null)}
          position="bottomRight"
        />

        <Button
          onClick={clickSignUp}
          variant="primary"
          size="l"
          corners="rounded"
          colour={successfullySubscribed ? 'starfruit' : 'pitaya'}
          className={`flex justify-center w-full lg:w-auto ${
            successfullySubscribed ? 'pointer-events-none' : ''
          }`}
          loading={loading}
        >
          {successfullySubscribed ? 'Subscribed!' : 'Subscribe'}
        </Button>
      </div>
    </div>
  )
}

export default SignUp
