import React, { ReactNode, useCallback, useMemo, useState } from 'react'
import { ContentfulNextJsImage } from '@components/common/ContentfulImage'
import ClickOutside from '@utils/click-outside'
import { ContentfulMarkdown } from '@utils/contentful/ContentfulMarkdown'
import Image from 'next/image'
import classNames from 'classnames'

enum BadgeVariant {
  Attention = 'attention',
  Success = 'success',
  Error = 'error',
  Info = 'info',
  MutedInfo = 'muted-info',
}
enum BadgeSize {
  XS = 'xs',
  S = 's',
  M = 'm',
  L = 'l',
}
enum BorderType {
  Rounded = 'rounded',
  SquareRound = 'square-round',
  Square = 'square',
}

export type TBadgeAlertSize = `${BadgeSize}`
export type TBadgeAlertVariant = `${BadgeVariant}`
export type TBadgeBorderType = `${BorderType}`

export const BadgeAlertMessage = ({
  size = BadgeSize.M,
  variant = BadgeVariant.Info,
  className,
  borderType = BorderType.Rounded,
  badge,
  customBadgeSize = null,
  hintText = '',
  hintIcon,
  ctaUrl,
  children,
}: {
  size: TBadgeAlertSize
  variant?: TBadgeAlertVariant
  className?: string
  borderType?: TBadgeBorderType
  badge: { url: string; alt: string }
  customBadgeSize?: {
    width: number
    height: number
  }
  hintText?: string
  hintIcon?: { url: string; alt: string }
  ctaUrl?: string
  children: ReactNode
}) => {
  const [hintIsOpen, setHintIsOpen] = useState(false)
  const hasCta = Boolean(ctaUrl)

  if (!children) {
    return null
  }

  const sizeToClass: Record<TBadgeAlertSize, string> = {
    [BadgeSize.XS]: `py-3 ${hintText && 'pr-5'}`,
    [BadgeSize.S]: `py-5 ${hintText && 'pr-5'}`,
    [BadgeSize.M]: `py-5 ${hintText && 'pr-5'}`,
    [BadgeSize.L]: `py-5 ${hintText && 'pr-5'}`,
  }
  const variantToClass: Record<TBadgeAlertVariant, string> = {
    [BadgeVariant.Error]: 'bg-pitaya-20',
    [BadgeVariant.Attention]: 'bg-unbleached-60',
    [BadgeVariant.Info]: 'bg-unbleached-60',
    [BadgeVariant.Success]: 'bg-starfruit-20',
    [BadgeVariant.MutedInfo]: 'bg-black-10',
  }
  const borderClassMapper: Record<TBadgeBorderType, string> = {
    [BorderType.Rounded]: 'rounded-full',
    [BorderType.SquareRound]: 'rounded-[8px]',
    [BorderType.Square]: 'rounded-none',
  }

  const sizeToImageStyles: Record<
    TBadgeAlertSize,
    {
      imageSize: number
      class: string
    }
  > = {
    [BadgeSize.XS]: { imageSize: 24, class: 'w-[24px] h-[24px]' },
    [BadgeSize.S]: { imageSize: 32, class: 'w-12 h-12' },
    [BadgeSize.M]: { imageSize: 40, class: 'w-16 h-16' },
    [BadgeSize.L]: { imageSize: 50, class: 'w-20 h-20' },
  }

  const textSizeMapper: Record<TBadgeAlertSize, string> = {
    [BadgeSize.XS]: 'text-lg',
    [BadgeSize.S]: 'text-lg',
    [BadgeSize.M]: 'text-xl',
    [BadgeSize.L]: 'text-2xl',
  }
  const textContainerStyles: Record<
    TBadgeBorderType,
    Record<TBadgeAlertSize, string>
  > = {
    [BorderType.Rounded]: {
      [BadgeSize.XS]: `py-2 pl-4 pr-8`,
      [BadgeSize.S]: 'py-3 pl-4 pr-10',
      [BadgeSize.M]: 'py-4 pl-6 pr-12',
      [BadgeSize.L]: 'py-4 pl-6 pr-16',
    },
    [BorderType.SquareRound]: {
      [BadgeSize.XS]: 'pt-2 pb-1 pl-3 pr-8',
      [BadgeSize.S]: 'py-3 pl-5 pr-10',
      [BadgeSize.M]: 'py-4 pl-6 pr-12',
      [BadgeSize.L]: 'py-4 pl-6 pr-16',
    },
    [BorderType.Square]: {
      [BadgeSize.XS]: 'py-2 pl-3 pr-8',
      [BadgeSize.S]: 'py-3 pl-5 pr-10',
      [BadgeSize.M]: 'py-4 pl-6 pr-12',
      [BadgeSize.L]: 'py-4 px-6 pr-16',
    },
  }

  const handleBadgeClick = useCallback(() => {
    if (typeof window !== 'undefined' && ctaUrl) {
      window.open(ctaUrl, '_blank')
    }
  }, [ctaUrl])

  const badgeContainerClasses = useMemo(
    () =>
      classNames(
        'relative flex items-center text-black',
        { 'cursor-pointer': hasCta },
        borderClassMapper[borderType],
        sizeToClass[size],
        variantToClass[variant],
        className
      ),
    [ctaUrl, size, variant, borderType, className]
  )

  const renderBadgeImage = () =>
    badge?.url?.includes('ctfassets') ? (
      <ContentfulNextJsImage
        alt={badge?.alt}
        src={badge?.url}
        quality={90}
        width={customBadgeSize?.width || sizeToImageStyles[size]?.imageSize}
        height={customBadgeSize?.height || sizeToImageStyles[size]?.imageSize}
        format="avif"
        objectFit="contain"
        aria-hidden="true"
        loading="lazy"
      />
    ) : (
      <Image
        src={badge?.url}
        alt={badge?.alt}
        width={customBadgeSize?.width || sizeToImageStyles[size]?.imageSize}
        height={customBadgeSize?.height || sizeToImageStyles[size]?.imageSize}
        aria-hidden="true"
        loading="lazy"
      />
    )

  const HintToggle = () => (
    <button
      className={classNames(
        'h-10 w-10 rounded-full flex items-center justify-center',
        { 'pointer-events-none': !hintText },
        { 'bg-unbleached': hintIsOpen },
        { 'bg-starfruit': !hintIsOpen }
      )}
      onClick={() => setHintIsOpen(!hintIsOpen)}
    >
      {hintIcon?.url?.includes('ctfassets') ? (
        <ContentfulNextJsImage
          alt={hintIcon?.alt}
          src={hintIcon?.url}
          height={16}
          width={16}
          quality={70}
          format="avif"
        />
      ) : (
        <Image
          src={hintIcon?.url}
          alt={hintIcon?.alt}
          width="16"
          height="16"
          className={`${hintIsOpen ? 'text-black' : 'text-black-80'}`}
        />
      )}
    </button>
  )

  const HintContainer = () => (
    <div className="absolute bottom-[calc(100%-3px)] right-5 w-[270px] bg-white px-6 py-3 rounded-lg shadow-md">
      <ContentfulMarkdown
        overrideClass="text-boulder text-lg font-mori"
        overrideStyles={{
          a: ({ children, href }) => (
            <a
              className="text-pitaya text-lg font-mori"
              href={href}
              target="_blank"
              rel="noopener noreferrer"
            >
              {` ${children} `}
            </a>
          ),
        }}
      >
        {hintText}
      </ContentfulMarkdown>
    </div>
  )

  return (
    <div className={badgeContainerClasses} onClick={handleBadgeClick}>
      <div className={`relative shrink-0 flex items-center ml-6`}>
        {renderBadgeImage()}
      </div>

      <div
        className={classNames(
          'flex items-center w-full font-mori font-normal text-left',
          textContainerStyles[borderType][size],
          textSizeMapper[size]
        )}
      >
        {children}
      </div>

      {hintText && (
        <ClickOutside active={hintIsOpen} onClick={() => setHintIsOpen(false)}>
          <div className="ml-auto">
            <HintToggle />

            {hintIsOpen && <HintContainer />}
          </div>
        </ClickOutside>
      )}
    </div>
  )
}
