import { trackNavigationClick } from '@utils/gtm'
import { useRouter } from 'next/router'
import React from 'react'
import { TSelectionMenu } from '../../Navbar.types'
import {
  TBreadcrumb,
  useTrackingBreadcrumbs,
} from '@utils/hooks/useTrackingBreadcrumbs'

export function SelectionMenu({
  selectionMenu,
  className,
  breadcrumbs,
}: {
  selectionMenu: TSelectionMenu
  className: string
  breadcrumbs?: TBreadcrumb[]
}) {
  const router = useRouter()
  const { saveBreadcrumbs } = useTrackingBreadcrumbs()

  return (
    <div className={className}>
      <a
        className="block mb-[16px] font-semibold text-[14px] text-black-60 whitespace-pre-line font-mori"
        href={selectionMenu?.selectionMenuLink}
        onClick={() => {
          if (selectionMenu?.selectionMenuLink) {
            trackNavigationClick({
              clickLocation: 'Selection Title',
              clickItem: selectionMenu?.selectionMenuTitle,
            })

            saveBreadcrumbs(
              breadcrumbs.concat({
                name: selectionMenu.selectionMenuTitle,
                url: selectionMenu.selectionMenuLink,
              })
            )

            router.push(selectionMenu?.selectionMenuLink).catch(() => {})
          }
        }}
      >
        {selectionMenu?.selectionMenuTitle}
      </a>
      <ul>
        {selectionMenu?.selectionMenuItems?.map((selectionMenuItem, index) => {
          return (
            <li
              className={`font-mori block text-[14px] text-boulder mb-[4px] transition-colors duration-300 leading-[20px] ${
                selectionMenuItem?.link ? 'hover:text-pitaya' : ''
              }`}
              key={index}
            >
              <a
                href={selectionMenuItem?.link}
                onClick={() => {
                  if (selectionMenuItem?.link) {
                    trackNavigationClick({
                      clickLocation: 'Selection Item',
                      clickItem: selectionMenuItem?.label,
                    })

                    const selectionBreadcrumbs = []
                    if (selectionMenu?.selectionMenuTitle) {
                      selectionBreadcrumbs.push({
                        name: selectionMenu.selectionMenuTitle,
                        url: selectionMenu.selectionMenuLink,
                      })
                    }
                    selectionBreadcrumbs.push({
                      name: selectionMenuItem.label,
                      url: selectionMenuItem.link,
                    })

                    saveBreadcrumbs(breadcrumbs.concat(selectionBreadcrumbs))

                    router.push(selectionMenuItem?.link).catch(() => {})
                  }
                }}
                className="whitespace-nowrap"
              >
                {selectionMenuItem?.label}
              </a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
