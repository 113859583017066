import { Button } from '../button'
import React, { ReactNode, useState } from 'react'
import { HiCheck, HiX } from 'react-icons/hi'
import { BadgeAlertMessage } from '@noissue-ui-kit/badgeAlertMessage'
import NellyRecommendsLogo from '../../public/common/nellie-outlined-icon.svg'

interface IModalProps {
  size: 'extra-small' | 'small' | 'medium' | 'large'
  title?: string
  subtitle?: string
  cancelLabel?: string
  confirmLabel?: string
  onConfirm?: (() => void) | (() => Promise<any>)
  confirmButtonDisabled?: boolean
  confirmButtonLoadingState?: boolean
  onCancel?: () => void
  showSuccessBadge?: boolean
  children?: ReactNode
  opened: boolean
  className?: string
  coverClassName?: string
  showControls?: {
    confirmButton: boolean
    cancelButton: boolean
    cancelIcon: boolean
  }
}

export const Modal = ({
  size,
  title = '',
  subtitle = '',
  cancelLabel = 'Close',
  confirmLabel = 'Confirm',
  onConfirm = () => {},
  onCancel = () => {},
  children = null,
  opened,
  className = '',
  coverClassName = '',
  showSuccessBadge = false,
  showControls = {
    confirmButton: true,
    cancelButton: true,
    cancelIcon: true,
  },
  confirmButtonDisabled = false,
  confirmButtonLoadingState = false,
}: IModalProps) => {
  const sizeToClassMapper = {
    'extra-small': 'sm:w-[400px] w-full',
    small: 'sm:w-[500px] w-full',
    medium: 'sm:w-[600px] w-full',
    large: 'sm:w-[800px] w-full',
  }
  const [submittingErrorMessage, setSubmittingErrorMessage] = useState('')

  return (
    <>
      <div
        className={`${coverClassName} fixed top-0 bottom-0 left-0 right-0 z-modal-overlay bg-acai font-mori ${
          opened ? 'visible opacity-70' : 'invisible opacity-0 hidden'
        } transition-all sm:duration-200 duration-400`}
        onClick={
          showControls.cancelButton || showControls.cancelIcon
            ? onCancel
            : () => {}
        }
      />

      <div
        className={`flex flex-col font-mori ${className} ${
          sizeToClassMapper[size]
        } ${
          opened
            ? 'sm:opacity-100 sm:visible sm:bottom-[unset] bottom-0 block'
            : 'sm:opacity-0 sm:invisible sm:bottom-[unset] -bottom-full hidden'
        } transition-all sm:duration-300 duration-[800ms] fixed p-12 sm:p-16
          sm:rounded-3xl rounded-b-0 rounded-t-3xl bg-core-white min-h-auto max-h-[calc(96%)] md:max-h-[calc(90%)] z-modal
          sm:shadow-modal -translate-x-1/2 sm:-translate-y-1/2 sm:top-1/2 left-1/2
        `}
      >
        {showSuccessBadge && (
          <div className="flex items-center justify-center w-[80px] h-[80px] rounded-full bg-core-tree-90 absolute top-0 left-1/2 -translate-y-1/2 -translate-x-1/2">
            <HiCheck className="w-20 h-20 text-white" />
          </div>
        )}

        <div
          className={`flex-none flex flex-row items-center ${
            subtitle ? 'pb-4' : 'pb-8'
          }`}
        >
          <h3 className="flex-1 text-[28px] font-semibold leading-[34px] text-pitaya">
            {title}
          </h3>
          {showControls?.cancelIcon && (
            <HiX className="w-10 h-10 cursor-pointer" onClick={onCancel} />
          )}
        </div>

        {subtitle && (
          <div className="flex-none">
            <p className="text-2xl leading-8 text-boulder">{subtitle}</p>
          </div>
        )}

        {submittingErrorMessage && (
          <BadgeAlertMessage
            badge={{ url: NellyRecommendsLogo, alt: 'Error message' }}
            variant={'error'}
            size={'s'}
            className="mb-8"
          >
            {submittingErrorMessage}. Please try again
          </BadgeAlertMessage>
        )}
        <div className="flex-1 overflow-y-auto">{children}</div>

        {(showControls?.cancelButton || showControls?.confirmButton) && (
          <div className="flex flex-col flex-none w-full mt-12 sm:flex-row">
            {showControls.cancelButton && (
              <Button
                variant="outline"
                size="s"
                corners="rounded"
                className={`order-1 sm:order-0 ${
                  showControls?.confirmButton
                    ? 'w-full sm:mr-4'
                    : 'w-full sm:w-80 sm:ml-auto'
                }`}
                onClick={onCancel}
              >
                {cancelLabel}
              </Button>
            )}

            {showControls?.confirmButton && (
              <Button
                size="s"
                corners="rounded"
                onClick={() => {
                  ;(onConfirm() as Promise<any>)
                    ?.then(() => {})
                    ?.catch((e) => {
                      setSubmittingErrorMessage(e.message ? e.message : e)
                    })
                }}
                className={`mb-4 order-0 sm:order-1 sm:mb-0 ${
                  showControls?.cancelButton
                    ? 'w-full'
                    : 'w-full sm:w-80 ml-auto'
                }`}
                disabled={confirmButtonDisabled}
                loading={confirmButtonLoadingState}
              >
                {confirmLabel}
              </Button>
            )}
          </div>
        )}
      </div>
    </>
  )
}
