import { SWRHook } from '@commerce/utils/types'
import useCustomer, { UseCustomer } from '@commerce/customer/use-customer'
import type { Customer, CustomerData } from '../api/customers'
import Cookie from 'js-cookie'
import { COOKIE_DATALAYER } from '@utils/constants'
import isEmpty from 'lodash/isEmpty'
import escape from 'lodash/escape'
import unescape from 'lodash/unescape'

export default useCustomer as UseCustomer<typeof handler>

const maxAge = 5 * 60 // 5 minutes

export const handler: SWRHook<Customer | null> = {
  fetchOptions: {
    url: '/api/bigcommerce/customers/',
    method: 'GET',
  },
  async fetcher({ options, fetch }) {
    // Cache cookie to avoid hammering services
    const cachedData = Cookie.get(COOKIE_DATALAYER)
    if (!cachedData) {
      const data = await fetch<CustomerData | null>(options)
      const customer = data?.customer ?? null
      if (!isEmpty(customer)) {
        Cookie.set(
          COOKIE_DATALAYER,
          btoa(unescape(encodeURIComponent(JSON.stringify(customer)))),
          {
            expires: new Date(Date.now() + maxAge * 1000),
          }
        )
      }
      return customer
    } else {
      return JSON.parse(decodeURIComponent(escape(atob(cachedData))))
    }
  },
  useHook:
    ({ useData }) =>
    (input) => {
      return useData({
        swrOptions: {
          revalidateOnFocus: false,
          ...input?.swrOptions,
        },
      })
    },
}
