import s from './Sidebar.module.css'
import cn from 'classnames'
import Portal from '@reach/portal'
import { FC, PropsWithChildren, useEffect, useRef } from 'react'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'

interface Props {
  children: any
  open: boolean
  upsellSidebar?: boolean
  onClose: () => void
}

const Sidebar: FC<PropsWithChildren<Props>> = ({
  children,
  open = false,
  upsellSidebar,
  onClose,
}) => {
  const ref = useRef() as React.MutableRefObject<HTMLDivElement>

  useEffect(() => {
    if (ref.current) {
      if (open) {
        disableBodyScroll(ref.current)
      } else {
        enableBodyScroll(ref.current)
      }
    }
    return () => {
      clearAllBodyScrollLocks()
    }
  }, [open])

  return (
    <Portal>
      {open ? (
        <div
          className={cn(
            'fixed inset-0 h-full overflow-hidden z-above-zendesk-widget',
            s.cdCartSideBar
          )}
          ref={ref}
        >
          <div
            className={cn(
              s.cdAbsolute,
              'absolute inset-0 bg-black opacity-50 transition-opacity'
            )}
            onClick={onClose}
          />
          <div
            className={cn(
              s.sidebarminipanel,
              upsellSidebar && s.upsellSectionActive,
              open && s.active
            )}
          >
            {children}
          </div>
        </div>
      ) : null}
    </Portal>
  )
}

export default Sidebar
