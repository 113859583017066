import { FC } from 'react'
import cn from 'classnames'
import s from './CartSidebarPopupView.module.css'
import { Button } from '@components/ui'
import { useUI } from '@components/ui/context'
import { Cross } from '@components/icons'
import useCart from '@framework/cart/use-cart'
import usePrice from '@framework/product/use-price'
import type { LineItem } from '@framework/types'
import { trackCheckout, trackCheckoutStep } from '@utils/gtm'
import {
  currencySymbol,
  NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_CURRENCY,
} from '@utils/client-side-config'
import { useCommerce } from '@commerce'

const countItem = (count: number, item: LineItem) => count + item.quantity
interface Props {
  cartUpsellData?: any
  cartSidebarData?: any
}

const CartSidebarPopupView: FC<Props> = ({
  cartSidebarData,
  cartUpsellData,
}) => {
  const { closePopupSidebar, togglePopupSidebar } = useUI()
  const { data, isLoading, isEmpty } = useCart()
  const itemsCount = data?.lineItems.reduce(countItem, 0) ?? 0
  const { locale } = useCommerce()

  const { price: subTotal } = usePrice(
    data && {
      amount: Number(data.subtotalPrice),
      currencyCode: data.currency.code,
      locale,
    }
  )
  const { price: total } = usePrice(
    data && {
      amount: Number(data.totalPrice),
      currencyCode: data.currency.code,
      locale,
    }
  )
  const handleClose = () => closePopupSidebar()

  const getCartUpsellData = cartUpsellData?.fields.cartPopupSidebar?.fields
  if (getCartUpsellData == undefined) {
    // If popup data not added for current opened productthant popupsidebar will close
    closePopupSidebar()
  }

  const error = null
  const success = null

  return (
    <div
      className={cn(s.root, {
        [s.empty]: error || success || isLoading || isEmpty,
      })}
    >
      <header className={s.slidebarPopupHeader}>
        <div className={s.cartIcon}>
          <nav>
            <div>
              <ul className={s.list}>
                <li className={s.cartIconLi} onClick={togglePopupSidebar}>
                  <img
                    className={s.cartIconImage}
                    alt={cartSidebarData.fields.cartIconImage.fields.title}
                    src={cartSidebarData.fields.cartIconImage.fields.file.url}
                  />
                  <span className={s.bagCount}>{itemsCount}</span>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <div className={s.totalPrice}>
          <span>
            {subTotal ||
              `${
                data?.currency?.code ||
                currencySymbol[NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_CURRENCY] ||
                '$'
              }0.00`}
          </span>
        </div>
        <div className={s.closeButtonArea}>
          <button onClick={handleClose} className={s.closeBtn}>
            <Cross width={24} height={24} />
          </button>
        </div>
      </header>

      {isLoading || isEmpty ? (
        <div className={s.emtryArea}>
          <h2 className={s.emptytext}>
            {cartSidebarData.fields.cartEmptyText}
          </h2>
        </div>
      ) : (
        <>
          <div className={s.opupContentArea}>
            <div className={s.container}>
              <div className={s.popupSidebarDetail}>
                <div className={s.productHeading}>
                  {getCartUpsellData?.heading}
                </div>
                <div className={s.productSubHeading}>
                  {getCartUpsellData?.subHeading.content &&
                    getCartUpsellData?.subHeading.content.map(
                      (item: any, index: number) => {
                        return (
                          <div key={index}>
                            {item.content.map(
                              (contentsItem: any, contentsIndex: number) => {
                                return contentsItem.marks[0] ? (
                                  <b key={contentsIndex}>
                                    {contentsItem.value}
                                  </b>
                                ) : (
                                  <span key={contentsIndex}>
                                    {contentsItem.value}
                                  </span>
                                )
                              }
                            )}
                          </div>
                        )
                      }
                    )}
                </div>
                <div className={s.productImage}>
                  <img
                    className={s.cartIconImage}
                    alt={getCartUpsellData?.image.fields.title}
                    src={getCartUpsellData?.image.fields.file.url}
                  />
                </div>
                <div className={s.productInfo}>
                  <div className={s.productName}>
                    {getCartUpsellData?.productName}
                  </div>
                  <div className={s.productPrice}>
                    {getCartUpsellData?.productPrice}
                  </div>
                </div>
                <div className={s.productButtons}>
                  <a
                    className={s.productBtn}
                    href={getCartUpsellData?.productButtonLink}
                  >
                    {getCartUpsellData?.productButtonLabel}
                  </a>
                  <a className={s.noThanksBtn} onClick={handleClose}>
                    {getCartUpsellData?.noThanksButtonLabel}
                  </a>
                </div>
              </div>
              <div className={s.checkoutrow}>
                <Button
                  className={s.checkoutBtn}
                  href="/checkout/"
                  Component="a"
                  width="100%"
                  onClick={() => {
                    trackCheckout(data)
                    trackCheckoutStep(data)
                  }}
                >
                  {cartSidebarData.fields.checkoutButtonLabel}
                  <span className={s.checkoutBtnIcon}></span>
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default CartSidebarPopupView
