import { createClient } from 'contentful'
import parentLogger from '../logger'

const logger = parentLogger.child({ name: 'utils/contentful/clients.ts' })

export enum CONTENTFUL_SPACE_IDS {
  ECOMMERCE_1 = '5gvckmvm9289',
  ECOMMERCE_2 = 'g07f8zx4h2ob',
}

function requestLogger(config) {
  if (!config) {
    return
  }

  let stack = []
  try {
    const obj = {
      stack: '',
    }
    Error.captureStackTrace(obj)
    stack = obj.stack
      .split('\n')
      .filter(
        (line) =>
          line &&
          (line.includes('/server') || line.includes('./')) &&
          !line.includes('node_modules') &&
          !line.includes('requestLogger')
      )
      .map((line) => {
        const matches = line.match(/.*?(\w+)\s\((.*)\)/)
        return matches ? `${matches[1]} at ${matches[2]}` : line
      })
  } catch (e) {
    stack.push(`${e.message}`)
  }

  logger.debug(
    { url: config.url, params: config.params, stack },
    'contentful request'
  )
}

export const ecommerceContentfulClient = createClient({
  space: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID
    ? process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID
    : '',
  accessToken: process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN
    ? process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN
    : '',
  environment: process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT,
  removeUnresolved: true,
  // @ts-ignore this is a documented option, but Contentful have not updated the typedefs
  requestLogger,
})

export const ecommerceContentfulPreviewClient = createClient({
  space: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID
    ? process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID
    : '',
  accessToken: process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_ACCESS_TOKEN
    ? process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_ACCESS_TOKEN
    : '',
  environment: process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT,
  host: 'preview.contentful.com',
  // @ts-ignore this is a documented option, but Contentful have not updated the typedefs
  requestLogger,
})

export const ecommerce2ContentfulClient = createClient({
  space: process.env.NEXT_PUBLIC_CONTENTFUL_ECOMMERCE_2_SPACE_ID
    ? process.env.NEXT_PUBLIC_CONTENTFUL_ECOMMERCE_2_SPACE_ID
    : '',
  accessToken: process.env.NEXT_PUBLIC_CONTENTFUL_ECOMMERCE_2_ACCESS_TOKEN
    ? process.env.NEXT_PUBLIC_CONTENTFUL_ECOMMERCE_2_ACCESS_TOKEN
    : '',
  environment: process.env.NEXT_PUBLIC_CONTENTFUL_ECOMMERCE_2_ENVIRONMENT,
  removeUnresolved: true,
  // @ts-ignore this is a documented option, but Contentful have not updated the typedefs
  requestLogger,
})

export const ecommerce2ContentfulPreviewClient = createClient({
  space: process.env.NEXT_PUBLIC_CONTENTFUL_ECOMMERCE_2_SPACE_ID
    ? process.env.NEXT_PUBLIC_CONTENTFUL_ECOMMERCE_2_SPACE_ID
    : '',
  accessToken: process.env
    .NEXT_PUBLIC_CONTENTFUL_ECOMMERCE_2_PREVIEW_ACCESS_TOKEN
    ? process.env.NEXT_PUBLIC_CONTENTFUL_ECOMMERCE_2_PREVIEW_ACCESS_TOKEN
    : '',
  environment: process.env.NEXT_PUBLIC_CONTENTFUL_ECOMMERCE_2_ENVIRONMENT,
  host: 'preview.contentful.com',
  // @ts-ignore this is a documented option, but Contentful have not updated the typedefs
  requestLogger,
})

export const getContentfulClient = ({
  contentfulSpace,
  preview = false,
}: {
  contentfulSpace:
    | CONTENTFUL_SPACE_IDS.ECOMMERCE_1
    | CONTENTFUL_SPACE_IDS.ECOMMERCE_2
  preview?: boolean
}) => {
  if (preview) {
    switch (contentfulSpace) {
      case CONTENTFUL_SPACE_IDS.ECOMMERCE_1:
        return ecommerceContentfulPreviewClient
      case CONTENTFUL_SPACE_IDS.ECOMMERCE_2:
        return ecommerce2ContentfulPreviewClient
    }
  } else {
    switch (contentfulSpace) {
      case CONTENTFUL_SPACE_IDS.ECOMMERCE_1:
        return ecommerceContentfulClient
      case CONTENTFUL_SPACE_IDS.ECOMMERCE_2:
        return ecommerce2ContentfulClient
    }
  }
}

export const getContentfulClients = (preview = false) => {
  return [
    {
      spaceId: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID,
      client: getContentfulClient({
        contentfulSpace: CONTENTFUL_SPACE_IDS.ECOMMERCE_1,
        preview,
      }),
    },
    {
      spaceId: process.env.NEXT_PUBLIC_CONTENTFUL_ECOMMERCE_2_SPACE_ID,
      client: getContentfulClient({
        contentfulSpace: CONTENTFUL_SPACE_IDS.ECOMMERCE_2,
        preview,
      }),
    },
  ]
}
