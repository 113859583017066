import React, { FC } from 'react'
import Link from '@components/common/NoPrefetchLink'
import { IFooterPage } from './Pages'

import {
  FooterBanner,
  IFooterBanner,
} from '@components/common/Footer/FooterBanner'

const Medium: FC<{
  pages: IFooterPage[]
  footerBannerContent: IFooterBanner
}> = ({ pages, footerBannerContent }) => {
  return (
    <div className="justify-center hidden grid-flow-row grid-cols-3 gap-4 gap-y-20 text-boulder md:grid xl:grid-cols-6 xl:grid-rows-2 xl:gap-y-0">
      {pages?.map((category, index) => (
        <div
          key={category.slug}
          className={`flex flex-col ${
            index > 3 ? 'row-span-1' : 'row-span-2'
          } ${category?.pages?.length > 10 ? 'col-span-2' : ''}`}
        >
          <div className="mb-5 text-xl font-bold uppercase text-acai-100 hover:text-pitaya-100">
            <Link href={category?.slug || '/'}>
              <a>{category?.text}</a>
            </Link>
          </div>

          <div
            className={`${
              category?.pages?.length > 10
                ? 'grid grid-cols-2'
                : 'flex flex-col'
            }`}
          >
            {category?.pages?.map(({ Component, text, slug }, index) => (
              <Link key={`${slug}-${index}`} href={slug || '/'} passHref>
                {Component ? (
                  <a>
                    <Component className="block h-16 w-36 hover:text-pitaya-100" />
                  </a>
                ) : (
                  <a className="block text-xl h-11 hover:text-pitaya-100">
                    {text}
                  </a>
                )}
              </Link>
            ))}
          </div>
        </div>
      ))}
      <div className="hidden row-start-[3] col-start-[-1] col-end-[-3] xl:block">
        <FooterBanner {...footerBannerContent} className="p-5" />
      </div>
    </div>
  )
}

export default Medium
