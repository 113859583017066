import { FC } from 'react'
import Link from '@components/common/NoPrefetchLink'
import { Accordion, AccordionItem } from '@noissue-ui-kit/oldBranding/accordion'
import { IFooterPage } from './Pages'

const Small: FC<{ pages: IFooterPage[] }> = ({ pages }) => (
  <div className="flex flex-col justify-center mx-8 md:hidden ">
    <Accordion initialMode="hideAll">
      {pages?.map((category, idx) => (
        <AccordionItem
          key={category?.slug}
          label={
            <Link href={category?.slug || '/'}>
              <a
                className="py-2 text-xl font-bold uppercase text-acai-100 h-11 hover:text-acai-120"
                onClick={() => {
                  if (category.text.toLowerCase().includes('inspo')) {
                    window.location.href = category?.slug || '/'
                  }
                }}
              >
                {category.text}
              </a>
            </Link>
          }
          className={`border-forest-5 border-b-0 ${
            idx === 0 ? 'border-t-0' : 'border-t'
          }`}
        >
          <div className="flex flex-col gap-[8px] w-screen mb-12">
            {category?.pages?.map(({ Component, text, slug }, index) => (
              <Link key={`${slug}-sm-${index}`} href={slug || '/'}>
                <a
                  className={`flex items-center w-screen text-[14px] text-boulder text-left hover:text-pitaya-100`}
                >
                  {Component ? <Component className="h-16 mt-4 w-36" /> : text}
                </a>
              </Link>
            ))}
          </div>
        </AccordionItem>
      ))}
    </Accordion>
  </div>
)

export default Small
