import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import concat from 'lodash/concat'

/**
 * Return the lineItems for displaying in order detail page and the invoice pdf
 * @param salesOrder
 * @return the raw lineItems
 */
export const getRawLineItemsBelongToSaleOrders = (salesOrder) => {
  // Before WIL-380, lineItems had been saved at root
  const lineItems = get(salesOrder, 'lineItems', [])
  if (!isEmpty(lineItems)) {
    return lineItems
  }
  // After WIL-380, we removed the lineItems at root of salesOrder
  return (salesOrder.parentEcommerceOrder.lineItems ?? []).filter(
    (lineItem) => {
      const itemCode = get(lineItem, 'code', '')
      if (isEmpty(itemCode)) {
        return false
      }
      // WIL-404 sometime salesOrderId is null, use salesOrderRef for more reliable lookup value
      if (lineItem.salesOrderRef) {
        return lineItem.salesOrderRef === salesOrder.reference
      }
      // For supporting old order which was created before WIL-404
      return lineItem.salesOrderId === salesOrder.salesOrderId
    }
  )
}

/**
 * Return the lineItems for displaying in order detail page and the invoice pdf
 * @param salesOrder
 * @return the linkedPOs and stock products
 */
export const getLineItemsBelongToSaleOrders = (salesOrder) => {
  const linkedPOs = salesOrder.linkedPurchaseOrders || []
  const linkedPOCodes = linkedPOs
    .filter((po) => !!po)
    .map((po) => po.sku)
    .filter((sku) => !!sku && sku !== '')
  const otherLineItems = getRawLineItemsBelongToSaleOrders(salesOrder).filter(
    (lineItem) => {
      const itemCode = get(lineItem, 'code', '')
      return !linkedPOCodes.includes(itemCode)
    }
  )
  return concat(linkedPOs, otherLineItems)
}
