import { getProductVariantsSales } from '@framework/lib/getProductVariantSales'
import { ProductVariant } from '@pageFeatures/design/designUpload/types'
import { useEffect, useState } from 'react'

export function useProductVariantsWithPrice({
  productSlug,
  fetch,
}: {
  productSlug: string
  fetch: boolean
}) {
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string>(null)
  const [productVariantsWithPrice, setProductVariantsWithPrice] =
    useState<ProductVariant[]>()

  useEffect(() => {
    const getProductVariants = async (productSlug): Promise<void> => {
      setLoading(true)
      try {
        const response = await getProductVariantsSales({
          productSlug: productSlug,
        })
        setProductVariantsWithPrice(response)
      } catch (err) {
        setError(err?.message || 'Something went wrong. Please, try later.')
      } finally {
        setLoading(false)
      }
    }

    if (fetch && !productVariantsWithPrice?.length) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getProductVariants(productSlug)
    }
  }, [productSlug, fetch])

  return {
    productVariantsWithPrice,
    loading,
    error,
  }
}
