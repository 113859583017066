import { ReactNode } from 'react'
import { CgClose } from 'react-icons/cg'
import cn from 'classnames'

export const MobileNavHeader = ({
  children,
  handleMenuToggle,
  className,
}: {
  children: ReactNode
  handleMenuToggle: () => void
  className?: string
}) => {
  return (
    <div
      className={`${cn(
        'sticky top-0 z-1 bg-core-white flex h-[65px] w-full justify-between items-center border-b border-core-grey-20 gap-[20px]',
        className
      )}`}
    >
      {children}
      <CgClose
        className="h-[20px] w-[20px] text-acai cursor-pointer"
        onClick={handleMenuToggle}
      />
    </div>
  )
}
