import React from 'react'
import Image from 'next/legacy/image'

export function CartIcon({ cartCount }: { cartCount: number }) {
  return (
    <div className="w-11 h-11">
      <Image
        alt="Cart"
        className="sm:max-w-none fill-acai"
        src="/images/cart-box.svg"
        height={30}
        width={30}
      />
      <span className="absolute bg-pitaya flex font-bold h-7 items-center justify-center left-7 p-0.5 rounded-full text-core-white -top-1.5 w-7 text-base ">
        {cartCount}
      </span>
    </div>
  )
}
