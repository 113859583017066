import React from 'react'
import Link from '@components/common/NoPrefetchLink'
import Image from 'next/legacy/image'
import { useSession } from 'next-auth/client'
import { UserNavButton } from './UserNavButton'
import { AccountPopup } from './AccountPopup'
import { useRouter } from 'next/router'

export function NellieIcon() {
  return (
    <Image
      height={35}
      width={35}
      src="/images/nellie-white.svg"
      alt="My Account"
    />
  )
}

export function UserNav() {
  const router = useRouter()
  const redirectRouteParametr = router.asPath
  const [session, loading] = useSession()

  if (loading) {
    return null
  }

  return (
    <div className="hidden md:block">
      {session ? (
        <AccountPopup />
      ) : (
        <Link
          href={{
            pathname: '/login',
            query: { redirect_route: redirectRouteParametr },
          }}
        >
          <a className="flex items-center mr-7">
            <span className={`flex rounded-full ring-2 ring-core-grey-lighter`}>
              <UserNavButton />
            </span>
            <span className="ml-4 text-xl font-bold uppercase text-core-rose">
              Log in / Sign Up
            </span>
          </a>
        </Link>
      )}
    </div>
  )
}
