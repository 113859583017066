import {
  CONTENTFUL_SPACE_IDS,
  getContentfulClient,
} from '@utils/contentful/clients'
import { useEffect, useState } from 'react'
import { IProductCategory } from 'types/contentful/productCategory'

export function useProductCategory({
  productSlug,
  fetch,
}: {
  productSlug: string
  fetch: boolean
}) {
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string>(null)
  const [productCategory, setProductCategory] = useState<IProductCategory>(null)

  useEffect(() => {
    const loadProductCategory = async (slug) => {
      setLoading(true)

      if (!slug) {
        return null
      }

      try {
        const contentfulClient = getContentfulClient({
          contentfulSpace: CONTENTFUL_SPACE_IDS.ECOMMERCE_1,
        })
        const productCategoryEntry = await contentfulClient.getEntries({
          content_type: 'productCategory',
          'fields.productSlug': slug,
        })
        const productCategory: IProductCategory = productCategoryEntry?.items[0]
          ?.fields as IProductCategory

        setProductCategory(productCategory)
      } catch (err) {
        setError(err.message || 'Failed to fetch product category.')
      } finally {
        setLoading(false)
      }
    }

    if (fetch) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      loadProductCategory(productSlug)
    }
  }, [fetch, productSlug])

  return {
    loading,
    error,
    productCategory,
  }
}
