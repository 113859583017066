import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import debounce from 'lodash.debounce'
import { HiOutlineSearch } from 'react-icons/hi'
import { FormInput } from '@noissue-ui-kit/form/formInput'
import { useDevice } from '@utils/responsiveness/useDevice'
import { useSearchInput } from '../NavbarDataProvider'
import { useSearchResults } from './useSearchResults'
import { HiXMark } from 'react-icons/hi2'
import { SearchLoadingStateSkeleton } from './searchLoadingStateSkeleton'
import { TSearchItem } from 'pages/api/content/search'
import { SearchResultItem } from './SearchResultItem'
import { KEY_CODES } from '@utils/constants'
import { useSession } from 'next-auth/client'

export function SearchButton() {
  const [showResultTab, setShowResultTab] = useState<boolean>(false)
  const [searchText, setSearchText] = useState<string>('')
  const [session] = useSession()

  const { isMobile } = useDevice()
  const { searchIsActive, setSearchIsActive } = useSearchInput()
  const {
    loading,
    error,
    searchResults,
    foundCategories,
    foundProducts,
    foundOrders,
  } = useSearchResults({
    searchText,
    email: session?.email as string,
  })

  const noResultsWereFound = !foundCategories && !foundProducts && !foundOrders

  const updateValue = useCallback(
    debounce((event) => {
      const searchText = event.target.value
      if (searchText.length >= 2) {
        setShowResultTab(true)
        setSearchText(searchText)
      }
    }, 500),
    []
  )

  useEffect(() => {
    setSearchIsActive(Boolean(searchText))
  }, [searchText])

  // Handling hotkeys
  const searchFieldRef = useRef<HTMLInputElement>()
  const [focusedElementIndex, setFocusedElementIndex] =
    useState<number>(undefined)

  /** Refs to elements that we need to be able focus on  */
  const refsByIndex = useMemo(() => {
    const refs = []
    const elementsToFocusOn: (TSearchItem | string)[] = [
      ...(searchResults?.categories || []),
      ...(searchResults?.products || []),
      ...(searchResults?.orders || []),
    ]

    elementsToFocusOn.forEach((item, index) => {
      refs[index] = createRef()
    })

    return refs
  }, [
    searchResults?.products,
    searchResults?.categories,
    searchResults?.orders,
  ])

  const onKeyDown = (event, item = null, elementIndex = null) => {
    const { keyCode, target } = event
    const { ENTER, ARROW_UP, ARROW_DOWN, ESCAPE } = KEY_CODES

    if (Object.values(KEY_CODES).includes(keyCode)) {
      event.preventDefault()
      event.stopPropagation()
      const firedOnSearchField = target?.nodeName === 'INPUT'

      switch (keyCode) {
        case ENTER:
          if (item?.slug) {
            window.location.href = `${window.location.origin}${item.slug}`
            setShowResultTab(false)
          }
          break

        case ARROW_UP:
          if (elementIndex === 0) {
            searchFieldRef?.current?.focus()
          } else {
            refsByIndex[elementIndex - 1]?.current?.focus()
          }
          break

        case ARROW_DOWN:
          if (firedOnSearchField) {
            // Focus on the first item
            refsByIndex[0]?.current?.focus()
          } else {
            refsByIndex[elementIndex + 1]?.current?.focus()
          }
          break

        case ESCAPE:
          setShowResultTab(false)
          setSearchText(null)
          break

        default:
          break
      }
    }
  }

  return (
    <>
      <div
        className="flex flex-col justify-center w-full px-5 sm:px-10 font-mori relative"
        onMouseLeave={() =>
          setTimeout(() => {
            if (!isMobile) {
              setShowResultTab(false)
            }
          }, 200)
        }
      >
        {/*
         * Work around to prevent background from scrolling when search is open on mobile
         */}
        {isMobile && searchIsActive && (
          <style>{`
            body {
              overflow: hidden;
            }
          `}</style>
        )}

        <div className="relative items-center w-full">
          <FormInput
            ref={searchFieldRef}
            type="text"
            name="textWithLeftIcon"
            placeholder={
              isMobile
                ? 'Search packaging...'
                : 'Search for tissue, bags, cups, orders and more...'
            }
            wasSubmitted={false}
            IconAfter={HiOutlineSearch}
            onChange={updateValue}
            onFocus={() => {
              setShowResultTab(true)
              setFocusedElementIndex(undefined)
            }}
            inputClassName="h-16 bg-black-10 border-[1px] placeholder-black-60 border-black-60 text-[16px] md:text-xl leading-1 pt-1"
            className="!mt-0 !mb-0 border-black-60"
            initialValue={searchText}
            onKeyDown={(event) => onKeyDown(event, null)}
          />
        </div>

        {showResultTab && searchText && (
          <div className="absolute top-[calc(100%-12px)] left-0 right-0 w-[100vw] sm:w-full pt-[13px] flex flex-col z-10">
            <div
              className={`
                          relative
                          bg-white w-[100vw]
                          grow sm:w-full rounded-none sm:rounded-[16px]
                          max-h-[calc(100vh-180px)] sm:max-h-[50vh] flex flex-col
                          overflow-y-auto shadow-none sm:shadow-2xl p-6 h-[calc(100vh-180px)] sm:h-max
                        `}
            >
              {isMobile && !loading && (
                <HiXMark
                  onClick={() => {
                    setShowResultTab(false)
                    setSearchText('')
                  }}
                  className="absolute text-5xl black top-8 right-8"
                />
              )}

              {!loading && !error && !noResultsWereFound && (
                <div className="flex flex-col w-full">
                  {foundProducts && (
                    <div className="flex flex-col w-full">
                      <h3 className="my-6 text-3xl font-semibold text-black sm:text-2xl">
                        Products
                      </h3>

                      {searchResults.products.map((item, index) => {
                        const elementRefIndex = index
                        const isLastItem =
                          !foundCategories &&
                          index === searchResults.products.length - 1
                        const isFocused =
                          focusedElementIndex === elementRefIndex
                        const itemClassName = `${
                          isLastItem ? 'last:mb-48 sm:last:mb-5' : ''
                        } ${
                          isFocused ? 'bg-black-10 text-pitaya' : 'text-black'
                        }`

                        return (
                          <SearchResultItem
                            ref={refsByIndex[elementRefIndex]}
                            className={itemClassName}
                            itemName={item.name}
                            itemSlug={item.slug}
                            imageURL={item.image}
                            statusTag={
                              item?.showStatusTag ? item?.statusTag : null
                            }
                            saleTag={item?.saleTag}
                            onFocus={() =>
                              setFocusedElementIndex(elementRefIndex)
                            }
                            onKeyDown={(event) =>
                              onKeyDown(event, item, elementRefIndex)
                            }
                            keyword={searchText}
                          />
                        )
                      })}
                    </div>
                  )}

                  {foundCategories && (
                    <div className="flex flex-col w-full">
                      <h3 className="my-6 text-3xl font-semibold text-black sm:text-2xl">
                        Categories
                      </h3>
                      {searchResults.categories.map((item, index) => {
                        const elementRefIndex =
                          searchResults.products?.length + index
                        const isFocused =
                          focusedElementIndex === elementRefIndex
                        const itemClassName = `last:mb-48 sm:last:mb-5 ${
                          isFocused ? 'bg-black-10 text-pitaya' : ''
                        }`

                        return (
                          <SearchResultItem
                            ref={refsByIndex[elementRefIndex]}
                            className={itemClassName}
                            itemName={item.name + ''}
                            itemSlug={item.slug}
                            imageURL={item.image}
                            onFocus={() =>
                              setFocusedElementIndex(elementRefIndex)
                            }
                            onKeyDown={(event) =>
                              onKeyDown(event, item, elementRefIndex)
                            }
                            keyword={searchText}
                          />
                        )
                      })}
                    </div>
                  )}

                  {foundOrders && (
                    <div className="flex flex-col w-full">
                      <h3 className="my-6 text-3xl font-semibold text-black sm:text-2xl">
                        My Orders
                      </h3>

                      {searchResults.orders.map((item, index) => {
                        const elementRefIndex =
                          searchResults.products?.length +
                          searchResults?.categories?.length +
                          index

                        const isFocused =
                          focusedElementIndex === elementRefIndex

                        const itemClassName = `last:mb-48 sm:last:mb-5 ${
                          isFocused ? 'bg-black-10 text-pitaya' : ''
                        }`

                        return (
                          <SearchResultItem
                            ref={refsByIndex[elementRefIndex]}
                            className={itemClassName}
                            itemName={item.name}
                            itemSlug={item.slug}
                            imageURL={item.image}
                            onFocus={() =>
                              setFocusedElementIndex(elementRefIndex)
                            }
                            onKeyDown={(event) =>
                              onKeyDown(event, item, elementRefIndex)
                            }
                            keyword={searchText}
                          />
                        )
                      })}
                    </div>
                  )}
                </div>
              )}

              {(error || noResultsWereFound) && !loading && (
                <p className="pt-16 text-2xl sm:pt-0 font-mori text-black-60">
                  No results for <strong>{searchText}</strong>
                </p>
              )}

              {loading && <SearchLoadingStateSkeleton />}
            </div>
          </div>
        )}
      </div>
    </>
  )
}
