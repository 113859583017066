import {
  IHubspotContact,
  IHubspotDeal,
  IHubspotProperyFilter,
} from 'types/hubspot'
import { COOKIE_HUBSPOT } from '@utils/constants'
import Cookie from 'js-cookie'
import isEmpty from 'lodash/isEmpty'
import { TConsentOptions } from '@utils/form/schema'
import {
  IHubspotQuotePayload,
  IHubspotQuotePayloadField,
} from '@components/product/ProductPage/productDetailVariants/usePlusProductDetailsSection/plusForm.types'

const fetchHubspotContactByEmail = async (
  email: string
): Promise<IHubspotContact> => {
  try {
    const response = await fetch('/api/customer/hubspot/contact', {
      method: 'POST',
      body: JSON.stringify({ email }),
      headers: { 'Content-Type': 'application/json' },
    })

    if (!response.ok) {
      throw new Error(
        `HubSpot API error: ${response.status} ${response.statusText}`
      )
    }

    return await response?.json()
  } catch (err) {
    console.error(err)
    throw new Error('Error fetching HubSpot contact')
  }
}

const fetchHubspotDealsInBatch = async (
  dealsIDs: string[]
): Promise<IHubspotDeal[]> => {
  try {
    const response = await fetch('/api/customer/hubspot/deals/read/batch', {
      method: 'POST',
      body: JSON.stringify({ dealsIDs }),
      headers: { 'Content-Type': 'application/json' },
    })

    if (!response.ok) {
      throw new Error(
        `HubSpot API error: ${response.status} ${response.statusText}`
      )
    }

    return (await response?.json())?.results
  } catch (err) {
    throw new Error('Error fetching HubSpot deals.')
  }
}

const fetchHubspotDeal = async (dealID: string) => {
  try {
    const response = await fetch(`/api/customer/hubspot/deals/read/${dealID}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })

    if (!response.ok) {
      throw new Error(
        `HubSpot API error: ${response.status} ${response.statusText}`
      )
    }

    return await response?.json()
  } catch (err) {
    throw new Error('Error fetching HubSpot deal.')
  }
}

const fetchHubspotLineItems = async (lineItemsIDs: string[]) => {
  try {
    const response = await fetch('/api/customer/hubspot/lineItems/read/batch', {
      method: 'POST',
      body: JSON.stringify({ lineItemsIDs }),
      headers: { 'Content-Type': 'application/json' },
    })

    // TODO: handle 207 response status when it happens
    // example: https://noissue.sentry.io/issues/5775734407/
    if (!response.ok) {
      throw new Error(
        `HubSpot API error: ${response.status} ${response.statusText}`
      )
    }

    return await response?.json()
  } catch (err) {
    throw new Error('Error fetching HubSpot line items.')
  }
}

const fetchHubspotDealByProperties = async ({
  propertyFilters,
  limit = 10,
  after = 0,
  sorts = [],
}: {
  propertyFilters: IHubspotProperyFilter[]
  limit?: number
  after?: number
  sorts?: IHubspotProperyFilter[]
}) => {
  try {
    const response = await fetch(`/api/customer/hubspot/deals/read/property`, {
      method: 'POST',
      body: JSON.stringify({ propertyFilters, limit, after, sorts }),
      headers: { 'Content-Type': 'application/json' },
    })

    if (!response.ok) {
      throw new Error(
        `HubSpot API error: ${response.status} ${response.statusText}`
      )
    }

    return await response?.json()
  } catch (err) {
    throw new Error('Error fetching HubSpot deal.')
  }
}

const fetchDealsWithLineItems = async (dealIDs: string[]) => {
  try {
    const promises = []

    for (const dealID of dealIDs) {
      promises.push(fetchHubspotDeal(dealID))
    }

    const dealsWithLineItemsIds = await Promise.all(promises)

    return dealsWithLineItemsIds
  } catch (err) {
    throw new Error('Error fetching HubSpot deals.')
  }
}

const fetchLineItemsInBatch = async (lineItemsIDs: string[]) => {
  try {
    const response = await fetch('/api/customer/hubspot/lineItems/read/batch', {
      method: 'POST',
      body: JSON.stringify({ lineItemsIDs }),
      headers: { 'Content-Type': 'application/json' },
    })

    if (!response.ok) {
      throw new Error(
        `HubSpot API error: ${response.status} ${response.statusText}`
      )
    }

    return (await response?.json())?.results
  } catch (err) {
    throw new Error('Error fetching HubSpot line items.')
  }
}

const updateLineItem = async (
  lineItemId: string,
  properties: Record<string, any>
) => {
  try {
    const response = await fetch(
      `/api/customer/hubspot/lineItems/update/${lineItemId}`,
      {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          properties,
        }),
      }
    )

    if (!response.ok) {
      throw new Error(
        `HubSpot API error: ${response.status} ${response.statusText}`
      )
    }

    return (await response?.json())?.results
  } catch (err) {
    console.log(err)
    throw new Error('Error updating HubSpot line items.')
  }
}

const fetchQuotesInBatch = async (quotesIDs: string[]) => {
  try {
    const response = await fetch('/api/customer/hubspot/quotes/read/batch', {
      method: 'POST',
      body: JSON.stringify({ quotesIDs }),
      headers: { 'Content-Type': 'application/json' },
    })

    if (!response.ok) {
      throw new Error(
        `HubSpot API error: ${response.status} ${response.statusText}`
      )
    }

    return (await response?.json())?.results
  } catch (err) {
    throw new Error('Error fetching HubSpot line items.')
  }
}

const fetchHubspotQuote = async (quoteID: string) => {
  try {
    const response = await fetch(
      `/api/customer/hubspot/quotes/read/${quoteID}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }
    )

    if (!response.ok) {
      throw new Error(
        `HubSpot API error: ${response.status} ${response.statusText}`
      )
    }

    return await response?.json()
  } catch (err) {
    throw new Error('Error fetching HubSpot deal.')
  }
}

const fetchHubspotCompany = async (companyId: string) => {
  try {
    const response = await fetch(
      `/api/customer/hubspot/companies/read/${companyId}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }
    )

    if (!response.ok) {
      throw new Error(
        `HubSpot API error: ${response.status} ${response.statusText}`
      )
    }

    return await response?.json()
  } catch (err) {
    throw new Error('Error fetching HubSpot Company.')
  }
}

const fetchHubspotOwner = async (ownerId: string) => {
  try {
    const response = await fetch(
      `/api/customer/hubspot/owners/read/${ownerId}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }
    )

    if (!response.ok) {
      throw new Error(
        `HubSpot API error: ${response.status} ${response.statusText}`
      )
    }

    return await response?.json()
  } catch (err) {
    throw new Error('Error fetching HubSpot Owner.')
  }
}

const fetchQuotesWithLineItems = async (quotesIDs: string[]) => {
  try {
    const promises = []

    for (const quoteID of quotesIDs) {
      promises.push(fetchHubspotQuote(quoteID))
    }

    const quoteWithLineItemsIds = await Promise.all(promises)

    return quoteWithLineItemsIds
  } catch (err) {
    throw new Error('Error fetching HubSpot deals.')
  }
}

/** Hubspot form submission */
export const MARKETING_FORM_API = '/api/customer/hubspot/marketing-form/'
export const RESERVED_CONSENT_FIELD_NAME = 'consent'

export const formValuesToHubspotPayload = ({
  formValues,
  formId,
  consentOptions,
}: {
  formValues: Record<string, string | boolean>
  consentOptions: TConsentOptions
  formId: string
}) => {
  const fieldsKeys = Object.keys(formValues)
  const fieldsPayload = fieldsKeys
    .map((fieldKey) => {
      if (!formValues[fieldKey]) {
        return null
      }
      const value = formValues[fieldKey]

      return {
        name: fieldKey,
        value,
      }
    })
    .filter(Boolean)

  return {
    formId: formId,
    fields: fieldsPayload,
    context: {
      hutk: Cookie.get(COOKIE_HUBSPOT),
    },
    legalConsentOptions: !isEmpty(consentOptions)
      ? {
          consent: {
            consentToProcess: true,
            text: consentOptions.label,
            communications: [
              {
                value:
                  typeof formValues[RESERVED_CONSENT_FIELD_NAME] === 'string'
                    ? Boolean(
                        formValues[RESERVED_CONSENT_FIELD_NAME] === 'true'
                      )
                    : formValues[RESERVED_CONSENT_FIELD_NAME],
                subscriptionTypeId: consentOptions?.subscriptionTypeId,
                text: consentOptions?.label,
              },
            ],
          },
        }
      : null,
  }
}

/**
 * Checks if a HubSpot form has a sku field and returns the default value,
 * otherwise it returns null
 * @param hubspotForm
 */
const getHubspotFormSku = (hubspotForm: any) => {
  const skuFieldGroup = hubspotForm.fieldGroups?.find((fg) =>
    fg.fields?.find((f) => f.name.indexOf('sku') !== -1)
  )
  if (!skuFieldGroup) {
    return null
  }

  const sku = skuFieldGroup?.fields?.at(0)?.defaultValue
  return sku && sku.trim() !== '' ? sku : null
}

/**
 * Checks if every line item in RAQ form payload has SKU set
 * otherwise it returns false
 * @param formPayload
 */
const quotePayloadContainsSkus = (formPayload: IHubspotQuotePayload) => {
  const lineItems: {
    fields: IHubspotQuotePayloadField[]
  }[] =
    (formPayload?.fields?.find((x) => x.name === 'line_items')?.value as any) ||
    []

  return lineItems?.every((item) => {
    const skuField = item?.fields?.find((x) =>
      x?.name?.toLowerCase()?.includes('sku')
    )
    return Boolean(skuField && skuField?.value)
  })
}

export {
  fetchHubspotContactByEmail,
  fetchHubspotDealsInBatch,
  fetchHubspotDeal,
  fetchDealsWithLineItems,
  fetchLineItemsInBatch,
  fetchQuotesInBatch,
  fetchQuotesWithLineItems,
  fetchHubspotQuote,
  fetchHubspotDealByProperties,
  fetchHubspotLineItems,
  fetchHubspotCompany,
  fetchHubspotOwner,
  getHubspotFormSku,
  quotePayloadContainsSkus,
  updateLineItem,
}
