import { useCallback } from 'react'
import type {
  MutationHookContext,
  HookFetcherContext,
} from '@commerce/utils/types'
import { ValidationError } from '@commerce/utils/errors'
import isEmpty from 'lodash/isEmpty'
import useRemoveItem, {
  RemoveItemInput as RemoveItemInputBase,
  UseRemoveItem,
} from '@commerce/cart/use-remove-item'
import { normalizeCart } from '../lib/normalize'
import type {
  RemoveCartItemBody,
  Cart,
  BigcommerceCart,
  LineItem,
} from '../types'
import useCart from './use-cart'
import { trackRemoveFromCart } from '@utils/gtm'
import { NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_CURRENCY } from '@utils/client-side-config'

export type RemoveItemInput<T = any> = T extends LineItem
  ? Partial<RemoveItemInputBase>
  : RemoveItemInputBase

export type RemoveItemFn<T = any> = T extends LineItem
  ? (input?: RemoveItemInput<T>) => Promise<Cart | null>
  : (input: RemoveItemInput<T>) => Promise<Cart | null>

// eslint-disable-next-line no-use-before-define
export default useRemoveItem as UseRemoveItem<typeof handler>

export const handler = {
  fetchOptions: {
    url: '/api/bigcommerce/cart/',
    method: 'DELETE',
  },
  async fetcher({
    input: { itemId },
    options,
    fetch,
  }: HookFetcherContext<RemoveCartItemBody>) {
    const data = await fetch<BigcommerceCart>({
      ...options,
      body: { itemId },
    })
    // Last item doesn't have any cart exist anymore
    if (isEmpty(data)) {
      return null
    }

    return normalizeCart(data)
  },
  useHook:
    ({ fetch }: MutationHookContext<Cart | null, RemoveCartItemBody>) =>
    <T extends LineItem | undefined = undefined>(ctx: { item?: T } = {}) => {
      const { item } = ctx
      const { mutate } = useCart()
      const removeItem: RemoveItemFn<LineItem> = async (input) => {
        const itemId = input?.id ?? item?.id

        if (!itemId) {
          throw new ValidationError({
            message: 'Invalid input used for this operation',
          })
        }

        const data = await fetch({ input: { itemId } })
        await mutate(data, false)
        if (!isEmpty(input || item)) {
          trackRemoveFromCart({
            currency: data?.currency || {
              code: NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_CURRENCY,
            },
            lineItems: [input || item],
          })
        }
        return data
      }

      return useCallback(removeItem as RemoveItemFn<T>, [fetch, mutate])
    },
}
