import { useWindowSize } from './useWindowSize'

interface IDeviceMedia {
  mobileMediaQuery: number
  tabletMediaQuery: number
}

const MOBLIE_MEDIA_QUERY = 480
const TABLET_MEDIA_QUERY = 768

export function useDevice(
  { mobileMediaQuery, tabletMediaQuery }: IDeviceMedia = {
    mobileMediaQuery: MOBLIE_MEDIA_QUERY,
    tabletMediaQuery: TABLET_MEDIA_QUERY,
  }
) {
  const { width } = useWindowSize()

  if (!width) {
    return {
      isMobile: false,
      isTablet: false,
      isDesktop: false,
    }
  }

  const isMobile = width <= mobileMediaQuery
  const isTablet = width > mobileMediaQuery && width <= tabletMediaQuery
  const isDesktop = width > tabletMediaQuery

  return {
    isMobile,
    isTablet,
    isDesktop,
  }
}
