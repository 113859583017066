import React, { ReactElement, useEffect, useState } from 'react'
import { formatCurrency } from '@commerce/product/use-price'
import { NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_CURRENCY } from '@utils/client-side-config'
import { FormInput } from '@noissue-ui-kit/form/formInput'
import { TProductType } from 'types/contentful/productCategory'
import { useCommerce } from '@commerce'
import { HiChevronDown } from 'react-icons/hi'
import Image from 'next/legacy/image'

export interface IQuantitySelectorOption {
  key: string
  displayLabel: string
  price?: { originalPrice: number; salePrice: number; currencyCode: string }
  indicativePricing?: string
  discount?: string
  optionType?: TProductType
}

interface ICell {
  key: string
  content: ReactElement
}

interface IRow {
  key: string
  cells: ICell[]
  selected?: boolean
  customRowClass?: string
  // Used as a flag for non regular options that are used to add alternative links or content to the Quantity table
  preventSelectionEvent?: boolean
}

interface IQuantityTableHeader {
  key: string
  content: ReactElement
}

function Table({
  headings,
  rows,
  footer,
  onChange,
}: {
  headings: IRow
  rows: IRow[]
  footer: ReactElement
  onChange: (event: IRow) => void
}) {
  const OPTIONS_TO_RENDER_INITIALLY = 7
  const [showMore, setShowMore] = useState<boolean>(false)
  const needsShowMoreState = rows?.length > OPTIONS_TO_RENDER_INITIALLY

  return (
    <div className="border-2 rounded-lg text-acai border-grey-10 font-mori">
      <table className="w-full table-auto">
        {headings?.cells?.length !== 0 && (
          <thead className="px-10">
            <tr
              className="grid px-10 py-4 border-b-2 border-grey-10 bg-core-white"
              style={{
                gridTemplateColumns: `1fr 2fr`,
              }}
            >
              {headings?.cells?.map((heading) => (
                <th
                  key={heading.key}
                  className="text-xl font-bold odd:text-left even:text-right"
                >
                  {heading.content}
                </th>
              ))}
            </tr>
          </thead>
        )}

        <tbody className="w-full">
          {rows
            ?.map((row, index) => {
              if (index < OPTIONS_TO_RENDER_INITIALLY || showMore) {
                return (
                  <tr
                    className={`grid px-10 py-4 border-b-2 cursor-pointer
                      hover:bg-black-10 border-grey-10 grid-cols-[1fr_2fr]
                      sm:grid-cols-[1fr_1fr]
                      ${row.selected ? 'bg-black-10' : 'bg-core-white'} ${
                      row.customRowClass
                    }`}
                    data-testid={row.selected ? 'selected' : undefined}
                    onClick={() => {
                      if (!row?.preventSelectionEvent) {
                        onChange(row)
                      }
                    }}
                    key={row.key}
                  >
                    {row?.cells?.map((columnData) => (
                      <td
                        key={columnData.key}
                        className="text-xl font-medium odd:text-left even:text-right"
                      >
                        {columnData.content}
                      </td>
                    ))}
                  </tr>
                )
              } else {
                return null
              }
            })
            .filter(Boolean)}

          {needsShowMoreState && !showMore && (
            <tr
              className={`grid px-10 py-4 border-b-2 cursor-pointer hover:bg-black-10 border-grey-10 bg-core-white`}
              onClick={() => setShowMore(true)}
            >
              <td className="flex items-center justify-between">
                <span className="text-xl font-mori text-acai">
                  Show more quantities
                </span>
                <HiChevronDown className="text-4xl text-black-60" />
              </td>
            </tr>
          )}

          {footer && (
            <tr className="grid px-10 py-4 bg-core-white">
              <td>{footer}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

const sortQuantityOptions = (options) => {
  return options
    ?.map((option) => +option?.key?.replaceAll(',', ''))
    .sort((a, b) => {
      return a - b
    })
}

export function QuantitySelector<T extends IQuantitySelectorOption>({
  selected,
  onChange,
  options,
  className,
  onError,
  productType = 'DTC',
  setCustomQuantity,
  customQuantity,
  requestAQuoteFormPath,
  alternativeDtcPagePath,
  minimumQuoteQuantityOverride,
  includeQuoteOption,
  requestAQuoteFlowEnabled,
}: {
  selected: T
  onChange: (event: T) => void
  options: T[]
  className?: string
  onError?: (error: string) => void
  productType?: TProductType
  setCustomQuantity?: (customQuantity: number) => void
  customQuantity?: number
  requestAQuoteFormPath?: string
  alternativeDtcPagePath?: string
  minimumQuoteQuantityOverride?: number
  includeQuoteOption?: boolean
  requestAQuoteFlowEnabled?: boolean
}) {
  const { locale } = useCommerce()
  const hubspotPlusFormIsEnabled = requestAQuoteFlowEnabled
  const [showDynamicInput, setShowDynamicInput] = useState<boolean>(false)
  const [smallestQuantityOption, setSmallestQuantityOption] = useState<number>()
  const [largestQuantityOption, setLargestQuantityOption] = useState<number>()
  const [tableHeadCells, setTableHeadСells] = useState<IQuantityTableHeader[]>([
    { key: 'quantity', content: <>Quantity</> },
  ])
  const [smallestAllowedQuoteQuantity, setSmallestAllowedQuoteQuantity] =
    useState<number>(0)

  const variantOptionMappedToTableRow = options?.map((option) => ({
    key: option.key,
    selected: selected?.key?.trim() === option?.key?.trim(),
    cells: [
      {
        key: `quantity-${option.displayLabel}`,
        content: (
          <>
            <span
              key={`quantity-${option.displayLabel}`}
              className="text-acai font-mori"
            >
              {option.displayLabel}{' '}
            </span>
          </>
        ),
      },
      option?.price
        ? {
            key: `price-${option.displayLabel}`,
            content: (
              <>
                {option?.price?.salePrice > 0 &&
                option?.price?.salePrice < option?.price?.originalPrice ? (
                  <>
                    <span className="hidden md:inline-block line-through pr-[12px] text-lg font-mori text-black-80">
                      {formatCurrency({
                        amount: option?.price?.originalPrice,
                        currencyCode:
                          option?.price?.currencyCode ||
                          NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_CURRENCY,
                        locale,
                      })}
                    </span>

                    <span className="text-lg font-semibold text-acai font-mori">
                      {formatCurrency({
                        amount: option?.price?.salePrice,
                        currencyCode:
                          option?.price?.currencyCode ||
                          NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_CURRENCY,
                        locale,
                      })}
                    </span>
                  </>
                ) : (
                  <span className="text-lg font-semibold text-acai font-mori">
                    {option?.price &&
                      formatCurrency({
                        amount: option?.price?.originalPrice,
                        currencyCode:
                          option?.price?.currencyCode ||
                          NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_CURRENCY,
                        locale,
                      })}
                  </span>
                )}
                <span className="inline-block min-w-[90px] ml-8 text-xl text-acai font-mori whitespace-nowrap">
                  {option.discount}
                </span>
              </>
            ),
          }
        : null,

      option?.indicativePricing
        ? {
            key: `indicative-pricing-${option.displayLabel}`,
            content: (
              <>
                <span className="ml-4 font-semibold text-acai-80 font-mori">
                  {option.indicativePricing}
                </span>
              </>
            ),
          }
        : null,

      option?.optionType === 'Plus'
        ? {
            key: `raq-label-${option.displayLabel}`,
            content: (
              <div className="flex flex-row justify-end items-end sm:items-center">
                <Image
                  src="/images/noissue-plus-logo.avif"
                  width={54}
                  height={12}
                  className="flex-shrink-0"
                  objectFit="contain"
                />
                <span className="min-w-[90px] ml-8 text-acai-80 font-mori whitespace-nowrap">
                  Get a quote
                </span>
              </div>
            ),
          }
        : null,
    ].filter(Boolean),
  }))

  useEffect(() => {
    const sortedQuantityOptions = sortQuantityOptions(options)
    setSmallestQuantityOption(sortedQuantityOptions[0])
    setLargestQuantityOption(sortedQuantityOptions.at(-1))
  }, [options])

  useEffect(() => {
    setSmallestAllowedQuoteQuantity(
      minimumQuoteQuantityOverride ||
        (productType === 'DTC' ? largestQuantityOption : smallestQuantityOption)
    )
  }, [
    minimumQuoteQuantityOverride,
    productType,
    largestQuantityOption,
    smallestQuantityOption,
  ])

  // Based on product type and configuration we do or do not need "Price" cell
  // Here we check and add it if needed.
  useEffect(() => {
    if (tableHeadCells?.find((cell) => cell?.key === 'price')) {
      return
    }

    if (productType === 'DTC') {
      setTableHeadСells([
        ...tableHeadCells,
        { key: 'price', content: <>Price</> },
      ])
    }

    if (
      productType === 'Plus' &&
      options.some((x) => Boolean(x?.indicativePricing))
    ) {
      setTableHeadСells([
        ...tableHeadCells,
        { key: 'price', content: <>Indicative Pricing</> },
      ])
    }
  }, [productType])

  const validateDynamicQuantity = (enteredValue) => {
    const enteredNumber = Number(enteredValue)

    if (
      (minimumQuoteQuantityOverride &&
        enteredNumber < smallestAllowedQuoteQuantity) ||
      (!minimumQuoteQuantityOverride &&
        enteredNumber <= smallestAllowedQuoteQuantity)
    ) {
      return 'Quantity is too small'
    }

    if (enteredNumber > Number.MAX_SAFE_INTEGER) {
      return 'Quantity is too big'
    }

    return null
  }

  useEffect(() => {
    if (!customQuantity && typeof customQuantity !== 'number') {
      setShowDynamicInput(false)
    }
  }, [customQuantity])

  const dtcLinkRow = {
    key: 'dtc-alternative-link',
    selected: false,
    cells: [
      {
        key: 'dtc-link-question',
        content: <span className="flex grow-2">Need a lower quantity?</span>,
      },
      {
        key: 'dtc-link',
        content: (
          <a
            className="text-acai hover:underline"
            href={alternativeDtcPagePath || '/noissueplus?site=dtc-pdp'}
          >
            Click here
          </a>
        ),
      },
    ],
    customRowClass:
      'grid-cols-[2fr_1fr] bg-sand hover:cursor-auto hover:bg-sand',
    preventSelectionEvent: true,
  }

  return (
    <div
      data-testid={'quantity-selector'}
      className={`relative mb-12 ${className}`}
    >
      <Table
        onChange={(event) => {
          const selectedOption = options.find(
            (option) => option.key === event.key
          )
          onChange(selectedOption)
          setCustomQuantity && setCustomQuantity(null)
          setShowDynamicInput(false)
          onError && onError(null)
        }}
        headings={{
          key: 'heading',
          cells: tableHeadCells,
        }}
        rows={[
          alternativeDtcPagePath ? dtcLinkRow : null,
          ...variantOptionMappedToTableRow,
        ].filter(Boolean)}
        footer={
          includeQuoteOption && (
            <div className="flex flex-col">
              {(productType === 'DTC' && !hubspotPlusFormIsEnabled) ||
              requestAQuoteFormPath ? (
                <span className="text-xl text-acai">
                  Need more?&nbsp;
                  <a
                    className="text-rambutant hover:underline"
                    href={requestAQuoteFormPath || '/noissueplus?site=dtc-pdp'}
                  >
                    Click here
                  </a>
                </span>
              ) : (
                <span className="text-xl text-acai">
                  {productType === 'DTC'
                    ? 'Need more? '
                    : 'Want a custom quantity? '}

                  <button
                    className="text-rambutan"
                    onClick={() => {
                      setShowDynamicInput(true)
                    }}
                  >
                    Get a quote
                  </button>
                </span>
              )}
              <span className="flex text-base text-boulder">
                Get extra savings with&nbsp;<strong>noissue+</strong>
              </span>
            </div>
          )
        }
      />

      <FormInput
        name="dynamic quantity"
        type="number"
        className={`!absolute mb-0 transition-all duration-300 h-[60px] ${
          showDynamicInput
            ? 'top-[calc(100%-66px)] opacity-1'
            : 'top-[calc(100%-30px)] opacity-0 pointer-events-none'
        }`}
        inputClassName="rounded-none pl-6 pr-6 bg-core-purple-10 text-2xl leading-tight font-soleil !h-[60px] appearance-textfield"
        onChange={(event) => {
          const error = validateDynamicQuantity(+event.target.value)
          onError && onError(error)
          setCustomQuantity(+event.target.value)
          onChange(null)
        }}
        customValidator={(dynamicQuantity) =>
          validateDynamicQuantity(dynamicQuantity)
        }
        placeholder={`Enter amount above ${smallestAllowedQuoteQuantity}`}
      />
    </div>
  )
}
