import { trackNavigationClick } from '@utils/gtm'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import {
  useSelectedPrimaryMenu,
  useSelectedSecondaryMenu,
} from '../NavbarDataProvider'
import { ContentDropdown } from './DropdownTypes/ContentDropdown'
import { ListDropdown } from './DropdownTypes/ListDropdown'
import { SocialMediaIcons } from './SocialMediaIcons/SocialMediaIcons'
import { useTrackingBreadcrumbs } from '@utils/hooks/useTrackingBreadcrumbs'
import { useLocationMismatchCheck } from '../LocationMismatchPopup/useLocationMismatchCheck'
import Cookies from 'js-cookie'
import { USER_LOCATION_POPUP_INTERACTION } from '@utils/constants'

export const SECONDARY_MENU_ID = 'secondaryMenu'

export function SecondaryMenu({
  showSecondaryMenuBar,
}: {
  showSecondaryMenuBar: boolean
}) {
  const { selectedPrimaryMenu, selectedPrimaryMenuIndex } =
    useSelectedPrimaryMenu()
  const secondaryMenuItems = selectedPrimaryMenu?.secondaryMenuItems
  const { selectedSecondaryMenuIndex, setSelectedSecondaryMenuIndex } =
    useSelectedSecondaryMenu()
  const [showSecondaryMenuDropdown, setShowSecondaryMenuDropdown] =
    useState(false)
  const router = useRouter()
  const { saveBreadcrumbs } = useTrackingBreadcrumbs()
  useEffect(() => {
    setSelectedSecondaryMenuIndex(null)
  }, [selectedPrimaryMenuIndex, showSecondaryMenuBar])

  const [animateSecondaryNavItems, setAnimateSecondaryNavItems] =
    useState(false)

  useEffect(() => {
    if (!secondaryMenuItems) {
      setAnimateSecondaryNavItems(false)
    } else {
      setAnimateSecondaryNavItems(true)
    }
  }, [selectedPrimaryMenuIndex])

  /** Increase that index if location mismatch to allow user interact with navigation,
   * while rest of the website is under overlay */
  const { locationDoesMismatch } = useLocationMismatchCheck()
  const locationMismathPopupIsOpen =
    locationDoesMismatch && !Cookies.get(USER_LOCATION_POPUP_INTERACTION)

  return (
    <div
      id={SECONDARY_MENU_ID}
      className={`absolute bg-core-white content-container transition duration-200 ease-linear ${
        locationMismathPopupIsOpen && showSecondaryMenuBar ? 'z-10' : '-z-1'
      } ${
        showSecondaryMenuBar && secondaryMenuItems?.length > 0
          ? '-translate-y-[0px]'
          : '-translate-y-[54px]'
      }`}
      onMouseLeave={() => {
        setSelectedSecondaryMenuIndex(null)
      }}
    >
      <div className="relative bg-core-white">
        <div className="flex h-[50px] px-[40px] border-b border-core-grey-30 items-center">
          <div className="flex items-end overflow-scroll grow no-scrollbar">
            {secondaryMenuItems?.map((secondaryMenu, index) => {
              return (
                <a
                  href={secondaryMenu?.titleLinkUrl}
                  className={`first:pl-0 px-[16px] font-mori inline-block text-[14px] py-[12px] whitespace-nowrap transition-colors duration-300 ease-linear border-transparent border-b-2 ${
                    selectedSecondaryMenuIndex === index &&
                    showSecondaryMenuDropdown
                      ? 'text-pitaya'
                      : 'text-boulder'
                  } ${
                    secondaryMenu?.titleLinkUrl
                      ? 'cursor-pointer'
                      : 'cursor-default'
                  } ${
                    animateSecondaryNavItems &&
                    'animate-[slideInDown_200ms_ease-out]'
                  }`}
                  onMouseEnter={() => {
                    setShowSecondaryMenuDropdown(true)
                    setSelectedSecondaryMenuIndex(index)
                  }}
                  onMouseLeave={() => {
                    if (secondaryMenu?.type === 'link') {
                      setSelectedSecondaryMenuIndex(null)
                    }
                  }}
                  key={`${secondaryMenu?.title}-${index}`}
                  onClick={(e) => {
                    if (secondaryMenu?.titleLinkUrl) {
                      trackNavigationClick({
                        clickLocation: 'Secondary Menu',
                        clickItem: secondaryMenu?.title,
                      })

                      const breadcrumbs = [
                        {
                          name: selectedPrimaryMenu.primaryMenuTitle,
                          url: selectedPrimaryMenu.primaryMenuLink,
                        },
                        {
                          name: secondaryMenu?.title,
                          url: secondaryMenu?.titleLinkUrl,
                        },
                      ]
                      saveBreadcrumbs(breadcrumbs)

                      router.push(secondaryMenu.titleLinkUrl).catch(() => {})
                    }
                  }}
                >
                  {secondaryMenu?.title}
                </a>
              )
            })}
          </div>

          <div className="flex items-center shrink-0 ml-[16px]">
            <SocialMediaIcons />
          </div>
        </div>
      </div>

      {selectedSecondaryMenuIndex !== null &&
        secondaryMenuItems?.[selectedSecondaryMenuIndex]?.type !== 'link' && (
          <div
            className="content-container bg-core-white"
            onMouseLeave={() => {
              setShowSecondaryMenuDropdown(false)
              setSelectedSecondaryMenuIndex(null)
            }}
          >
            <div
              className="absolute inset-0 h-screen opacity-50 bg-core-black z-[-1]"
              aria-hidden="true"
              onMouseEnter={() => {
                setShowSecondaryMenuDropdown(false)
                setSelectedSecondaryMenuIndex(null)
              }}
            ></div>

            <div className="bg-white min-h-[240px]">
              {secondaryMenuItems?.map((secondaryMenu, index) => {
                return showSecondaryMenuDropdown &&
                  selectedSecondaryMenuIndex === index &&
                  secondaryMenu?.type !== 'link' ? (
                  <>
                    <div className="p-[20px] pb-5 border-t border-core-grey-30">
                      <div className="flex justify-around">
                        <div className={`flex content-container`}>
                          {secondaryMenu?.type === 'list-dropdown' && (
                            <ListDropdown
                              selectedSecondaryMenu={secondaryMenu}
                            />
                          )}
                          {secondaryMenu?.type === 'content-dropdown' && (
                            <ContentDropdown
                              selectedSecondaryMenu={secondaryMenu}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : null
              })}
            </div>
          </div>
        )}
    </div>
  )
}
