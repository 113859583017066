import {
  TCartMetafieldPermisions,
  TCartMetafieldsNamespaces,
} from './cartMetafields.types'

const CART_METAFIELD_NAMESPACES: Record<string, TCartMetafieldsNamespaces> = {
  ANALYTICS: 'analytics',
  COMMERCE: 'commerce',
}

const CART_METAFIELD_PERMISSIONS: Record<string, TCartMetafieldPermisions> = {
  APP_ONLY: 'app_only',
  READ: 'read',
  WRITE: 'write',
  STOREFRONT_READ: 'read_and_sf_access',
  STOREFRONT_WRITE: 'write_and_sf_access',
}

const CLIENT_ID_KEY = 'ga_client_id'

export { CART_METAFIELD_NAMESPACES, CART_METAFIELD_PERMISSIONS, CLIENT_ID_KEY }
