import { SlugOrLink } from '@components/common/SlugOrLink'
import { TLink } from '../Navbar.types'
import { SocialMediaIcons } from '../SecondaryMenu/SocialMediaIcons/SocialMediaIcons'
import { ShippingDestinationToggle } from '../ShippingDestination/ShippingDestinationPopup'
import { useShippingDestinationPopUp } from '../NavbarDataProvider'
import { codeToShippingLocation } from '../ShippingDestination/utils'
import router from 'next/router'

export const MobileFooter = ({
  mobileFooterLinks,
}: {
  mobileFooterLinks: TLink[]
}) => {
  const { shippingDestinationIsOpen, setShippingDestinationIsOpen } =
    useShippingDestinationPopUp()
  const currentShippintDestination = codeToShippingLocation(
    router?.query?.countryCode || null
  )

  return (
    <div className="flex flex-col items-start gap-[32px]">
      <ShippingDestinationToggle
        selectedLocation={currentShippintDestination}
        open={shippingDestinationIsOpen}
        onClick={(event) => {
          event?.stopPropagation()
          event?.preventDefault()
          setShippingDestinationIsOpen(!shippingDestinationIsOpen)
        }}
      />

      <SocialMediaIcons />

      <div className="flex flex-col items-start gap-[16px] h-[56px] opacity-50 font-normal leading-[20px] text-[14px]">
        <div className="flex items-start gap-[16px] h-[20px] flex-wrap">
          {mobileFooterLinks?.map((link) => (
            <SlugOrLink
              key={link.title}
              slugOrUrl={link.slugOrUrl}
              trackingIdentifier={`navbar-mobile-footer-${link.title}`}
            >
              {link.title}
            </SlugOrLink>
          ))}
        </div>
      </div>
    </div>
  )
}
