import React from 'react'
import isEmpty from 'lodash/isEmpty'
import { NavbarTile } from '../SecondaryMenu/TileSection/NavbarTile'
import { TDropdownTile } from '../SecondaryMenu/TileSection/TileSection.types'

export const MobileTiles = ({
  tiles,
  className,
}: {
  tiles: TDropdownTile[]
  className?: string
}) => {
  if (isEmpty(tiles)) {
    return null
  }
  return (
    <div
      className={`flex flex-col w-full items-start gap-[16px] pb-[32px] ${className}`}
    >
      {tiles.map((tile, idx) => (
        <NavbarTile
          key={idx}
          title={tile?.title}
          titleSize={tile?.titleSize}
          titleColor={tile?.titleColor}
          subtitle={tile?.subtitle}
          subtitleColor={tile?.subtitleColor}
          tileLink={tile?.blockLink}
          backgroundColor={tile?.backgroundColor}
          image={tile?.image}
          images={tile?.images}
          layout={tile?.mobileLayout}
          siblingIsFeatureTile={false}
        />
      ))}
    </div>
  )
}
