import Cookie from 'js-cookie'
import { Modal } from '@noissue-ui-kit/modal'
import { useShippingDestinationPopUp } from '../NavbarDataProvider'
import { ShippingDestinationItem } from './ShippingDestinationPopup'
import { changeRegion, codeToShippingLocation } from './utils'
import { useRouter } from 'next/router'
import { shippingLocations } from './config'
import { useEffect, useState } from 'react'
import Image from 'next/legacy/image'
import { COOKIE_CONTAINER } from '@utils/constants'

function MobileShippingDestinationPopup() {
  const router = useRouter()
  const siteRegion = Cookie.get(COOKIE_CONTAINER) || router?.query?.siteRegion
  const selectedLocation = codeToShippingLocation(siteRegion)
  const { shippingDestinationIsOpen, setShippingDestinationIsOpen } =
    useShippingDestinationPopUp()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (siteRegion) {
      setShippingDestinationIsOpen(false)
      setLoading(false)
    }
  }, [siteRegion])

  return (
    <Modal
      size="small"
      opened={shippingDestinationIsOpen}
      title="Shipping destination:"
      showControls={{
        cancelButton: false,
        confirmButton: false,
        cancelIcon: true,
      }}
      onCancel={() => setShippingDestinationIsOpen(false)}
    >
      {loading && (
        <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center bg-white opacity-90 z-5">
          <Image
            className="w-24 h-24 opacity-100"
            src={'/icon-uploading-new-brand.gif'}
            alt="loading icon"
            width={64}
            height={64}
          />
        </div>
      )}

      {shippingLocations.map((location) => (
        <ShippingDestinationItem
          key={location.label}
          itemLabel={location.label}
          itemIconUrl={location.icon}
          selected={location.key === selectedLocation?.key}
          onSelection={() => {
            setLoading(true)
            changeRegion(router?.asPath, location)
          }}
        />
      ))}
    </Modal>
  )
}

export { MobileShippingDestinationPopup }
