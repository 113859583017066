import { trackCTAClick } from '@utils/gtm'
import Link from 'next/link'
import router from 'next/router'

const isOuterLink = (link: string) => {
  return link.toLowerCase().startsWith('http')
}

type TSlugOrLinkProps = {
  slugOrUrl: string
  trackingIdentifier: string
  children: React.ReactNode
}

export const SlugOrLink = ({
  slugOrUrl,
  trackingIdentifier,
  children,
}: TSlugOrLinkProps) => {
  if (!slugOrUrl) {
    return <>{children}</>
  }
  const sendDataLayerEvent = () => {
    trackCTAClick({
      ctaLinkUrl: slugOrUrl,
      componentIdentifier: trackingIdentifier,
      pageUrl: router.asPath,
      ctaLabel: undefined,
    })
  }
  return (
    <>
      {isOuterLink(slugOrUrl) ? (
        <a
          href={slugOrUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="cursor-pointer"
          onClick={sendDataLayerEvent}
        >
          {children}
        </a>
      ) : (
        <Link
          href={slugOrUrl}
          className="cursor-pointer"
          onClick={sendDataLayerEvent}
          legacyBehavior
        >
          {children}
        </Link>
      )}
    </>
  )
}
