import React from 'react'
import { SocialLinks } from './SocialLinks'
import { PrivaryPolicy } from './PrivacyPolicy'
import { TermsAndConditions } from './TermsAndConditions'
import { Copyright } from './Copyright'
import { Logo } from '@components/ui'

export function FooterLinksDesktop({ className }: { className?: string }) {
  return (
    <div
      className={`items-center justify-between mb-20 text-xl text-black-40 gap-[12px] ${className}`}
    >
      <div className="flex items-center justify-center gap-10">
        <Logo isNewLogo={false} />
        <div className="flex flex-row flex-wrap text-xl text-black-40">
          <SocialLinks iconSize="S" />
        </div>
      </div>
      <div className="flex items-center justify-center">
        <Copyright className="border-r border-black-10 text-black-80" />
        <TermsAndConditions className="border-r border-black-10 text-black-80 hover:text-acai-100" />
        <PrivaryPolicy className="text-black-80 hover:text-acai-100" />
      </div>
    </div>
  )
}
