import { NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_CURRENCY } from '@utils/client-side-config'

export function formatCurrency({
  amount,
  currencyCode = 'NZD',
  locale,
  wideFormatForFractions = true,
}: {
  amount: number
  currencyCode: string
  locale: string
  wideFormatForFractions?: boolean
}) {
  if (amount < 0) {
    return null
  }

  const showThreeFractionDigits = amount < 1 && wideFormatForFractions

  try {
    return Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyCode,
      currencyDisplay: 'narrowSymbol',
      minimumFractionDigits: showThreeFractionDigits ? 3 : 2,
    }).format(amount)
  } catch (e) {
    // catch RangeError in old Safari if narrowSymbol is unsupported
    // Will show e.g. "NZD NZ$123.00" for this fallback but better than crashing
    return Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: showThreeFractionDigits ? 3 : 2,
    }).format(amount)
  }
}

export function formatPrice({
  amount,
  currencyCode = 'NZD',
  locale,
  hideCurrencyCode = false,
}: {
  amount: number
  currencyCode: string
  locale: string
  hideCurrencyCode?: boolean
}) {
  const formattedCurrency = formatCurrency({ amount, currencyCode, locale })
  if (hideCurrencyCode || currencyCode.toUpperCase() === 'USD') {
    return formattedCurrency
  }

  return currencyCode.toUpperCase() + ' ' + formattedCurrency
}

export function formatVariantPrice({
  amount,
  baseAmount,
  currencyCode,
  locale,
}: {
  baseAmount: number
  amount: number
  currencyCode: string
  locale: string
}) {
  const hasDiscount = baseAmount > amount
  const formatDiscount = new Intl.NumberFormat(locale, { style: 'percent' })
  const discount = hasDiscount
    ? formatDiscount.format((baseAmount - amount) / baseAmount)
    : null

  const price = formatPrice({ amount, currencyCode, locale })
  const basePrice = hasDiscount
    ? formatPrice({ amount: baseAmount, currencyCode, locale })
    : null

  return { price, basePrice, discount }
}

export default function usePrice(
  data?: {
    amount: number
    baseAmount?: number
    currencyCode: string
    hideCurrencyCode?: boolean
    locale: string
  } | null
) {
  const { amount, baseAmount, currencyCode, hideCurrencyCode, locale } =
    data ?? {}
  let value

  if (typeof amount !== 'number' || !currencyCode) {
    value = ''
  }

  value = baseAmount
    ? formatVariantPrice({ amount, baseAmount, currencyCode, locale })
    : formatPrice({ amount, currencyCode, locale, hideCurrencyCode })

  return typeof value === 'string' ? { price: value } : value
}
